/* eslint-disable  */
import Vue from 'vue';

const domanAnoreg = 'ws-anoregmt.org.br';

Vue.prototype.$anoreg = { parametes: {} };
Vue.prototype.$anoreg.parametes.domainCurrent = window.location.origin;

Vue.prototype.$anoreg.parametes.updateToken = '12c703ec7-6b4e-4144-aba6-80590acb213d';

Vue.prototype.$anoreg.parametes.maintenance = false;
Vue.prototype.$anoreg.parametes.maintenance_message = `
Estamos em manutenção e em breve retornaremos com o serviço.<br>
Pedimos desculpas pelo transtorno causado no momento.<br>
Previsão de retorno 30/08/2020 23:59:00.`;

Vue.prototype.$anoreg.parametes.production =
  Vue.prototype.$anoreg.parametes.domainCurrent === 'https://app.anoregmt.org.br';

Vue.prototype.$anoreg.parametes.staging =
  Vue.prototype.$anoreg.parametes.domainCurrent === 'https://hom-app.anoregmt.org.br';

Vue.prototype.$anoreg.parametes.development =
  Vue.prototype.$anoreg.parametes.domainCurrent === 'https://dev-app.anoregmt.org.br';

Vue.prototype.$anoreg.parametes.localhost =
  Vue.prototype.$anoreg.parametes.domainCurrent.indexOf('localhost') != -1;

Vue.prototype.$anoreg.parametes.reportUrlWs =
  Vue.prototype.$anoreg.parametes.production || Vue.prototype.$anoreg.parametes.development
    ? `wss://report.mundel.com.br/`
    : `wss://report.mundel.com.br/`;
//  : `ws://localhost:3333/`;

Vue.prototype.$anoreg.parametes.reportUrl =
  Vue.prototype.$anoreg.parametes.production || Vue.prototype.$anoreg.parametes.development
    ? `https://report.mundel.com.br/`
    : `https://report.mundel.com.br/`;
// `http://localhost:3333/`;



/**
 * Parâmetros URL Base
 */
Vue.prototype.$anoreg.base = {};
Vue.prototype.$anoreg.base.urlApi = `https://dev-ws.anoregmt.org.br`;

Vue.prototype.$anoreg.socket = {};
Vue.prototype.$anoreg.socket.active = true;
Vue.prototype.$anoreg.socket.urlAuth = `broadcasting/auth`;
Vue.prototype.$anoreg.socket.urlApi = `https://dev-ws.anoregmt.org.br`;
Vue.prototype.$anoreg.socket.portaApi = `443`; // producao = 443
Vue.prototype.$anoreg.socket.enabledTransports = ['ws'];
Vue.prototype.$anoreg.socket.broadcaster = 'pusher'; 
Vue.prototype.$anoreg.socket.key = 'vqSDdRJUa6'; 
Vue.prototype.$anoreg.socket.cluster = 'mt1'; 
Vue.prototype.$anoreg.socket.forceTLS = false; // producao = false
Vue.prototype.$anoreg.socket.disableStats = true; // producao = true
Vue.prototype.$anoreg.socket.encrypted = false; // producao = true



/**
 * Parâmetros Auth
 */
Vue.prototype.$anoreg.auth = {};
Vue.prototype.$anoreg.auth.urlApi = `https://dev-ws.anoregmt.org.br/api`; 
Vue.prototype.$anoreg.auth.urlApiCertificate = `https://assinador.prosix.com.br`;

/**
 * Parametros Distribuicao
 */

Vue.prototype.$anoreg.distribuicao = {};
Vue.prototype.$anoreg.distribuicao.urlApi = `https://dev-ws.anoregmt.org.br/distribuicao/api/admin/`;

/**
 * Parametros Financeiro
 */

Vue.prototype.$anoreg.financeiro = {};

if (Vue.prototype.$anoreg.parametes.production) {
  Vue.prototype.$anoreg.financeiro.urlApi = `https://dev-ws.anoregmt.org.br/financeiro/api/v1/`; // Maquina Local
}
if (Vue.prototype.$anoreg.parametes.development) {
  Vue.prototype.$anoreg.financeiro.urlApi = `https://dev-ws.anoregmt.org.br/financeiro/api/v1/`; // Maquina Local
}
if (Vue.prototype.$anoreg.parametes.localhost) {
  Vue.prototype.$anoreg.financeiro.urlApi = `http://localhost:8000/financeiro/api/v1/`; // Maquina Local
  // Vue.prototype.$anoreg.financeiro.urlApi = `https://dev-ws.anoregmt.org.br/financeiro/api/admin/`; // Maquina Local
}

/**
 * Parametros Consulta
 */

Vue.prototype.$anoreg.consulta = {};

if (Vue.prototype.$anoreg.parametes.production) {
  Vue.prototype.$anoreg.consulta.urlApi = `https://dev-ws.anoregmt.org.br/`; // Produção
}
if (Vue.prototype.$anoreg.parametes.development) {
  Vue.prototype.$anoreg.consulta.urlApi = `https://dev-ws.anoregmt.org.br/`; // Desenvolvimento
}
if (Vue.prototype.$anoreg.parametes.localhost) {
  // Vue.prototype.$anoreg.consulta.urlApi = `http://192.168.3.200:8002/v1/`; // Maquina Local
  Vue.prototype.$anoreg.consulta.urlApi = `https://dev-ws.anoregmt.org.br/`;
}

/**
 * Parametros Biblioteca
 */

Vue.prototype.$anoreg.biblioteca = {};

if (Vue.prototype.$anoreg.parametes.production) {
  Vue.prototype.$anoreg.biblioteca.urlApi = `https://dev-ws.anoregmt.org.br/biblioteca/api/v1`;
}
if (Vue.prototype.$anoreg.parametes.development) {
  Vue.prototype.$anoreg.biblioteca.urlApi = `https://dev-ws.anoregmt.org.br/biblioteca/api/v1`;
}
if (Vue.prototype.$anoreg.parametes.localhost) {
  Vue.prototype.$anoreg.biblioteca.urlApi = `https://dev-ws.anoregmt.org.br/biblioteca/api/v1`;
}

/**
 * Parametro Empresa
 */

Vue.prototype.$anoreg.empresa = {};

if (Vue.prototype.$anoreg.parametes.production) {
  Vue.prototype.$anoreg.empresa.urlApi = `https://auth.${domanAnoreg}/v1/`; // Produção
}
if (Vue.prototype.$anoreg.parametes.development) {
  Vue.prototype.$anoreg.empresa.urlApi = `https://auth.${domanAnoreg}/v1/`; // Desenvolvimento
}
if (Vue.prototype.$anoreg.parametes.localhost) {
  Vue.prototype.$anoreg.empresa.urlApi = `https://auth.${domanAnoreg}/v1/`; // Maquina Local
}

/**
 * Parametros CEI
 */

Vue.prototype.$anoreg.cei = {};

if (Vue.prototype.$anoreg.parametes.production) {
  Vue.prototype.$anoreg.cei.urlApi = `https://cei.${domanAnoreg}/v1/`; // Produção
}
if (Vue.prototype.$anoreg.parametes.development) {
  Vue.prototype.$anoreg.cei.urlApi = `https://cei.${domanAnoreg}/v1/`; // Desenvolvimento
}
if (Vue.prototype.$anoreg.parametes.localhost) {
  Vue.prototype.$anoreg.cei.urlApi = `https://cei.${domanAnoreg}/v1/`; // Maquina Local
}

/**
 * Parametro Sesp
 */

Vue.prototype.$anoreg.sesp = {};

if (Vue.prototype.$anoreg.parametes.production) {
  Vue.prototype.$anoreg.sesp.urlApi = `https://dev-ws.anoregmt.org.br/sesp/api/`; // Produção
}
if (Vue.prototype.$anoreg.parametes.development) {
  Vue.prototype.$anoreg.sesp.urlApi = `https://dev-ws.anoregmt.org.br/sesp/api/`; // Produção
}
if (Vue.prototype.$anoreg.parametes.localhost) {
  Vue.prototype.$anoreg.sesp.urlApi = `https://dev-ws.anoregmt.org.br/sesp/api/`; // Produção
}

/**
 * Parametros Acompanhamento
 */

Vue.prototype.$anoreg.acompanhamento = {};

if (Vue.prototype.$anoreg.parametes.production) {
  Vue.prototype.$anoreg.acompanhamento.urlApi = `https://acompanhamento.${domanAnoreg}/v1/`; // Produção
}
if (Vue.prototype.$anoreg.parametes.development) {
  Vue.prototype.$anoreg.acompanhamento.urlApi = `https://acompanhamento.${domanAnoreg}/v1/`; // Desenvolvimento
}
if (Vue.prototype.$anoreg.parametes.localhost) {
  Vue.prototype.$anoreg.acompanhamento.urlApi = `https://acompanhamento.${domanAnoreg}/v1/`; // Maquina Local
}

/**
 * Parametro Arquivo
 */

Vue.prototype.$anoreg.arquivos = {};

if (Vue.prototype.$anoreg.parametes.production) {
  Vue.prototype.$anoreg.arquivos.urlApi = `https://sign.${domanAnoreg}/v1/`; // Produção
}
if (Vue.prototype.$anoreg.parametes.development) {
  Vue.prototype.$anoreg.arquivos.urlApi = `https://sign.${domanAnoreg}/v1/`; // Desenvolvimento
}
if (Vue.prototype.$anoreg.parametes.localhost) {
  Vue.prototype.$anoreg.arquivos.urlApi = `https://sign.${domanAnoreg}/v1/`; // Maquina Local
}

/**
 * Parametro Integraçao
 */

Vue.prototype.$anoreg.gif = {};

if (Vue.prototype.$anoreg.parametes.production) {
  Vue.prototype.$anoreg.gif.urlApi = `https://gif.${domanAnoreg}/v1/`; // Produção
}
if (Vue.prototype.$anoreg.parametes.development) {
  Vue.prototype.$anoreg.gif.urlApi = `https://dev-gif.${domanAnoreg}/v1/`; // Desenvolvimento
}
if (Vue.prototype.$anoreg.parametes.localhost) {
  Vue.prototype.$anoreg.gif.urlApi = `https://dev-gif.${domanAnoreg}/v1/`; // Maquina Local
}

/**
 * Parametros Estatistica
 */

Vue.prototype.$anoreg.estatistica = {};
Vue.prototype.$anoreg.estatistica.urlApi = `https://ws.anoregmt.org.br/estatistica/api/v1`; // Produção

