export default function () {
  return {
    envolvidos: {
      data: [],
    },
    envolvido: {
      attributes: {},
      relationships: {
        arquivos: [],
      },
    },
    papeis: [
      { label: 'Analista', value: 'analista' },
      { label: 'Encarregado de dados', value: 'encarregado_dados' },
      { label: 'Operador', value: 'operador' },
      // { label: 'Responsável de dados', value: 'responsavel_dados' },
    ],
  };
}
