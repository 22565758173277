/* eslint-disable */
/* eslint-disable arrow-body-style */
import Vue from 'vue';
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'notificacao/api/v1';

/**
 * Buscando Notificações do usuário
 * @param {*} param0
 */
export async function notificacaoList({ commit }, resorce) {
  Loading.show();
  return (
    await http
      .get(`${urlApi}/listar?page=${resorce.page}&${resorce.filtros}`)
      .then((res) => {
        Loading.hide();
        // commit('SET_NOTIFICATIONS', res.data.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Buscando Quantidade Notificações do usuário
 * @param {*} param0
 */
export async function notificationQuantidade({ commit }, obj) {
  Loading.show();
  return (
    await http
      .get(`${urlApi}/quantidade?filter[modulos]=${obj.filtros}`)
      .then((res) => {
        Loading.hide();
        // commit('SET_NOTIFICATIONS', res.data.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Deixar notificação como Lida
 * @param {*} param0
 */
export async function notificacaoLer({ commit }, notificacaoUuid) {
  Loading.show();
  return (
    await http
      .post(`${urlApi}/ler`, {
        notificacaoUuid
      })
      .then((res) => {
        Loading.hide();
        // commit('SET_NOTIFICATIONS', res.data.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Buscando Notificações do usuário
 * @param {*} param0
 */
export async function notificationList({ commit }, obj) {
  Loading.show();
  return (
    await http
      .get(`${urlApi}/retrieve?${obj.page}&${obj.filter}`)
      .then((res) => {
        Loading.hide();
        // commit('SET_NOTIFICATIONS', res.data.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Informar Notificação como lido
 * {
 *     id: 'deb06905-4106-49b1-9b76-2d1f022d86c1'
 * }
 * @param {*} param0
 */
export async function notificationRead({ commit }, obj) {
  Loading.show();
  await http.post(`${urlApi}/read`, obj);
  Loading.hide();
}

export async function notificationReadAll({ commit }) {
  Loading.show();
  return (
    await http
      .post(`${urlApi}/read-all`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Informar Notificação como não lido
 * {
 *     id: 'deb06905-4106-49b1-9b76-2d1f022d86c1'
 * }
 * @param {*} param0
 */
export async function notificationUnread({ commit }, obj) {
  return (
    await http
      .post(`${urlApi}/unread`, obj)
      .then((res) => {
        Loading.hide();
        commit('UPDATE_READ_AT_NOTIFICATION', obj);
        success(res.data.data.message);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Abrindo Canal Socket
 * @param {*} param0
 * @param {*} obj
 */
export async function channelClient({ commit }, userId) {
  // Criando Canal usuário
  if (Vue.prototype.$echoNotification) {
    Vue.prototype.$echoChannelUser = Vue.prototype.$echoNotification.private(`user.${userId}`);
    Vue.prototype.$echoChannelUser.listen('.notification.new', (e) => {
      Vue.prototype.$notification.show(
        e.data.description,
        {
          body: '',
        },
        {},
      );
      commit('UNSHIT_NOTIFICATIONS', e);
    });
  }
  return true;
}


/**
 * Buscando Relatorios do usuário
 * @param {*} param0
 */
export async function relatorioList({ commit }, userId) {
  return (
    await http
      .get(`http://localhost:3333/report-list/${userId}`)
      .then((res) => {
        commit('SET_RELATORIOS', res.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
/**
 * Removendo relatório
 * @param {*} param0
 */
export async function relatorioDelete({ commit }, id) {
  return (
    await http
      .delete(`http://localhost:3333/report/${id}`)
      .then((res) => {
        commit('REMOVE_RELATORIO', id);
        success('Relatório removido com sucesso');
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
