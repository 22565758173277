import Vue from 'vue';
import Platform from 'quasar/src/plugins/Platform.js';;
import VueNativeNotification from 'vue-native-notification';

if (!Platform.is.ios) {
  Vue.use(VueNativeNotification, {
    // Automatic permission request before
    // showing notification (default: true)
    requestOnNotify: true,
  });
  Vue.notification.requestPermission();
}
