export default function () {
  return {
    pedidoNotificacoes: {
      data: [],
      meta: {
        total: 0,
      },
    },
    pedidoListloading: true,
    faseDisabled: false,
    dashboardFiltro: {
      diasInicio: null,
      diasFim: null,
    },

    pedidoStatus: [
      { label: 'Aberto', value: 'aberto' },
      { label: 'Em análise', value: 'analise' },
      { label: 'Finalizado', value: 'encerrado' },
      { label: 'Cancelado', value: 'cancelado' },
      // { label: 'Encerrado', value: 'encerrado' },
      { label: 'Pendente', value: 'pendente' },
    ],

    columnsPedido: [
      {
        name: 'id',
        align: 'center',
        label: '#',
        field: 'id',
        style: 'width: 20px',
        sortable: true,
      },
      {
        name: 'tipo',
        align: 'left',
        label: 'Tipo',
        field: 'data',
        style: 'width: 100px',
        sortable: true,
      },
      {
        name: 'status',
        align: '',
        label: 'Status',
        field: 'status',
        style: '',
        sortable: true,
      },
      {
        name: 'andamento',
        align: 'left',
        label: 'Andamento atual',
        field: 'andamento',
        style: '',
        sortable: true,
      },
      {
        name: 'responsavel',
        align: 'left',
        label: 'Responsável',
        field: 'responsavel',
        style: '',
        sortable: true,
      },
      {
        name: 'prazo',
        align: 'left',
        label: '',
        field: 'prazo',
        style: '',
        sortable: true,
      },
      // {
      //   name: 'distribuidor',
      //   align: 'center',
      //   label: 'Distribuidor',
      //   field: 'distribuidor',
      //   style: '',
      //   sortable: true,
      // },
    ],
    pedidoIncludes: '',
    pedidoFiltros: {
      ativo: false,
      showLotes: false,
      ordemCriacao: 0,
      criadosPorMim: 0,
      meusPedidos: 0,
      remetenteAtribuicao: '',
      status: 'aberto',
      remetentecpfcnpj: '',
      pedido: {
        id: 0,
        nome: '',
        agrupamentos_ativos: [],
      },
      agrupamento: {
        nome: '',
        id: 0,
      },
      categoria: {
        nome: '',
        id: 0,
      },
      lote: {
        id: null,
      },
    },
    pedidos: {
      data: [],
      links: {},
    },
    pedido: {
      destinatario_id: null,
      avaliacao_nota: 5,
      andamento: {
        fase: {},
      },
    },
    // Resumo para criar novoPedido
    pedidoResumo: {
      tituloTab: '',
      versao: {
        attributes: {},
      },
      agrupamento: {},
      cidade: {},
      cartorio: {},
      tipoServico: {},
      servicos: [],
    },
    pedidoOficios: {
      data: [],
    },

    // Componentes da Etapa
    pedidoStepComponentes: [],

    actionPositivo: true,
    actionExcecao: true,
    actionNegativo: true,
  };
}
