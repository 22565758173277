import authStore from '../store/auth/auth';

/**
 * Verificando se usuário tem algum Scope da rota
 * @param {*} routeScopes
 */
// export function userHasScope(routeScopes) {
// }

export function userValidate(routeScopes) {
  let routerName = '';

  // Verificando permissão do usuário
  // Comparando com Scope da rota
  if (!authStore.state.user.scopes.filter(userScope => routeScopes.indexOf(userScope.scope) > -1)) {
    routerName = 'default-home';
    return routerName;
  }

  // Caso usuário n validou o email
  if (!authStore.state.user.email_verified_at) {
    routerName = 'email-validacao';
    return routerName;
  }

  // Caso usuário não tenha entidades vinculadas
  // if (!authStore.state.user.entidades.length) {
  //   routerName = 'cei-novo-pedido';
  //   return routerName;
  // }

  return routerName;
}
