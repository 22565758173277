/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../../services/alert-message';

const urlApi = 'central/api/acesso-restrito/atos';

/**
* Buscando todos atos
* @param {*} param0
*/
export async function resumoAnual({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/resumo-anual?${obj.filter}`)
    .then((res) => {
      Loading.hide();
      // commit('SET_PROTOCOLOS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando todos atos
* @param {*} param0
*/
export async function resumoMensal({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/resumo-mensal?${obj.filter}`)
    .then((res) => {
      Loading.hide();
      // commit('SET_PROTOCOLOS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando todos atos
* @param {*} param0
*/
export async function resumoSelo({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/resumo-selos?${obj.filter}`)
    .then((res) => {
      Loading.hide();
      // commit('SET_PROTOCOLOS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function resumoServico({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/resumo-servico?${obj.filter}`)
    .then((res) => {
      Loading.hide();
      // commit('SET_PROTOCOLOS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando todos atos
* @param {*} param0
*/
export async function resumoDiario({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/resumo-diario?${obj.filter}`)
    .then((res) => {
      Loading.hide();
      // commit('SET_PROTOCOLOS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Quantidade de Livros por Serviço
* @param {*} param0
*/
export async function quantidadeLivroServico({ commit }, obj) {
  Loading.show();
  return (await http.get(`central/api/acesso-restrito/quantidade-litro-por-servico?${obj.filter}`)
    .then((res) => {
      Loading.hide();
      commit('SET_LIVROS_POR_SERVICO', res.data.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


/**
* Quantidade de Livros por Serviço
* @param {*} param0
*/
export async function syncGif({ commit }, obj) {
  Loading.show();
  return (await http.post('gif/api/v1/atos-praticados/atualizar', obj)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
