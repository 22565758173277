export default function () {
  return {
    baseConhecimentoSearch: {
      texto: '',
      tag: '',
    },
    baseConhecimentos: {
      data: [],
    },
    baseConhecimentoTree: [],
    baseConhecimento: {
      attributes: {
        titulo: '',
        descricao: '',
      },
      relationships: {
        baseConhecimentoItens: [],
      },
    },
    baseConhecimentoItem: {
      attributes: {
        titulo: '',
        descricao: '',
      },
      relationships: {
      },
    },
  };
}
