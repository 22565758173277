export default function () {
  return {
    filtro: {
      periodo: { from: null, to: null },
    },
    dashboard: {},
    loadingConsultaDashboard: false,
    consultas: {
      data: [],
    },
  };
}
