/*
export function someMutation (state) {
}
*/

export function SET_CORREICAO_CORREICAO_LIST(state, list) {
  state.correicoes = list;
}


export function SET_CORREICAO_CORREICAO(state, obj) {
  state.correicao = obj;
}
