export default function () {
  return {
    baseConhecimentoItem: {
      attributes: {},
      relationships: {
        arquivos: [],
      },
    },
  };
}
