

export function SET_FORNECEDOR_LIST(state, list) {
  state.fornecedores = list;
}


export function SET_FORNECEDOR(state, obj) {
  if (!obj.relationships.dado) {
    obj.relationships.dado = {
      attributes: {
        avaliacaoAtendimento: 1,
        avaliacaoPrazoEntrega: 1,
        avaliacaoQualidadeProduto: 1,
        observacao: '',
      },
    };
  }

  if (obj.relationships.dado.attributes.dataContrato) {
    obj.relationships.dado.attributes.dataContrato = obj.relationships.dado.attributes.dataContrato.formatDate('DD/MM/YYYY', 'YYYY-MM-DD');
  }

  if (obj.relationships.dado.attributes.dataContratoRevisado) {
    obj.relationships.dado.attributes.dataContratoRevisado = obj.relationships.dado.attributes.dataContratoRevisado.formatDate('DD/MM/YYYY', 'YYYY-MM-DD');
  }


  state.fornecedor = obj;
}


export function SET_FORNECEDOR_ACESSO_EXTERNO(state, obj) {
  if (!obj.relationships.cidade) {
    obj.relationships.cidade = {
      attributes: { name: '', cidade_id: null },
      relationships: {
        estado: {
          attributes: { abbreviation: '' },
        },
      },
    };
  }
  state.fornecedor = obj;
}


export function SET_FORNECEDOR_DASHBOARD(state, obj) {
  state.fornecedorDashboard = obj;
}
