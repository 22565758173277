/*
export function someMutation (state) {
}
*/
export function SET_PERGUNTA_LIST(state, list) {
  state.perguntas = list;
}


export function SET_PERGUNTA(state, obj) {
  if (obj) {
    state.pergunta = obj;
  } else {
    state.pergunta = { attributes: {}, relationships: { perguntas: [] } };
  }
}
