export default function () {
  return {
    mapaRisco: {
      data: [],
    },
    // mapaRisco: {
    //   attributes: {
    //     titulo: '',
    //   },
    // },
  };
}
