/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'central/api/acesso-restrito/atos';

/**
* Buscando todos atos
* @param {*} param0
*/
export async function correicaoList({ commit }, obj) {
  return (await http.get(`${urlApi}?${obj.page}&${obj.filter}&include=tipoServico,tipoLivro,cartorio,partes`)
    .then((res) => {
      Loading.hide();
      // commit('SET_PROTOCOLOS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


/**
 *
 * @param {*} param0
 * @param {*} obj
 */
export async function correicaoSave({ state, commit }, obj) {
  Loading.show();
  return (await http.post(`${urlApi}/${obj.ato.id}/correicao`, state.correicao)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
