export default function () {
  return {
    consultaHistorico: [],
    valorPedidoEprotocolo: '6,23',
    valorPesquisaDinamica: '10,38',
    valorConsulta: '30,00',
    valorImagem: '13,40',
    link_tabela_custa: 'https://corregedoria-mc.tjmt.jus.br/corregedoria-arquivos-prod/cms/Provimento_n_38_2023_CGJ_Republicado_ff8088eb6d.pdf',
  };
}
