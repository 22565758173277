/*
export function someMutation (state) {
}
*/


export function SET_VERSAO(state, obj) {
  if (obj) {
    state.versao = obj;
  } else {
    state.versao = {};
  }
}
