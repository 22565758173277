const routes = [
  {
    path: '/minha-serventia',
    component: () => import('layouts/minha-serventia.vue'),
    children: [
      // Visão geral
      {
        path: '',
        redirect: 'visao-geral',
      },
      {
        path: 'visao-geral',
        name: 'minha-serventia-visao-geral',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Minha Serventia', path: '' },
            // { label: 'Visão geral', path: 'minha-serventia-visao-geral' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/acesso-restrito/visao-geral-serventia.vue'),
      },
      {
        path: 'calendario-envio',
        name: 'minha-serventia-calendario-envio-ato',
        meta: {
          title: '',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
          ],
        },
        component: () => import('pages/cei/acesso-restrito/calendario-envio-ato.vue'),
      },
      {
        path: 'cartorio-envio-10-dias/:cns',
        name: 'minha-serventia-show-cartorio-envio-10-dias',
        meta: {
          title: '',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
            'orgao-publico',
          ],
        },
        component: () => import('pages/cei/acesso-restrito/show-cartorio-envio-10-dias.vue'),
      },
      {
        path: 'extrato',
        name: 'minha-serventia-extrato',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'Minha Serventia', path: '' },
            { label: 'Extrato', path: 'minha-serventia-extrato' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/acesso-restrito/extrato.vue'),
      },
      {
        path: 'ato',
        name: 'minha-serventia-ato',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Minha Serventia', path: '' },
            // { label: 'Ato', path: 'minha-serventia-ato' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/acesso-restrito/ato.vue'),
      },
      {
        path: 'protocolo',
        name: 'minha-serventia-protocolo',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Minha Serventia', path: '' },
            // { label: 'Protocolo', path: 'minha-serventia-protocolo' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/acesso-restrito/protocolo.vue'),
      },
      {
        path: 'consulta',
        name: 'minha-serventia-consulta',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Minha Serventia', path: '' },
            // { label: 'Consultas', path: 'minha-serventia-consulta' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/acesso-restrito/consulta.vue'),
      },
      {
        path: 'sinal-publico',
        name: 'minha-serventia-sinal-publico',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Minha Serventia', path: '' },
            // { label: 'Sinal público', path: 'minha-serventia-consulta' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/acesso-restrito/sinal-publico.vue'),
      },
      {
        path: 'procuracoes',
        name: 'minha-serventia-procuracoes',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Minha Serventia', path: '' },
            // { label: 'Sinal público', path: 'minha-serventia-consulta' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/acesso-restrito/procuracoes.vue'),
      },
      {
        path: 'financeiro-contaPagar',
        name: 'minha-serventia-financeiro-contaPagar',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Minha Serventia', path: '' },
            // { label: 'Financeiro', path: 'minha-serventia-financeiro' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/acesso-restrito/financeiro-contaPagar.vue'),
      },
      {
        path: 'financeiro-distribuicao',
        name: 'minha-serventia-financeiro-distribuicao',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Minha Serventia', path: '' },
            // { label: 'Financeiro', path: 'minha-serventia-financeiro' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/acesso-restrito/financeiro-distribuicao.vue'),
      },
      {
        path: 'regularizacao',
        name: 'minha-serventia-regularizacao',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Minha Serventia', path: '' },
            // { label: 'Financeiro', path: 'minha-serventia-financeiro' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/acesso-restrito/regularizacao.vue'),
      },
      {
        path: 'pedidos',
        name: 'minha-serventia-pedidos',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Minha Serventia', path: '' },
            // { label: 'Financeiro', path: 'minha-serventia-financeiro' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/acesso-restrito/pedidos.vue'),
      },

    ],
  },
];

export default routes;
