export default function () {
  return {
    valor_minimo: '6,23',
    compra: {
      cliente_id: null,
      formaPagamento: '',
      quantidade: '0,00',
    },
    compras: {
      data: [],
    },
  };
}
