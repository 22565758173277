/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'contas-bancarias';

/**
* Salvando conta bancaria
* @param {*} param0
*/
export async function contaBancariaSave({ state, commit }, obj) {
  const contaBancaria = JSON.parse(JSON.stringify(state.contaBancaria));
  Loading.show({ message: 'Salvando conta...' });
  if (state.contaBancaria.id) {
    return (
      await http.put(`${urlApi}/${state.contaBancaria.id}`, contaBancaria)
        .then((res) => {
          Loading.hide();
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }

  return (
    await http.post(`${urlApi}`, contaBancaria)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

export async function contaBancariaPadrao({ state, commit }) {
  Loading.show({ message: 'Incluindo conta como principal...' });
  return (
    await http.post(`${urlApi}/${state.contaBancaria.id}/padrao`)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
 * Removendo conta Bancarária
 * @param {*} param0
 */
export async function contaBancariaDelete({ state, commit }) {
  Loading.show({ message: 'Removendo conta...' });
  return (
    await http.delete(`${urlApi}/${state.contaBancaria.id}`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}
