/* eslint-disable  */
import Vue from 'vue';
import axios from 'axios';


// Vue.config.errorHandler = function (err, vm, info)  {
//   console.log('[Global Error Handler]: Error in ' + info + ': ' + err);
// };

Vue.prototype.$axios = axios.create({
  baseURL: Vue.prototype.$anoreg.base.urlApi,
  timeout: 0,
  headers: {

  },
});

Vue.prototype.$axios.interceptors.request.use(request => {
  const token = localStorage.getItem('token');
  // const token = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3dzLmFub3JlZ210Lm9yZy5ici9hdXRoL2xvZ2luIiwiaWF0IjoxNjc4NzQ1MDgyLCJleHAiOjE2Nzk2MDkwODIsIm5iZiI6MTY3ODc0NTA4MiwianRpIjoiNHdKYllBZGk4M0tsQVExQiIsInN1YiI6IjMxNzIiLCJwcnYiOiIyM2JkNWM4OTQ5ZjYwMGFkYjM5ZTcwMWM0MDA4NzJkYjdhNTk3NmY3IiwibWV0YSI6eyJpbmZvIjoiTG9naW4gcG9yIENQRi9DTlBKIDkwODI3MDQwMjEwIEAyMDIzLTAzLTEzIDE4OjA0OjQyIn0sInNjb3BlcyI6WyJjb25zdWx0YS1iYXNpY2EiLCJjb25zdWx0YS1zZXJ2aWNvcy1maXJtYXMiLCJjb25zdWx0YS1zZXJ2aWNvcy1lc2NyaXR1cmEiLCJjb25zdWx0YS1zZXJ2aWNvcy1wcm9jdXJhY2FvIiwiY29uc3VsdGEtc2Vydmljb3MtbmFzY2ltZW50byIsImNvbnN1bHRhLXNlcnZpY29zLWNhc2FtZW50byIsImNvbnN1bHRhLXNlcnZpY29zLW9iaXRvIiwiY29uc3VsdGEtc2Vydmljb3MtcmVnaXN0cm8tZXNwZWNpYWwiLCJjb25zdWx0YS1zZXJ2aWNvcy1wcm90ZXN0byIsImNvbnN1bHRhLXNlcnZpY29zLXRkIiwiY29uc3VsdGEtc2Vydmljb3MtcGoiLCJjb25zdWx0YS1zZXJ2aWNvcy1yaSIsImNvbnN1bHRhLWF0b3MtZ2VyYWwiLCJyLWNlaSIsInctY2VpIiwici1kaXN0cmlidWljYW8iLCJ3LWRpc3RyaWJ1aWNhbyIsImNhbi1sb2dpbiIsImNvbnN1bHRhLXBlcmlvZG8iLCJjb25zdWx0YS1ncmF0dWl0YS1idXNjYSIsImNvbnN1bHRhLWF2YW5jYWRhIiwiY29uc3VsdGEtYXRvcy1jb21wbGV0byIsImNvbnN1bHRhLWdyYXR1aXRhLXZpc3VhbGl6YWNhbyIsImVudmlvLW5vdGlmaWNhY2FvIiwic3RhcnQiXX0.zO2crtoW5v5eB1iONAoD8xXELoSwnVtjiB0cJrl3f40';
  request.headers.authorization = `Bearer ${token}`;
  request.headers.Cookie = '__cflb=';
  return request;
});
