/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'files';

/**
 * Buscando arquivos
 * @param {*} param0
 * @param {*} obj
 */
export async function listArquivo({ commit }, obj) {
  return (
    await http
      .get(`${urlApi}?page=${obj.page}${obj.filter}`)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

/**
 * Buscando arquivo
 * @param {*} param0
 * @param {*} obj
 */
export async function getArquivo({ commit }, fileId) {
  Loading.show();
  return (
    await http
      .get(`${urlApi}/${fileId}?include=signed_by,forwarded_by,received_by,entity,signed_by,author`)
      .then((res) => {
        Loading.hide();
        commit('SET_DOCUMENT', res.data.data.file);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}
