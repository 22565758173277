/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'consultas';

/**
*
* @param {*} param0
*/
export async function dashboardList({ commit }, obj) {
  // Loading.show();
  return (await http.get('financeiro/api/v1/dashboard')
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
