/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'pedido-agrupamento';

/**
* Buscando todos os Tipo de Pedido
* @param {*} param0
*/
export async function agrupamentoList({ commit }, pedidoTipoSlug) {
  return (await http.get(`/distribuicao/api/v1/${urlApi}?pedido_tipo_slug=${pedidoTipoSlug}`)
    .then((res) => {
      return res;
    })
  ).data;
}
