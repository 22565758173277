/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1/fornecedores';

/**
* Buscando todos fornecedores no cartório
* @param {*} param0
*/
export async function fornecedorList({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}?${obj.page}&${obj.filter}`)
    .then((res) => {
      Loading.hide();
      // commit('SET_FORNECEDOR_LIST', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Pesquisando cadastro base de todos os Fornecedores
* @param {*} param0
*/
export async function fornecedorSearch({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}-pesquisar?${obj.page}&${obj.filter}`)
    .then((res) => {
      Loading.hide();
      // commit('SET_FORNECEDOR_LIST', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando fornecedores
* @param {*} param0
*/
export async function fornecedorGet({ commit }, id) {
  Loading.show();
  return (await http.get(`${urlApi}/${id}`)
    .then((res) => {
      Loading.hide();
      commit('SET_FORNECEDOR', res.data.data.fornecedor);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Dashboard
* @param {*} param0
*/
export async function fornecedorDashboard({ commit }, id) {
  return (await http.get(`${urlApi}/${id}/dashboard`)
    .then((res) => {
      commit('SET_FORNECEDOR_DASHBOARD', res.data.data);
      return res;
    })
    .catch((err) => {
      return err;
    })
  ).data;
}


/**
*
* @param {*} param0
*/
export async function fornecedorFormularioEnviar({ state, commit }) {
  Loading.show();
  return (await http.get(`${urlApi}/${state.fornecedor.id}/enviar-formulario`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Salvando fornecedor
* @param {*} param0
*/
export async function fornecedorSave({ state, commit }) {
  Loading.show();
  const fornecedor = JSON.parse(JSON.stringify(state.fornecedor));
  fornecedor.attributes.cpfCnpj = fornecedor.attributes.cpfCnpj.apenasNumeros();
  if (state.fornecedor.id) {
    return (
      await http.put(`${urlApi}/${state.fornecedor.id}`, fornecedor)
        .then((res) => {
          Loading.hide();
          // success(res.data.data.message);
          // commit('SET_ATOS', res.data);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }

  return (
    await http.post(`${urlApi}`, fornecedor)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
* Salvando fornecedor
* @param {*} param0
*/
export async function fornecedorDadoSave({ state, commit }) {
  Loading.show({ message: 'Salvando fornecedor ...' });
  const dado = JSON.parse(JSON.stringify(state.fornecedor.relationships.dado));

  if (dado.attributes.dataContrato) {
    dado.attributes.dataContrato = dado.attributes.dataContrato.formatDate('YYYY-MM-DD', 'DD/MM/YYYY');
  }
  if (dado.attributes.dataContratoRevisado) {
    dado.attributes.dataContratoRevisado = dado.attributes.dataContratoRevisado.formatDate('YYYY-MM-DD', 'DD/MM/YYYY');
  }

  if (dado.id) {
    return (
      await http.put(`${urlApi}/${state.fornecedor.id}/dados/${dado.id}`, dado)
        .then((res) => {
          Loading.hide();
          success(res.data.data.message);
          // commit('SET_ATOS', res.data);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }

  return (
    await http.post(`${urlApi}/${state.fornecedor.id}/dados`, dado)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
* Remover todos fornecedors
* @param {*} param0
*/
export async function fornecedorRemove({ state, commit }) {
  Loading.show();
  return (await http.delete(`${urlApi}/${state.fornecedor.id}`)
    .then((res) => {
      Loading.hide();
      success(res.data.data.message);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


/**
*
* @param {*} param0
*/
export async function fornecedorArquivoDownload({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/${obj.fornecedor.id}/arquivos/${obj.arquivo.id}/download`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function fornecedorInformacaoSave({ commit }, obj) {
  Loading.show();
  return (await http.post(`lgpd/api/v1/processos/${obj.processo.id}/regulamento/${obj.regulamento.id}/contexto/${obj.contexto.id}/fornecedor/${obj.fornecedor.id}/atualiza-informacao`, obj)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

// ///////////////////////////////////


export async function fornecedorAcessoExternoShow({ commit }, obj) {
  Loading.show();
  return (await http.get(`lgpd/api/publico/fornecedores/${obj.uuid}?chaveAcesso=${obj.chaveAcesso}`)
    .then((res) => {
      Loading.hide();
      commit('SET_FORNECEDOR_ACESSO_EXTERNO', res.data.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function fornecedorAcessoExternoUpdate({ commit }, obj) {
  Loading.show();
  return (await http.post(`lgpd/api/publico/fornecedores/${obj.uuid}?chaveAcesso=${obj.chaveAcesso}`, obj.fornecedor)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function fornecedorAcessoExternoArquivoUpdate({ commit }, obj) {
  Loading.show({ message: 'Salvando documento ...' });
  return (await http.put(`lgpd/api/publico/fornecedores/${obj.uuid}/arquivos/${obj.arquivoId}?chaveAcesso=${obj.chaveAcesso}`, obj.arquivo)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function fornecedorAcessoExternoUpload({ commit }, obj) {
  Loading.show({ message: 'Salvando ...' });
  return (await http.post(`lgpd/api/publico/fornecedores/${obj.uuid}/arquivos/${obj.arquivoId}/upload?chaveAcesso=${obj.chaveAcesso}`, obj.arquivo)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function fornecedorAcessoExternoDownload({ commit }, obj) {
  Loading.show({ message: 'Buscando documento ...' });
  return (await http.get(`lgpd/api/publico/fornecedores/${obj.uuid}/arquivos/${obj.arquivoId}/download?chaveAcesso=${obj.chaveAcesso}`)
    .then((res) => {
      Loading.hide();
      return res.data;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function fornecedorAcessoExternoArquivoRemove({ commit }, obj) {
  Loading.show({ message: 'Removendo documento ...' });
  return (await http.delete(`lgpd/api/publico/fornecedores/${obj.uuid}/arquivos/${obj.arquivoId}?chaveAcesso=${obj.chaveAcesso}`)
    .then((res) => {
      Loading.hide();
      return res.data;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function fornecedorAcessoExternoDadoCartorio({ commit }, obj) {
  Loading.show();
  return (await http.post(`lgpd/api/publico/fornecedores/${obj.fornecedorUuid}/cartorios/${obj.cartorioUuid}?chaveAcesso=${obj.chaveAcesso}`, obj)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
