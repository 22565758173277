/* eslint-disable */
export default function () {
  return {
    filterGlobal: '',
    module: {
      filter: '',
    },
    modules: [
      {
        title: 'Minha conta',
        categories: [
          {
            title: 'Admin',
            icon: 'build',
            path: 'admin-index',
            slug: '*',
            description: `
              <span class="text-bold">Administração</span>
            `,
            status: 'active',
          },
          {
            title: 'Meu perfil',
            icon: 'perm_identity',
            path: 'user-profile',
            description: `
              <span class="text-bold">Meu Perfil</span>
            `,
            status: 'active',
          },
          {
            title: 'Minha serventia',
            icon: 'business',
            path: 'acompanhamento-index',
            description: `
              <span class="text-h6 text-bold">Minha Serventia</span>
              <p class="q-mt-md text-justify">Você, notário ou registrador, pode configurar a melhor forma para
              acompanhar o andamento de todos os serviços da serventia.</p>
            `,
            status: 'disabled',
          },
          {
            title: 'Ouvidoria',
            icon: 'hearing',
            path: '',
            description: `
              <span class="text-h6 text-bold">Ouvidoria</span>
              <p class="q-mt-md text-justify">Cade o texto, Djalma?</p>
            `,
            status: 'disabled',
          },
          {
            title: 'Ajuda',
            icon: 'contact_support',
            path: 'geo-index',
            description: `
              <span class="text-h6 text-bold">Ajuda</span>
              <p class="q-mt-md text-justify">Cade o texto, Djalma?</p>
            `,
            status: 'disabled',
          },
        ],
      },
      {
        title: 'Consultas & informações',
        categories: [
          {
            title: 'CEI/MT',
            icon: 'search',
            slug: 'cei-mt',
            path: 'cei-index',
            description: 'bg-primary',
            status: 'active',
          },
          {
            title: 'Acompanhamento',
            icon: 'done_outline',
            path: 'acompanhamento-index',
            description: 'bg-primary',
            status: 'active',
          },
          {
            title: 'Biblioteca',
            icon: 'import_contacts',
            path: 'biblioteca-index',
            slug: 'biblioteca',
            description: 'bg-primary',
            status: 'active',
          },
        ],
      },
      {
        title: 'Serviços',
        categories: [
          {
            title: 'Meu Município',
            icon: 'location_city',
            path: 'geo-index',
            slug: 'meu-municipio',
            status: 'active',
          },
          {
            title: 'Distribuição',
            icon: 'swap_horiz',
            path: 'distribuicao-index',
            status: 'active',
          },
          {
            title: 'Qualidade',
            icon: 'emoji_events',
            path: 'qualidade-index',
            status: 'active',
          },
          {
            title: 'Guarda & Cons.',
            icon: 'vertical_split',
            path: 'geo-index',
            status: 'disabled',
          },
          {
            title: 'Finanças',
            icon: 'bar_chart',
            path: 'geo-index',
            status: 'disabled',
          },
        ],
      },
      {
        title: 'Ferramentas',
        categories: [
          {
            title: 'Assinador',
            icon: 'verified_user',
            path: 'cei-index',
            status: 'disabled',
          },
          {
            title: 'Backups',
            icon: 'backup',
            path: 'backup-index',
            status: 'active',
          },
        ],
      },
      {
        title: 'Anoreg',
        categories: [
          {
            title: 'Notícias',
            icon: 'rss_feed',
            path: 'cei-index',
            status: 'disabled',
          },
          {
            title: 'Mídia',
            icon: 'photo',
            path: 'acompanhamento-index',
            status: 'disabled',
          },
          {
            title: 'Eventos',
            icon: 'event_note',
            path: 'acompanhamento-index',
            status: 'disabled',
          },
          {
            title: 'EMNOR',
            icon: 'live_tv',
            path: 'acompanhamento-index',
            status: 'disabled',
          },
          {
            title: 'Forum',
            icon: 'forum',
            path: 'acompanhamento-index',
            status: 'disabled',
          },
        ],
      },
      {
        title: 'Integrações',
        categories: [
          {
            title: 'Sinter',
            icon: 'compare_arrows',
            path: 'cei-index',
            description: `
              <span class="text-h6 text-bold">Sinter</span>
              <p class="q-mt-md text-justify">Acompanhamento das informações encaminhadas pela
              CEI-MT de RTD, PJ, NOTAS e RI como:</p>
              <ul>
                <li>Protocolo de envio</li>
                <li>Protocolo de recebimento</li>
                <li>Status do arquivo (aceito, aguardando, rejeitado)</li>
                <li>Fluxograma e regras da api()</li>
              </ul>
            `,
            status: 'warning',
            message:
              'Estamos com uma instabilidade nesta integração. Nossas engenheiros já estão' +
              ' avaliando a situação',
          },
          {
            title: 'SIRC',
            icon: 'compare_arrows',
            path: 'acompanhamento-admin',
            description: `
              <span class="text-h6 text-bold">SIRC</span>
              <p class="q-mt-md text-justify">Acompanhamento das informações encaminhadas pela CEI-MT
              de Nascimento, Casamento e Óbito como:</p>
              <ul>
                <li>Protocolo de envio</li>
                <li>Protocolo de recebimento</li>
                <li>Status do arquivo (aceito, aguardando, rejeitado)</li>
                <li>Fluxograma e regras da api()</li>
              </ul>
            `,
            status: 'negative',
            message: 'Esta integração está desativada por tempo indeterminado.',
          },
          {
            title: 'CRC-BR',
            icon: 'compare_arrows',
            path: 'geo-index',
            description: `
              <span class="text-h6 text-bold">CRC-BR</span>
              <p class="q-mt-md text-justify">Acompanhamento das informações encaminhadas pela CEI-MT
              de Nascimento, Casamento e Óbito como:</p>
              <ul>
                <li>Protocolo de envio</li>
                <li>Protocolo de recebimento</li>
                <li>Status do arquivo (aceito, aguardando, rejeitado)</li>
                <li>Fluxograma e regras da api()</li>
              </ul>
            `,
            status: 'disabled',
          },
          {
            title: 'IRTDPJ-BR',
            icon: 'compare_arrows',
            path: 'geo-index',
            description: `
              <span class="text-h6 text-bold">IRTDPJ-BR</span>
              <p class="q-mt-md text-justify">Acompanhamento das informações encaminhadas pela
              CEI-MT de RTD e PJ como:</p>
              <ul>
                <li>Protocolo de envio</li>
                <li>Protocolo de recebimento</li>
                <li>Status do arquivo (aceito, aguardando, rejeitado)</li>
                <li>Fluxograma e regras da api()</li>
              </ul>
            `,
            status: 'disabled',
          },
          {
            title: 'CENSEC',
            icon: 'compare_arrows',
            path: 'geo-index',
            description: `
              <span class="text-h6 text-bold">CENSEC</span>
              <p class="q-mt-md text-justify">Acompanhamento das informações encaminhadas pela
              CEI-MT de RTD e PJ como:</p>
              <ul>
                <li>Protocolo de envio</li>
                <li>Protocolo de recebimento</li>
                <li>Status do arquivo (aceito, aguardando, rejeitado)</li>
                <li>Fluxograma e regras da api()</li>
              </ul>
            `,
            status: 'disabled',
          },
          {
            title: 'RI-BR',
            icon: 'compare_arrows',
            path: 'geo-index',
            description: `
              <span class="text-h6 text-bold">RI-BR</span>
              <p class="q-mt-md text-justify">Acompanhamento das informações encaminhadas pela
              CEI-MT de RI como:</p>
              <ul>
                <li>Protocolo de envio</li>
                <li>Protocolo de recebimento</li>
                <li>Status do arquivo (aceito, aguardando, rejeitado)</li>
                <li>Fluxograma e regras da api()</li>
              </ul>
            `,
            status: 'disabled',
          },
          {
            title: 'WebServices',
            icon: 'developer_board',
            path: 'geo-index',
            description: `
              <span class="text-h6 text-bold">WebServices</span>
              <p class="q-mt-md text-justify">Detalhamento de todas as APIs para integração com a
              CEI-MT;</p>
              <ul>
                <li>Sesp</li>
                <li>Sinter</li>
                <li>Sirc</li>
                <li>Testamento</li>
                <li>Guarda e conservação</li>
                <li>Alienação fiduciária</li>
                <li>e-CPR</li>
                <li>Regularização fundiária</li>
              </ul>
            `,
            status: 'disabled',
          },
        ],
      },
    ],
  };
}
