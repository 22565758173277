/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'pedido-versao';

/**
* Buscando todos os Tipo de Pedido
* @param {*} param0
*/
export async function versaoShow({ commit }, versaoId) {
  return (await http.get(`/distribuicao/api/v1/${urlApi}/${versaoId}`)
    .then((res) => {
      commit('SET_VERSAO', res.data.data);
      return res;
    })
  ).data;
}
