const routes = [
  {
    path: '/empresa',
    component: () => import('layouts/empresa.vue'),
    children: [
      {
        path: '',
        name: 'empresa-index',
        meta: {
          title: 'Empresas',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-empresa', 'r-empresa'],
        },
        component: () => import('pages/empresa/empresa/empresa-list.vue'),
      },
      {
        path: 'pessoas',
        name: 'empresa-people-list',
        meta: {
          title: 'Pessoas',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-empresa', 'r-empresa'],
        },
        component: () => import('pages/empresa/people/empresa-people-list.vue'),
      },
      {
        path: 'pessoa/:person_id',
        name: 'empresa-people-person',
        meta: {
          title: 'Pessoa',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-empresa', 'r-empresa'],
        },
        component: () => import('pages/empresa/people/empresa-people-person.vue'),
      },
    ],
  },
];

export default routes;
