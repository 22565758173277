

export function SET_MENSAGENS(state, list) {
  list.data.reverse();
  state.mensagens = list;
}

export function SET_MENSAGENS_OCULTAS(state, list) {
  state.mensagensOcultas = list;
}

export function PUSH_MENSAGENS(state, list) {
  if (list.meta) {
    state.mensagens.meta = list.meta;
  }

  // list.data.reverse();
  list.data.map((m) => {
    state.mensagens.data.unshift(m);
    return true;
  });
}

export function INSERT_MESAGEM(state, mensagem) {
  state.mensagens.data.push(mensagem);
}
