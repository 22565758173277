/* eslint-disable */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'auth';

/**
 * Autenticação com Usuário e Senha
 * @param {*} param0
 * @param {*} obj
 */
export async function login({ commit }, obj) {
  const user = JSON.parse(JSON.stringify(obj));
  user.cpf_cnpj = user.cpf_cnpj.replace(/\D/g, '');
  return (
    await http
      .post(`${urlApi}/login`, user)
      .then(res => {
        res.data.router_name = 'select-perfil';
        commit('SAVE_TOKEN', res.data);
        // me({ commit });
        return res;
      })
      .catch(err => {
        return { data: err };
      })
  ).data;
}

export async function checkAutenticacao({ commit }) {
  Loading.show();
  const token = localStorage.getItem('token');
  if (token) {
    await http.get(`${urlApi}/me?include=entidades,atribuicao`)
      .then((res) => {
        commit('SET_USER', res.data);
        Loading.hide();
        this.$router.push({ name: 'select-perfil' });
      })
      .catch(() => {
        Loading.hide();
        localStorage.removeItem('token');
      });
  }
  Loading.hide();
}

export async function saveMe({ commit }, obj) {
  const user = JSON.parse(JSON.stringify(obj));
  if (user.cep) {
    user.cep = user.cep.replace(/[^0-9]/g, '');
  }
  user.fone1 = user.fone1.replace(/[^0-9]/g, '');
  if (user.fone2) {
    user.fone2 = user.fone2.replace(/[^0-9]/g, '');
  }
  if (user.fone3) {
    user.fone3 = user.fone3.replace(/[^0-9]/g, '');
  }

  return (
    await http
      .post(`${urlApi}/me`, user)
      .then((res) => {
        success(res.data.data.message);
        return res;
      })
      .catch((err) => {
        return err;
      })
  ).data;
}

export async function saveEntidade({ commit }, obj) {
  const user = JSON.parse(JSON.stringify(obj));
  if (user.cep) {
    user.cep = user.cep.replace(/[^0-9]/g, '');
  }
  user.fone1 = user.fone1.replace(/[^0-9]/g, '');
  if (user.fone2) {
    user.fone2 = user.fone2.replace(/[^0-9]/g, '');
  }
  if (user.fone3) {
    user.fone3 = user.fone3.replace(/[^0-9]/g, '');
  }

  return (
    await http
      .post(`${urlApi}/update-entidade`, user)
      .then((res) => {
        success(res.data.data.message);
        return res;
      })
      .catch((err) => {
        return err;
      })
  ).data;
}


/**
 * Solicitar Reset de Senha
 */
export async function gePasswordReset({ commit }, user) {
  Loading.show();
  return await http
    .post(`${urlApi}/password-reset`, user)
    .then(res => {
      Loading.hide();
      success(res.data.data.message);
      return res;
    })
    .catch(err => {
      Loading.hide();
      error(err.error.message);
      return err;
    }).data;
}

/**
 * Resetando Senha
 */
export async function passwordReset({ commit }, user) {
  return await http
    .post(`${urlApi}/change-password`, user)
    .then(res => {
      Loading.hide();
      this.$router.push({ name: 'auth-login' }).catch(() => { });
      success('Senha alterada com sucesso!');
      return res;
    })
    .catch(err => {
      error(err.error.message);
      return err;
    }).data;
}

/**
 * Solicitação email de validação
 * @param {*} param0
 */
export async function emailConfirmation({ commit }) {
  Loading.show();
  return await http
    .get(`${urlApi}/email-confirmation`)
    .then(res => {
      Loading.hide();
      success(res.data.data.message);
    })
    .catch(err => {
      error(err.error.message);
      Loading.hide();
    });
}

/**
 * Saindo do sistema
 * @param {*} param0
 */
export async function logout({ commit }) {
  Vue.prototype.$echoNotification;
  Loading.show();
  commit('CLEAR_USER');
  window.location.href = '#/opcoes';
  Loading.hide();
}

/**
 * Buscando Usuário logado
 * @param {*} param0
 */
export async function me({ commit }) {
  return (
    await http
      .get(`${urlApi}/me?include=entidades,atribuicao`)
      .then(async res => {
        Loading.hide();
        // const scopes = await scopeList();
        // res.data.data.me.scopes = scopes;
        commit('SET_USER', res.data);
        return res.data;
      })
      .catch(() => {
        Loading.hide();
        this.logout({ commit });
      })
  ).data;
}

/**
 * Usamos essa função nas rotas (route/index.js)
 * @param {*} param0
 */
export async function checkToken() {
  return (
    await http
      .get(`${urlApi}/me?include=entidades,atribuicao`)
      .then(async res => {
        // const scopes = await scopeList();
        // res.data.data.me.scopes = scopes;
        return res;
      })
      .catch(() => {
        Loading.hide();
        this.logout({ commit });
      })
  ).data;
}

/**
 * Buscando os Scopes do Token corrente
 * @param {*} param0
 */
export async function scopeList() {
  return (
    await http
      .get(`${urlApi}/token/validate`)
      .then(res => {
        return res.data;
      })
      .catch(() => {
      })
  ).data.scopes;
}

/**
 * Buscando Company do usuário
 * @param {*} param0
 */
export async function getMyCompany({ commit }) {
  return (
    await http.get(`${urlApi}/my-company`).then(res => {
      Loading.hide();
      return res.data;
    })
  ).data;
}

/**
 * Removendo company corrente
 * @param {*} param0
 */
export async function selectPerfil({ commit }, obj) {
  if (obj.role === 'user') {
    return await http
      .post(`${urlApi}/reset-entidade-atual`)
      .then(async res => {
        // res.data.router_name = 'cei-visao-geral';
        commit('SAVE_TOKEN', res.data);
        const user = await me({ commit });
        return user.me;
      })
      .catch(() => {
        Loading.hide();
      });
  }

  if (obj.role === 'company') {
    return await http.post(`${urlApi}/set-entidade-atual`, obj).then(async res => {
      Loading.hide();
      // res.data.router_name = 'cei-visao-geral';
      commit('SAVE_TOKEN', res.data);
      const user = await me({ commit });
      return user.me;
    });
  }
}

/**
 * Cadastrando usuário
 * @param {*} param0
 * @param {*} obj
 */
export async function register({ state, commit }, obj) {
  Loading.show();

  const u = JSON.parse(JSON.stringify(state.user));

  u.cpfCnpj = u.cpfCnpj.replace(/[^0-9]/g, '');
  u.fone1 = u.fone1.replace(/[^0-9]/g, '');
  u.cep = u.cep.replace(/[^0-9]/g, '');

  return (
    await http
      .post(`${urlApi}/register`, u)
      .then(res => {
        Loading.hide();
        res.data.router_name = 'select-perfil';
        commit('SAVE_TOKEN', res.data);
        return res;
      })
      .catch(err => {
        Loading.hide();
        error(err.errors[Object.keys(err.errors)[0]]);
        return err;
      })
  ).data;
}

/**
 * Salvando usuário
 * @param {*} param0
 * @param {*} obj
 */
export async function userSave({ commit }, obj) {
  return (
    await http.put(`${urlApi}/users/${obj.id}`, obj).then(res => {
      return res;
    })
  ).data;
}

/**
 * Alterando senha da pessoa
 * @param {*} param0
 * @param {*} obj
 */
export async function updatePassword({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`${urlApi}/users/${obj.person.id}/password-update`, { password: obj.password })
      .then(res => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch(err => {
        Loading.hide();
        error(err.errors.password[0]);
      })
  ).data;
}

export async function deleteDatabase() {
  window.indexedDB.deleteDatabase('anoreg');
}

/**
 * Criando BancoLocal com tabela bio-user
 */
export async function createDbIndexdDB({ commit }) {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open('anoreg', '1');
    request.onerror = e => {
      reject('Error');
    };
    request.onsuccess = e => {
      resolve(e.target.result);
    };
    request.onupgradeneeded = e => {
      const db = e.target.result;
      const objectStore = db.createObjectStore('user-bio', {
        autoIncrement: true,
        keyPath: 'userbioid',
      });
    };
  });
}

/**
 * Pegando usuário BancoLocal
 * @param {*} param0
 */
export async function getUserbioFromDb({ commit }) {
  const db = await createDbIndexdDB(commit);
  return new Promise(resolve => {
    const trans = db.transaction(['user-bio'], 'readonly');
    const store = trans.objectStore('user-bio');
    const users = [];
    store.openCursor().onsuccess = e => {
      const cursor = e.target.result;
      if (cursor) {
        users.push(cursor.value);
        cursor.continue();
      }
      resolve(users);
    };
  });
}

/**
 * Incluindo usuário BancoLocal
 * @param {*} param0
 */
export async function addUserbio({ commit }, obj) {
  const db = await createDbIndexdDB(commit);
  return new Promise((resolve, reject) => {
    const trans = db.transaction(['user-bio'], 'readwrite');
    trans.oncomplete = e => {
      resolve();
    };
    const store = trans.objectStore('user-bio');
    store.add(obj);
  });
}

/**
 * Removendo usuário BancoLocal
 * @param {*} param0
 * @param {*} id
 */
export async function deleteCatFromDb({ commit }, id) {
  const db = await this.createDbIndexdDB(commit);
  return new Promise((resolve, reject) => {
    const trans = db.transaction(['user-bio'], 'readwrite');
    trans.oncomplete = e => {
      resolve();
    };

    const store = trans.objectStore('user-bio');
    store.delete(id);
  });
}

/**
 * Buscando parâmetros WARMUP para Certificado
 * Temos que criar outro store (Signature) e remover para lá
 * @param {*} param0
 * @param {*} obj
 */
export async function getWarmup({ commit }, obj) {
  return (
    await http
      .get(`${Vue.prototype.$anoreg.auth.urlApiCertificate}/v1/public/warmup`)
      .then(res => {
        commit('SET_WARMUP', res.data.data.warmup);
        return res.data;
      })
      .catch(() => { })
  ).data;
}

/**
 * Autenticação com Certificado
 * @param {*} param0
 * @param {*} obj
 */
export async function loginCertComplete({ commit }, obj) {
  return (
    await http
      .post(`${urlApi}/login`, obj)
      .then(res => {
        res.data.router_name = 'select-perfil';
        commit('SAVE_TOKEN', res.data);
        // me({ commit });
        return res;
      })
      .catch(err => {
        error(err.error.message);
        return { data: err };
      })
  ).data;
}

/**
 * Iniciando assinatura de documento
 * @param {*} param0
 * @param {*} obj
 */
export async function singPadesStart({ commit }, obj) {
  return (
    await http
      .post(`${Vue.prototype.$anoreg.auth.urlApiCertificate}/sign-pades-start`, obj)
      .then(res => {
        // commit('SET_WARMUP', res.data.data.warmup);
        // return res.data;
      })
      .catch(() => { })
  ).data;
}
