export default function () {
  return {
    module: {
      filter: '',
    },
    // Categorias de Módulos
    categories: ['Minha conta', 'Serviços', 'Integrações', 'Consultas e informações', 'Anoreg'],
    modulesAll: [],
    modulesEmpresa: [],
  };
}
