import miscEstado from './estado';
import miscCidade from './cidade';
import miscCartorio from './cartorio';
import miscDominio from './dominio';
import miscCalendario from './calendario';

export default {
  miscEstado,
  miscCidade,
  miscCartorio,
  miscDominio,
  miscCalendario,
};
