export default function () {
  return {
    respostas: {
      data: [],
    },
    resposta: {
      attributes: {
        descricao: '',
        modeloRespostaId: '',
        reconhecimento: '',
        probabilidade: '',
      },
      relationships: {
      },
    },
  };
}
