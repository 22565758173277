/*
export function someGetter (state) {
}
*/

export function getFiltros(state) {
  let filtro = '';
  if (typeof state.contaPagarFiltros.periodo === 'object') {
    if (state.contaPagarFiltros.periodo.from) {
      filtro += `&filter[periodoGerado]=${state.contaPagarFiltros.periodo.from.replaceAll('/', '-')},${state.contaPagarFiltros.periodo.to.replaceAll('/', '-')}`;
    }
  }
  if (typeof state.contaPagarFiltros.periodo === 'string') {
    filtro += `&filter[periodoGerado]=${state.contaPagarFiltros.periodo.replaceAll('/', '-')},${state.contaPagarFiltros.periodo.replaceAll('/', '-')}`;
  }

  filtro += '&filter[status]=P';

  return filtro;
}
