/*
export function someMutation (state) {
}
*/

export function SET_PLANO_ACAO_CARTAO(state, obj) {
  if (obj) {
    state.planoAcaoCartao = obj;
  } else {
    state.planoAcaoCartao = {
      attributes: {
        titulo: '',
      },
      relationships: {
      },
    };
  }
}


export function SET_ETAPA_PLANO_ACAO_CARTAO(state, etapa) {
  state.planoAcaoCartao.attributes.etapa = etapa;
  if (etapa === 'concluido') {
    state.planoAcaoCartao.attributes.status = 'concluido';
  } else {
    state.planoAcaoCartao.attributes.status = 'pendente';
  }
}
