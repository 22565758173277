/*
export function someMutation (state) {
}
*/


export function SET_LOTES(state, list) {
  state.lotes = list;
  state.meta.rowsPerPage = list.meta.per_page;
}

export function SET_STATUS_PEDIDOS_DASHBOARD(state, obj) {
  state.dashboardLote.status = obj;
}

export function SET_FINANCEIRO_VALORES_DASHBOARD(state, obj) {
  state.dashboardLote.financeiro = obj;
}

export function SET_LOTES_NOVO_PEDIDO_IMPORTADO(state, obj) {
  const lote = state.lotes.data.find(l => l.id === obj.loteId);
  lote.attributes.status = obj.loteStatus;
  lote.attributes.pedidos_importados = obj.pedidosImportados;
}

export function SET_LOTE_ACOES(state, list) {
  state.loteAcoes = list;
}

export function SET_ACAO_ATUAL(state, obj) {
  state.acaoAtual = obj;
}
