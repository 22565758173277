import date from 'quasar/src/utils/date.js';;


function filtroBase(state) {
  let filter = `&filter[exportado]=${state.filtro.xmlExportado}`;

  if (state.filtro.naoRecebidoComSucesso) {
    filter += `&filter[recebidoComSucesso]=${!state.filtro.naoRecebidoComSucesso}`;
    // filter += '&filter[arquivado]=1';
  } else {
    filter += '&filter[arquivado]=0';
  }

  if (state.filtro.dataEnvioInicio) {
    let dataInicio = date.extractDate(state.filtro.dataEnvioInicio, 'DD/MM/YYYY');
    dataInicio = date.formatDate(dataInicio, 'YYYY/MM/DD');
    let dataFim = date.extractDate(state.filtro.dataEnvioFim, 'DD/MM/YYYY');
    dataFim = date.formatDate(dataFim, 'YYYY/MM/DD');
    filter += `&filter[periodoEnvio]=${dataInicio} 00:00:00,${dataFim} 23:59:59`;
  }

  if (state.filtro.dataAtoInicio) {
    let dataInicio = date.extractDate(state.filtro.dataAtoInicio, 'DD/MM/YYYY');
    dataInicio = date.formatDate(dataInicio, 'YYYY/MM/DD');
    let dataFim = date.extractDate(state.filtro.dataAtoFim, 'DD/MM/YYYY');
    dataFim = date.formatDate(dataFim, 'YYYY/MM/DD');
    filter += `&filter[periodoAto]=${dataInicio} 00:00:00,${dataFim} 23:59:59`;
  }

  if (state.filtro.atoHomologacao) {
    filter += `&filter[atoHomologacao]=${state.filtro.atoHomologacao}`;
  }

  if (state.filtro.atoId) {
    filter += `&filter[atoId]=${state.filtro.atoId}`;
  }

  if (state.filtro.atoCodServico) {
    filter += `&filter[atoCodServico]=${state.filtro.atoCodServico.id}`;
  }

  if (state.filtro.atoProtocolo) {
    filter += `&filter[atoProtocolo]=${state.filtro.atoProtocolo}`;
  }

  if (state.filtro.atoCodLivro) {
    filter += `&filter[atoCodLivro]=${state.filtro.atoCodLivro}`;
  }

  if (state.filtro.atoLivro) {
    filter += `&filter[atoLivro]=${state.filtro.atoLivro}`;
  }

  if (state.filtro.atoFolha) {
    filter += `&filter[atoFolha]=${state.filtro.atoFolha}`;
  }

  if (state.filtro.integracoes.length < 3) {
    filter += `&filter[integracoes]=${state.filtro.integracoes.join(',')}`;
  }

  if (state.filtro.integracoesStatus.length < 3) {
    filter += `&filter[integracoesStatus]=${state.filtro.integracoesStatus.join(',')}`;
  }

  if (state.filtro.protocoloCei) {
    filter += `&filter[protocoloCei]=${state.filtro.protocoloCei}`;
  }

  return filter;
}

export function getFiltroPage(state) {
  return filtroBase(state);
}

export function getFiltro(state) {
  let filter = filtroBase(state);

  if (state.filtro.protocoloSelecionados.length) {
    filter += `&filter[ids]=${state.filtro.protocoloSelecionados}`;
  }

  return filter;
}
