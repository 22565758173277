/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import axios from 'axios';
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'v1/public/cidades';

/**
 * Buscando todos os Tipo de Pedido
 * @param {*} param0
 */
export async function cidadeList({ commit }, obj) {
  return (
    await http.get(`${urlApi}${obj.filter}`).then((res) => {
      commit('SET_CIDADES', res.data.data);
      return res.data;
    })
  ).data;
}

export async function cidadeSearchCep({ commit }, cep) {
  return (
    await axios.get(`https://viacep.com.br/ws/${cep.replace(/[^a-z0-9]/g, '')}/json`)
      .then(async (res) => {
        const endereco = {};
        const arrayLogradouro = res.data.logradouro.split(' ');
        [endereco.tipo] = arrayLogradouro;
        endereco.nome = res.data.logradouro.substring(
          res.data.logradouro.indexOf(endereco.tipo) + endereco.tipo.length,
        );
        endereco.bairro = res.data.bairro;
        endereco.municipio = res.data.localidade;
        endereco.uf = res.data.uf;
        endereco.ibge_code = res.data.ibge;

        [endereco.cidade] = await cidadeList({ commit }, { filter: `?filter[ibge_code]=${res.data.ibge}` });

        commit('SET_CIDADE', endereco.cidade);

        return { data: endereco };
      })
      .catch((err) => {
        return { data: err };
      })
  ).data;
}
