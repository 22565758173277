/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'financeiro/api/v1';

/**
 * Listando Compras
 */
export async function comprasList({ commit }) {
  Loading.show({ message: '' });
  return (await http.get(`${urlApi}/compras`)
    .then((res) => {
      commit('SET_COMPRAS', res.data);
      Loading.hide();
      return res;
    })
    .catch(() => {
      Loading.hide();
    })
  ).data;
}

/**
 * Comprando créditos
 */
export async function compraCredito({ commit }, obj) {
  const credito = JSON.parse(JSON.stringify(obj));

  credito.quantidade = Number(credito.quantidade.toString()
    .replace(/\./g, '')
    .replace(/,/g, '.'));

  return (await http.post(`${urlApi}/comprar-creditos`, credito)
    .then((res) => {
      Loading.hide();
      success('Compra realizada com sucesso!');

      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
 * Cancelando compra
 */
export async function comprasCancelar({ commit }, compraId) {
  return (await http.post(`${urlApi}/compras/${compraId}/cancelar`)
    .then((res) => {
      success(res.data.data.message);
      return res;
    })
  ).data;
}
