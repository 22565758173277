export default function () {
  return {
    filtro: {
      loading: false,
      periodo: '',
      tipoTermoConsultado: 'cpf',
      termoConsultado: '',
      servico: '',
      cartorio: '',
      livrosIds: [],
      livroId: '',
      atoId: '',
      dataInicio: '',
      dataFim: '',
      visualEletronico: 1,
      cidadeId: null,
      regularizacaoFundiaria: false,
    },
    servicosBasicos: {
      data: [],
    },
  };
}
