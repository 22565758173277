import date from 'quasar/src/utils/date.js';;

export function SET_DASHBOARD(state, obj) {
  state.dashboard = obj;
  state.loadingConsultaDashboard = false;
}

export function SET_LOADING_DASHBOARD(state) {
  state.loadingConsultaDashboard = true;
}

export function SET_CONSULTAS(state, list) {
  state.consultas = list;
}

export function PUSH_CONSULTAS(state, list) {
  list.data.map((cc) => {
    state.consultas.data.push(cc);
    return true;
  });

  state.consultas.meta = list.meta;
}

export function RESET_FILTER(state) {
  const dateNow = Date.now();
  const primeiroDia = `${date.formatDate(date.subtractFromDate(dateNow, { month: 1 }), 'YYYY-MM-DD')} 00:00:00`;
  const ultimoDia = `${date.formatDate(dateNow, 'YYYY-MM-DD')} 23:59:59`;

  state.filtro = {
    periodo: { from: primeiroDia, to: ultimoDia },
  };
}
