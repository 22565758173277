const routes = [
  {
    path: '/meu-orgao-publico',
    component: () => import('layouts/meu-orgao-publico.vue'),
    children: [
      // Visão geral
      {
        path: '',
        redirect: 'visao-geral',
      },
      {
        path: 'calendario-envio',
        name: 'meu-orgao-publico-calendario-envio-ato',
        meta: {
          title: '',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
            'orgao-publico',
          ],
        },
        component: () => import('pages/cei/acesso-restrito/calendario-envio-ato.vue'),
      },
      {
        path: 'lista-cartorios-envio-10-dias',
        name: 'meu-orgao-publico-lista-cartorios-envio-10-dias',
        meta: {
          title: '',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
            'orgao-publico',
          ],
        },
        component: () => import('pages/cei/acesso-restrito/lista-cartorios-envio-10-dias.vue'),
      },
      {
        path: 'cartorio-envio-10-dias/:cns',
        name: 'meu-orgao-publico-show-cartorio-envio-10-dias',
        meta: {
          title: '',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
            'orgao-publico',
          ],
        },
        component: () => import('pages/cei/acesso-restrito/show-cartorio-envio-10-dias.vue'),
      },
      {
        path: 'visao-geral',
        name: 'meu-orgao-publico-visao-geral',
        meta: {
          title: '',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
            'orgao-publico',
          ],
        },
        component: () => import('pages/cei/acesso-restrito/visao-geral-orgao-publico.vue'),
      },
      {
        path: 'correicao-portaria',
        name: 'orgao-publico-correncao-portaria',
        meta: {
          title: 'Portarias',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
            'orgao-publico',
          ],
        },
        component: () => import('pages/cei/correicao/cei-correicao-portaria.vue'),
      },
      {
        path: 'correicao-on-line',
        name: 'orgao-publico-correncao-online',
        meta: {
          title: 'Correição on-line',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
            'orgao-publico',
          ],
        },
        component: () => import('pages/cei/correicao/cei-correicao-ato.vue'),
      },
      {
        path: 'gestao-organizacao',
        name: 'meu-orgao-publico-lgpd-cartorio-list',
        meta: {
          title: 'Gestão e Organização',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
            'orgao-publico',
          ],
        },
        component: () => import('pages/cei/acesso-restrito/lgpd-cartorio-list.vue'),
      },
      {
        path: 'gestao-organizacao-auditoria',
        name: 'meu-orgao-publico-lgpd-auditoria-list',
        meta: {
          title: '',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
            'orgao-publico',
          ],
        },
        component: () => import('pages/cei/acesso-restrito/lgpd-auditoria-list.vue'),
      },
      {
        path: 'gestao-organizacao-auditoria/:auditoriaId',
        name: 'meu-orgao-publico-lgpd-auditoria-show',
        meta: {
          title: 'Auditorias',
          breadCrumb: [
            // { label: 'Gestão e organização', path: '' },
            // { label: 'Plano de ação', path: 'lgpd-plano-acao' },
          ],
          requiresAuth: true,
          emDesenvolvimento: true,
          scopes: ['start-auditor'],
        },
        component: () => import('pages/cei/acesso-restrito/lgpd-auditoria-show.vue'),
      },
      {
        path: 'regularizacao-fundiaria',
        name: 'orgao-publico-regularizacao-fundiaria-cartorios',
        meta: {
          title: '',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
            'orgao-publico',
          ],
        },
        component: () => import('pages/cei/acesso-restrito/regularizacao-fundiaria-cartorios.vue'),
      },
      {
        path: 'regularizacao-fundiaria-cartorio/:cns',
        name: 'orgao-publico-regularizacao-fundiaria-cartorio',
        meta: {
          title: '',
          breadCrumb: [
          ],
          requiresAuth: true,
          scopes: [
            'orgao-publico',
          ],
        },
        component: () => import('pages/cei/acesso-restrito/regularizacao-fundiaria-cartorio-atos.vue'),
      },

    ],
  },
];

export default routes;
