/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lote';

export async function loteList({ state, commit }, obj) {
  state.loteListloading = true;
  state.lotes.data = [];
  return (
    await http
      .get('/distribuicao/api/v1/lote')
      .then((res) => {
        state.loteListloading = false;
        commit('SET_LOTES', res.data);
        return res;
      })
      .catch((err) => {
        state.loteListloading = false;
        return err;
      })
  ).data;
}

export async function loteStatusPedido({ state, commit }, loteId) {
  return (
    await http
      .get(`/distribuicao/api/v1/lote/${loteId}/statusPedidos`)
      .then((res) => {
        commit('SET_STATUS_PEDIDOS_DASHBOARD', res.data.data);
        return res;
      })
  ).data;
}

export async function loteFinanceiroValores({ state, commit }, loteId) {
  return (
    await http
      .get(`/distribuicao/api/v1/lote/${loteId}/financeiroValores`)
      .then((res) => {
        commit('SET_FINANCEIRO_VALORES_DASHBOARD', res.data.data);
        return res;
      })
  ).data;
}

/**
 * Buscando as ações que lote pode executar
 * @param {*} param0
 * @param {*} obj
 */
export async function loteAcaoList({ commit }, loteId) {
  Loading.show({
    message: 'Buscando ações do lote...',
  });
  return (
    await http
      .get(`/distribuicao/api/v1/lote/${loteId}/acoes`)
      .then((res) => {
        Loading.hide();
        commit('SET_LOTE_ACOES', res.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Execultando ação note lote
 * @param {*} param0
 * @param {*} obj
 */
export async function loteAcaoExecutar({ commit }, obj) {
  Loading.show({
    message: obj.messageLoad ? obj.messageLoad : 'Execultando ação do lote...',
  });
  return (
    await http
      .post(`/distribuicao/api/v1/lote/${obj.lote_id}/acao-executar`, obj)
      .then((res) => {
        if (res.data.message) {
          Loading.hide();
          success(res.data.message);
        }
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}


export async function loteExportar({ commit }, obj) {
  Loading.show({
    message: 'Exportando pedidos do lote...',
  });
  return (
    await http
      .post(`/distribuicao/api/v1/lote/${obj.id}/exportar`, obj.params)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function loteImportar({ commit }, obj) {
  Loading.show({
    message: 'Importando pedidos do lote...',
  });
  return (
    await http
      .post(`/distribuicao/api/v1/lote/${obj.id}/importar`, obj.pedidos)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function loteFinanceiro({ state, commit }, obj) {
  Loading.show({
    message: '',
  });
  return (
    await http
      .get(`/distribuicao/api/v1/lote/${obj.loteId}/financeiro`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function loteGeraBoleto({ commit }, obj) {
  Loading.show({
    message: 'Gerando boleto do lote...',
  });
  return (
    await http
      .post(`/distribuicao/api/v1/lote/${obj.loteId}/gera-boleto`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
