export default function () {
  return {
    filtro: {
      cns: '',
      atoSelecionados: [],
      periodo: { from: '', to: '' },
      dataAtoInicio: '',
      dataAtoFim: '',
      numeroSelo: '',
      atoLivro: '',
      atoFolha: '',
      atoCodProtocolo: '',
      atoCodLivro: '',
      atoId: '',
      atoCodServico: '',
    },
    atos: {
      data: [],
      meta: {},
    },
    ato: {
      attributes: {},
      relationships: {
        cartorio: {
          relationships: {},
        },
        gif: {},
      },
    },
  };
}
