export default function () {
  return {
    fornecedores: {
      data: [],
    },
    fornecedorDashboard: {
      atttributes: {
        processos: [],
        modeloRegulamentos: [],
        modeloContextos: [],
        modeloInformacoes: [],
      },
    },
    fornecedor: {
      attributes: {
        cnpj: '',
        nome: '',
      },
      relationships: {
        perguntas: [],
        arquivos: [],
        dado: {
          attributes: {
            avaliacaoAtendimento: 1,
            avaliacaoPrazoEntrega: 1,
            avaliacaoQualidadeProduto: 1,
            observacao: '',
            dataContrato: null,
            dataContratoRevisado: null,
          },
        },
        cidade: {
          attributes: { name: '' },
          relationships: {
            estado: {
              attributes: { abbreviation: '' },
            },
          },
        },
      },
    },

  };
}
