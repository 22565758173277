/*
export function someMutation (state) {
}
*/

export function SET_PLANO_ACAO_CARTAO_ITEM(state, obj) {
  if (obj) {
    state.planoAcaoCartaoItem = obj;
  } else {
    state.planoAcaoCartaoItem = {
      attributes: {},
      relationships: {},
    };
  }
}
