import Notify from 'quasar/src/plugins/Notify.js';;

export const success = (msg) => {
  // Mostando mensagem de sucesso
  Notify.create({
    message: msg,
    color: 'green',
    icon: 'done',
    actions: [
      {
        icon: 'close', // optional
        noDismiss: false, // não fechar
        handler: () => {
        },
      },
    ],
  });
};

export const alert = (msg) => {
  // Mostando mensagem de Alerta
  Notify.create({
    message: msg,
    color: 'orange',
    icon: 'done',
    actions: [
      {
        icon: 'close', // optional
        noDismiss: false, // não fechar
        handler: () => {
        },
      },
    ],
  });
};

export const error = (msg) => {
  // Mostando mensagem de sucesso
  Notify.create({
    message: msg,
    color: 'red-6',
    icon: 'error',
    actions: [
      {
        color: 'white',
        icon: 'close', // optional
        noDismiss: false, // não fechar
        handler: () => {
        },
      },
    ],
  });
};
