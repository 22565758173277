/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable */
import authStore from '../../auth/auth';
import { checkError } from '../../../services/httpMessage';

/**
 * Filtrando módulos
 * @param {*} state
 */
export function categoryAll(state) {
  // Caso Usuário não tem acesso
  if (!authStore.state.user.scopes) {
    return [];
  }

  const modules = [];

  // Percorrendo todos os módulos
  state.modulesAll.map(m => {
    let module = null;
    // Percorrento permissões do usuário
    authStore.state.user.scopes.map(scopeUser => {
      // verificando se usuário tem permissão no módulo
      const i = m.relationships.scopes.data.findIndex(scope => scope.scope === scopeUser.scope);

      // Caso encontrado uma permissão, seta variavel module
      if (i > -1) {
        if (state.module.filter) {
          if (m.attributes.title.toLowerCase().indexOf(state.module.attributes.filter.toLowerCase()) != -1) {
            module = m;
          }
        } else {
          module = m;
        }
      }
      return true;
    });
    // Caso usuário tenha alguma permissão no módulo
    if (module) {
      modules.push(module);
      state.modulesEmpresa.push(module);
    }

    return true;
  });

  // Agrupando modulos
  const categories = [];
  state.categories.map(category => {
    groupBy(modules, 'group_name').map(c => {
      if (c.name === category) categories.push(c);
      return true;
    });
    return true;
  });

  categories.map(category => {
    category = category.modules.sort(orderModule);
  });

  return categories; // Retornando categorias
}

/**
 * Agrupando módulos
 * @param {*} modules
 * @param {*} key
 */
function groupBy(modules, key) {
  const c = modules.reduce((rv, x) => {
    (rv[x.attributes[key]] = rv[x.attributes[key]] || []).push(x);
    return rv;
  }, {});

  const categories = [];

  Object.keys(c).forEach(item => {
    const category = {
      name: item,
      modules: c[item],
    };
    categories.push(category);
  });
  return categories;
}

function orderModule(a, b) {
  if (a.order < b.order) {
    return -1;
  }
  if (a.order > b.order) {
    return 1;
  }
  return 0;
}
