/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1/base-conhecimentos';


export async function baseConhecimentoItemGet({ state, commit }, id) {
  Loading.show();
  return (
    await http.get(`lgpd/api/v1/base-conhecimento-itens/${id}`)
      .then((res) => {
        Loading.hide();
        commit('SET_BASE_CONHECIMENTO_ITEM', res.data.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function baseConhecimentoItemSave({ state, commit }, obj) {
  Loading.show({ message: 'Salvando ...' });
  const baseConhecimentoItem = JSON.parse(JSON.stringify(state.baseConhecimentoItem));

  if (state.baseConhecimentoItem.id) {
    return (
      await http.put(`${urlApi}/${obj.baseConhecimento.id}/base-conhecimento-itens/${state.baseConhecimentoItem.id}`, baseConhecimentoItem)
        .then((res) => {
          Loading.hide();
          success(res.data.data.message);
          commit('SET_BASE_CONHECIMENTO_ITEM', res.data.data.baseConhecimentoItem);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }

  return (
    await http.post(`${urlApi}/${obj.baseConhecimento.id}/base-conhecimento-itens`, baseConhecimentoItem)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        commit('SET_BASE_CONHECIMENTO_ITEM', res.data.data.baseConhecimentoItem);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function baseConhecimentoItemRemove({ state, commit }, obj) {
  Loading.show({ message: 'Removendo...' });
  return (await http.delete(`${urlApi}/${obj.baseConhecimento.id}/base-conhecimento-itens/${obj.baseConhecimentoItem.id}`)
    .then((res) => {
      Loading.hide();
      success(res.data.data.message);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function baseConhecimentoItemArquivoSave({ state, commit }, obj) {
  Loading.show({ message: 'Salvando arquivo...' });
  return (await http.post(`lgpd/api/v1/base-conhecimento-itens/${state.baseConhecimentoItem.id}/arquivos`, obj.arquivo)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function baseConhecimentoItemArquivoDelete({ state, commit }, obj) {
  Loading.show({ message: 'Salvando arquivo...' });
  return (await http.delete(`lgpd/api/v1/base-conhecimento-itens/${state.baseConhecimentoItem.id}/arquivos/${obj.arquivo.id}`)
    .then((res) => {
      Loading.hide();
      success(res.data.data.message);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function baseConhecimentoItemArquivoDownload({ state, commit }, obj) {
  Loading.show({ message: 'Buscando arquivo ...' });
  return (await http.get(`lgpd/api/v1/base-conhecimento-itens/${state.baseConhecimentoItem.id}/arquivos/${obj.arquivo.id}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function baseConhecimentoItemAttachItem({ state, commit }, obj) {
  Loading.show({ message: 'Vinculando evidência ...' });
  return (await http.post(`lgpd/api/v1/base-conhecimento-itens/${state.baseConhecimentoItem.id}/base-conhecimento-itens/attach`, { baseConhecimentoItem: obj.item })
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function baseConhecimentoItemDetachItem({ state, commit }, obj) {
  Loading.show({ message: 'Removendo evidência ...' });
  return (await http.post(`lgpd/api/v1/base-conhecimento-itens/${state.baseConhecimentoItem.id}/base-conhecimento-itens/detach`, { baseConhecimentoItem: obj.item })
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
