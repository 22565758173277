/* eslint-disable arrow-body-style */
import authStore from '../../auth';
/**
 * Filtrando módulos
 * @param {*} state
 */
export function listModules(state) {
  // caso Usuário não tem acesso
  if (!authStore.state.user.scopes) {
    return [];
  }

  let modules = []; // JSON.parse(JSON.stringify(state.modules));

  state.modules.map((m) => {
    const userModule = { title: m.title, categories: [] };
    m.categories.map((c) => {
      const i = authStore.state.user.scopes.findIndex(scopeUser => scopeUser.slug === c.slug);
      if (i > -1) {
        userModule.categories.push(c);
      }
      return true;
    });
    modules.push(userModule);
    return true;
  });

  if (state.module.filter) {
    modules = modules.filter((module) => {
      module.categories = module.categories.filter((category) => {
        return category.title.toLowerCase().indexOf(state.module.filter.toLowerCase()) > -1;
      });
      return module;
    });
  }
  return modules;
}
