/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'entities';

/**
 * Buscando entidades
 * @param {*} param0
 * @param {*} obj
 */
export async function listEntidade({ commit }, obj) {
  return (await http.get(urlApi)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      error(`${err.error.message}`);
      return err;
    })
  ).data;
}

/**
 * Buscando Entidade
 * @param {*} param0
 * @param {*} obj
 */
export async function getEntidade({ commit }, autorId) {
  Loading.show();
  return (await http.get(`${urlApi}/${autorId}?include=files`)
    .then((res) => {
      Loading.hide();
      commit('SET_ENTIDADE', res.data.data);
      return res;
    })
    .catch((err) => {
      error(`${err.error.message}`);
      Loading.hide();
      return err;
    })
  ).data;
}
