/*
export function someMutation (state) {
}
*/

export function CLEAR_SERVICOS_BASICOS(state) {
  state.servicosBasicos.data = [];
}

export function SET_SERVICOS_BASICOS(state, array) {
  state.servicosBasicos = array;
}

export function PUSH_SERVICOS_BASICOS(state, array) {
  array.data.map((servico) => {
    state.servicosBasicos.data.push(servico);
    return true;
  });

  state.servicosBasicos.meta = array.meta;
}

export function SET_FILTRO_SERVICO(state, servico) {
  state.filtro.servico = servico;
}

export function SET_FILTRO_LIVROS(state, livros) {
  state.filtro.livrosIds = livros;
}

export function SET_FILTRO_REGULARIZACAOFUNDIARIA(state, status) {
  state.filtro.regularizacaoFundiaria = status;
}

export function RESET_FILTRO(state) {
  state.filtro.servico = '';
  state.filtro.livrosIds = [];
  state.filtro.cartorio = '';
  state.filtro.atoId = '';
  state.filtro.dataInicio = '';
  state.filtro.dataFim = '';
  state.filtro.regularizacaoFundiaria = false;
  state.filtro.cidadeId = null;
}
