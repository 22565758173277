const routes = [
  {
    path: '/estatisticas',
    component: () => import('layouts/clean.vue'),
    children: [
      {
        path: '',
        name: 'estatistica',
        meta: {
          title: '',
          scopes: [],
        },
        component: () => import('pages/estatistica/index.vue'),
      },
      {
        path: 'consulta-visualizacao',
        name: 'estatistica-consulta-visualizacao',
        meta: {
          title: '',
          scopes: [],
        },
        component: () => import('pages/estatistica/consulta-visualizacao.vue'),
      },
    ],
  },
];

export default routes;
