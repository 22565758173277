/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = '/v1';

/**
* Consultando Identificação Civil
* @param {*} param0
*/
export async function consultaIdentificacaoCivil({ commit }, obj) {
  return (await http.get(`${urlApi}/identificacao-civil?${obj.filtro}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    })
  ).data;
}

/**
* Consultando Identificação Civil
* @param {*} param0
*/
export async function consultaDocumentoExtraviadoCivil({ commit }, obj) {
  return (await http.get(`${urlApi}/documentos-extraviados?${obj.filtro}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    })
  ).data;
}
