const routes = [
  {
    path: '/geolocalizacao',
    component: () => import('layouts/geolocalizacao.vue'),
    children: [
      {
        path: '',
        name: 'geo-index',
        meta: {
          title: 'Localização',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-geo', 'r-geo'],
        },
        component: () => import('pages/geolocalizacao/Index.vue'),
      },
      {
        path: 'mapa/:location',
        name: 'geo-map',
        meta: {
          title: 'Localização',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-geo', 'r-geo'],
        },
        component: () => import('pages/geolocalizacao/map.vue'),
      },
    ],
  },
];

export default routes;
