/*
export function someGetter (state) {
}
*/
export function bannerNovoPedidoPage(state) {
  const dominios = state.dominios.data.filter(b => b.attributes.meta);
  return dominios.filter(b => b.attributes.meta.ativo && b.attributes.titulo === 'cei-novo-pedido-page');
}

export function bannerNovoPedidoModal(state) {
  const dominios = state.dominios.data.filter(b => b.attributes.meta);
  return dominios.filter(b => b.attributes.meta.ativo && b.attributes.titulo === 'cei-novo-pedido-modal');
}

export function bannerVisaoGeralPage(state) {
  const dominios = state.dominios.data.filter(b => b.attributes.meta);
  return dominios.filter(b => b.attributes.meta.ativo && b.attributes.titulo === 'cei-visao-geral-page');
}

export function bannerPesquisaDinamicaPage(state) {
  const dominios = state.dominios.data.filter(b => b.attributes.meta);
  return dominios.filter(b => b.attributes.meta.ativo && b.attributes.titulo === 'cei-pesquisa-dinamica-page');
}
