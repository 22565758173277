/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'authors';

/**
 * Buscando autores
 * @param {*} param0
 * @param {*} obj
 */
export async function listAutor({ commit }, obj) {
  return (await http.get(`${urlApi}?random=1`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      error(`${err.error.message}`);
      return err;
    })
  ).data;
}

/**
 * Buscando autor
 * @param {*} param0
 * @param {*} obj
 */
export async function getAutor({ commit }, autorId) {
  Loading.show();
  return (await http.get(`${urlApi}/${autorId}?include=latestFiles,proficiencies`)
    .then((res) => {
      Loading.hide();
      commit('setAutor', res.data.data);
      return res;
    })
    .catch((err) => {
      error(`${err.error.message}`);
      Loading.hide();
      return err;
    })
  ).data;
}

/**
 * Salvando altor
 * @param {*} param0
 * @param {*} obj
 */
export async function saveAutor({ commit }, obj) {
  Loading.show();
  const autor = JSON.parse(JSON.stringify(obj));
  // if (autor.id) {
  //   delete autor.cpf;
  // }

  return (await http.post(`${urlApi}`, autor)
    .then((res) => {
      Loading.hide();
      success(res.data.data.message);
      // commit('insertCompetencia', res.data.data.proficiency);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
 * Enviando imagem para Avatar do Autor
 * @param {*} param0
 * @param {*} obj
 */
// export async function uploadAvatar({ commit }, obj) {

// }

/**
 * Buscando Comperencia
 * @param {*} param0
 * @param {*} obj
 */
export async function getAutorCompetencias({ commit }, autorId) {
  Loading.show();
  return (await http.get(`${urlApi}/${autorId}/proficiencies`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      error(`${err.error.message}`);
      Loading.hide();
      return err;
    })
  ).data;
}

/**
 * Salvando Competencia
 * @param {*} param0
 * @param {*} obj
 */
export async function saveAutorCompetencias({ commit }, obj) {
  Loading.show();
  if (obj.proficiencie.id) {
    return (await http.put(`${urlApi}/${obj.autor.id}/proficiencies/${obj.proficiencie.id}`, obj.proficiencie)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch((err) => {
        error(`${err.error.message}`);
        Loading.hide();
        return err;
      })
    ).data;
  }

  return (await http.post(`${urlApi}/${obj.autor.id}/proficiencies`, obj.proficiencie)
    .then((res) => {
      Loading.hide();
      success(res.data.data.message);
      commit('insertCompetencia', res.data.data.proficiency);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
 * Removendo Competência do Autor
 * @param {*} param0
 * @param {*} obj
 */
export async function deleteAutorCompetencia({ commit }, obj) {
  return (await http.delete(`${urlApi}/${obj.autor.id}/proficiencies/${obj.proficiencie.id}`)
    .then((res) => {
      Loading.hide();
      success(res.data.data.message);
      commit('removeCompetencia', obj.proficiencie.id);
      return res;
    })
    .catch((err) => {
      error(`${err.error.message}`);
      Loading.hide();
      return err;
    })
  ).data;
}
