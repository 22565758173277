/*
export function someMutation (state) {
}
*/

export function SET_SERVICO_LIST(state, list) {
  state.servicos = list;
}

export function SET_SERVICO(state, obj) {
  state.servico = obj;
}
