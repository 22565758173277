/*
export function someMutation (state) {
}
*/

export function SET_PLANO_ACAO_LIST(state, list) {
  state.planosAcao = list;
}


export function SET_PLANO_ACAO(state, obj) {
  state.planoAcao = obj;
}
