/*
export function someMutation (state) {
}
*/


export function SET_NONCE_WARMUP(state, obj) {
  state.nonceWarmup = obj;
}

export function SET_CERTIFICADO_ATUAL(state, obj) {
  state.certificado = obj;
}
