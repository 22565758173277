const routes = [
  {
    path: '/sesp',
    component: () => import('layouts/sesp.vue'),
    children: [
      // Visão geral
      {
        path: '',
        redirect: 'visao-geral',
      },
      {
        path: 'visao-geral',
        name: 'sesp-visao-geral',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'Sesp', path: '' },
            { label: 'Visão geral', path: 'sesp-visao-geral' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/sesp/visao-geral.vue'),
      },
      // Consulta Documento extraviado
      {
        path: 'consulta/documento-extraviado',
        name: 'sesp-consulta-documento-extraviado',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'Sesp', path: '' },
            { label: 'Consulta', path: 'sesp-consulta-documento-extraviado' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/sesp/consulta-documento-extraviado.vue'),
      },
      // Consulta Identificação civil
      {
        path: 'consulta/identificacao-civil',
        name: 'sesp-consulta-identificacao-civil',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'Sesp', path: '' },
            { label: 'Consulta', path: 'sesp-consulta-identificacao-civil' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/sesp/consulta-identificacao-civil.vue'),
      },

    ],
  },
];

export default routes;
