/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'central/api/acesso-restrito/consultas';

/**
 * Dashboard
 */
export async function consultaDashboardGet({ commit }, obj) {
  return (await http.get(`${urlApi}/dashboard?${obj.filter}`)
    .then((res) => {
      commit('SET_DASHBOARD', res.data);
      return res;
    })
  ).data;
}

/**
 * Listando Consultas
 */
export async function consultaList({ commit }, obj) {
  return (await http.get(`${urlApi}/?include=vendas&${obj.page}&${obj.filter}`)
    .then((res) => {
      // commit('SET_CONTA_CORRENTE', res.data);
      return res;
    })
  ).data;
}
