/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'pedido';

/**
 * Buscando mensagens
 * @param {*} param0
 * @param {*} obj
 */
export async function listMessage({ commit }, obj) {
  // Loading.show();
  return (
    await http
      .get(
        `/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/mensagens?${obj.page}&include=remetente,arquivo,entidade`,
      )
      .then((res) => {
        Loading.hide();
        // commit('SET_MENSAGENS', res.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Enviando mensagem
 * @param {*} param0
 * @param {*} obj
 */
export async function saveMessage({ commit }, obj) {
  // Loading.show();
  return (
    await http
      .post(
        `/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/mensagens?include=arquivo`,
        obj,
      )
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Marcando mensagem como Lida
 * @param {*} param0
 * @param {*} obj
 */
export async function notifiedMessage({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/mensagens/${obj.messageId}/lido`,
      )
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Gerando relatório das mensagens
 * @param {*} param0
 * @param {*} obj
 */
export async function printMessage({ commit }, obj) {
  return (
    await http
      .get(
        `/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/mensagens-historico`,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        },
      )
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
 * Gerando Meet Session
 * @param {*} param0
 */
export async function pedidoMeetSession({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/sessions`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Gerando Meet Token
 * @param {*} param0
 */
export async function pedidoMeetToken({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/tokens`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function pedidoMeetSessionRecordingStart({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/tokens`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function pedidoMeetSessionRecordingStop({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/tokens`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function mensagemListaAppV2({ state, commit }, obj) {
  state.mensagensLoad = true;
  return (
    await http
      .get(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagens?${obj.page}`,
      )
      .then((res) => {
        state.mensagensLoad = false;

        return res;
      })
      .catch(() => {
        state.mensagensLoad = false;
      })
  ).data;
}

export async function mensagemListaOcultasAppV2({ state, commit }, obj) {
  state.mensagensOcultasLoad = true;
  return (
    await http
      .get(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagens-ocultas`,
      )
      .then((res) => {
        commit('SET_MENSAGENS_OCULTAS', res.data);
        state.mensagensOcultasLoad = false;
        return res;
      })
      .catch(() => {
        state.mensagensOcultasLoad = false;
      })
  ).data;
}

export async function mensagemSaveAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem-incluir`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}


/**
 * Devolutiva
 */
export async function devolutivaListaAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .get(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/devolutivas`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function devolutivaIncluirAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/devolutiva-incluir`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function devolutivaRemoverAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .delete(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/devolutiva-remover/${obj.mensagem.id}`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function devolutivaEnviarAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem/${obj.mensagem.id}/devolutiva-enviar`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Execução
 */
export async function execucaoIncluirAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/execucao-incluir`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function execucaoEnviarAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem/${obj.mensagem.id}/execucao-enviar`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function execucaoRemoverAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .delete(`/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem/${obj.mensagem.id}/remover-enviar`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Anexo
 */
export async function anexoIncluirAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/app/v1/pedidos/${obj.pedido.id}/anexo-incluir`, obj)
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function anexoRemoverAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .delete(`/distribuicao/app/v1/pedidos/${obj.pedido.id}/anexo-remover/${obj.mensagem.id}`, obj)
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function anexoEnviarAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem/${obj.mensagem.id}/anexo-enviar`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function anexoRenomearArquivoAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem/${obj.mensagem.id}/anexo-renomear-item/${obj.arquivo.id}`, obj)
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}


export async function anexoRemoverArquivoAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .delete(`/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem/${obj.mensagem.id}/anexo-remover-item/${obj.arquivo.id}`, obj)
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
