/* eslint-disable */
/* eslint-disable no-unused-vars */
import Loading from 'quasar/src/plugins/Loading.js';;
import axios from 'axios';
import http from "../http/http";
import { error, success } from "../../../services/alert-message";

const urlApi = "entidades";

/**
 * Buscando Entidades
 * @param {*} param0
 * @param {*} obj
 */
export async function entidadeList({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`lgpd/api/v1/${urlApi}?${obj.page}&${obj.include}`, obj.filtro)
      .then(res => {
        Loading.hide();
        commit('SET_ENTIDADES', res.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function entidadeFornecedores({ commit }, obj) {
  Loading.show();
  return (
    await http
      .get(`lgpd/api/v1/${urlApi}/${obj.entidadeId}/fornecedores`)
      .then(res => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function entidadeNotificar({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`lgpd/api/v1/entidades/notificacoes`, obj)
      .then(res => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data
}