/*
export function someMutation (state) {
}
*/


export function SET_AUDITORIAS(state, list) {
  state.auditorias = list;
}

export function SET_AUDITORIA(state, obj) {
  if (obj) {
    state.auditoria = obj;
  } else {
    state.auditoria = {
      attributes: {},
    };
  }
}

export function SET_AUDITORIA_PERGUNTA(state, obj) {
  if (obj) {
    state.auditoriaPergunta = JSON.parse(JSON.stringify(obj));
  } else {
    state.auditoriaPergunta = {
      attributes: {
        status: '',
        auditorDescricao: '',
        auditadoCausa: '',
        auditadoCorrecao: '',
        auditadoAcaoCorretiva: '',
        auditadoEvidenciaImplementacaoAcaoCorretiva: '',
      },
    };
  }
}
