/*
export function someMutation (state) {
}
*/
export function SET_RESPOSTA_LIST(state, list) {
  state.respostas = list;
}


export function SET_RESPOSTA(state, obj) {
  if (obj) {
    state.resposta = obj;
  } else {
    state.resposta = {
      attributes: {
        descricao: '',
        modeloRespostaId: '',
        reconhecimento: '',
        probabilidade: '',
      },
      relationships: {
      },
    };
  }
}
