/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'central/api/v1/servicos';

/**
* Buscando todos livros
* @param {*} param0
*/
export async function livroList({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/${obj.servicoId}/livros?${obj.filtro}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
