
// import date from 'quasar/src/utils/date.js';;

export default function () {
  return {
    contaPagarFiltros: {
      periodo: {
        // from: date.formatDate(date.subtractFromDate(Date.now(), { month: 1 }), 'YYYY/MM/DD'),
        // to: date.formatDate(Date.now(), 'YYYY/MM/DD'),
        from: null,
        to: null,
      },
    },
    contasPagar: {
      data: [],
    },
    contaPagar: {
      attributes: {},
    },
  };
}
