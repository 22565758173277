export default function () {
  return {
    processoModelos: {
      data: [],
    },
    processos: {
      data: [],
    },
    processo: {
      attributes: {
        titulo: '',
      },
      relationships: {
        regulamentos: [],
        perguntas: [],
      },
    },
  };
}
