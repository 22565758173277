/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import axios from 'axios';
import http from '../http/http';
import { success, error } from '../../../services/alert-message';


export async function pedidoFaseListaAppV2({ state, commit }, obj) {
  Loading.show();
  return (
    await http.get(`/distribuicao/app/v1/pedidos/${obj.pedido.id}/fases`)
      .then((res) => {
        Loading.hide();
        commit('SET_FASES', res.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
