/*
export function someMutation (state) {
}
*/


export function SET_ESTADOS(state, list) {
  state.estados = list;
}

export function SET_ESTADO(state, obj) {
  state.estado = obj;
}
