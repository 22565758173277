export default function () {
  return {
    // Notificações do usuário
    notifications: {
      data: [],
      meta: {},
    },
    // Relatórios do usuário
    relatorios: [],
  };
}
