

export function SET_PEDIDO_TIPOS(state, lista) {
  state.pedidoTipos = lista;
}


/**
 * Setando Pedido Tipo
 * @param {*} state
 * @param {*} obj
 */
export function SET_PEDIDO_TIPO(state, obj) {
  if (obj) {
    state.pedidoTipo = obj;
  } else {
    state.pedidoTipo = {
      attributes: {},
    };
  }
}
