/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1';


/**
* Salvando
* @param {*} param0
*/
export async function regulamentoSave({ state, commit }, obj) {
  Loading.show({ message: 'Salvando ...' });
  const regulamento = JSON.parse(JSON.stringify(state.regulamento));
  if (regulamento.attributes.dataInicio) {
    regulamento.attributes.dataInicio = regulamento.attributes.dataInicio.formatDate('YYYY-MM-DD', 'DD/MM/YYYY');
  }
  if (regulamento.attributes.dataPrevistaTermino) {
    regulamento.attributes.dataPrevistaTermino = regulamento.attributes.dataPrevistaTermino.formatDate('YYYY-MM-DD', 'DD/MM/YYYY');
  }
  if (regulamento.attributes.dataTermino) {
    regulamento.attributes.dataTermino = regulamento.attributes.dataTermino.formatDate('YYYY-MM-DD', 'DD/MM/YYYY');
  }
  return (
    await http.put(`${urlApi}/processos/${obj.processo.id}/regulamentos/${state.regulamento.id}`, regulamento)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}
