/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'central/api/acesso-restrito/protocolos';

export async function dashboard({ commit }) {
  return (await http.get(`${urlApi}/dashboard`)
    .then((res) => {
      Loading.hide();
      commit('SET_DASHBOARD', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando todos protocolos
* @param {*} param0
*/
export async function protocoloList({ commit }, obj) {
  return (await http.get(`${urlApi}?${obj.page}&${obj.filter}&include=integracaoSirc,integracaoSircLegado,integracaoCrc,integracaoCrcLegado,integracaoSinter,integracaoSinterLegado,ato.tipoServico,primeiroAto.tipoServico,primeiroAto.tipoLivro`)
    .then((res) => {
      Loading.hide();
      // commit('SET_PROTOCOLOS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


/**
* Buscando protocolo
* @param {*} param0
*/
export async function protocoloGet({ commit }, id) {
  Loading.show();
  return (await http.get(`${urlApi}/${id}?include=integracoesSirc,integracoesSinter,integracoesCrc,ato.partes,ato.cartorio,ato.tipoServico,ato.tipoLivro,historico.ato.cartorio,historico.ato.tipoServico,historico.ato.tipoLivro,historico.ato.partes,cartorio`)
    .then((res) => {
      Loading.hide();
      commit('SET_PROTOCOLO', res.data.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


/**
* Imprimir protocolos
* @param {*} param0
*/
export async function protocoloImprimir({ state, commit }, obj) {
  Loading.show({ message: 'Gerando relatório, esse processo poderá levar vários minutos' });

  return (await http.post(`${urlApi}/imprimir?${obj.filtro}`, obj.relatorio, {
    responseType: 'arraybuffer',
  })
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
 * Arquivando falhas
 * @param {*} param0
 * @param {*} obj
 */
export async function protocoloArquivarFalhas({ state, commit }, obj) {
  Loading.show({ message: 'Arquivando falhas, esse processo poderá levar vários minutos' });

  if (state.filtro.protocoloSelecionados.length) {
    obj.protocolosId = state.filtro.protocoloSelecionados;
  } else {
    obj.protocolosId = [];
  }

  return (await http.post(`${urlApi}/arquivarFalhas`, obj)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Exportando protocolos em XML
* @param {*} param0
*/
export async function protocoloExportarXml({ state, commit }, obj) {
  Loading.show({ message: 'Gerando XML, esse processo poderá levar vários minutos' });

  return (await http.post(`${urlApi}/exportarXml?${obj.filtro}`, obj.objXml)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Exportando protocolos em JSON
* @param {*} param0
*/
export async function protocoloExportarJson({ state, commit }, obj) {
  Loading.show({ message: 'Gerando JSON, esse processo poderá levar vários minutos' });

  return (await http.post(`${urlApi}/exportarJson?${obj.filtro}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Abrindo arquivo do Ato
* @param {*} param0
*/
export async function protocoloAtoArquivo({ commit }, obj) {
  // Loading.show();
  return (await http.get(`${urlApi}/${obj.protocolo.id}/arquivo`)
    .then((res) => {
      Loading.hide();
      return res.data;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
