/* eslint-disable */

/**
 * Formatando Moeda
 */
String.prototype.money = function (type = 'pt-br', minimumFractionDigits = 2, maximumFractionDigits = 2) {

    if (!this) {
      return '';
    }

  if (type === 'pt-br') {
    return Number(this).toLocaleString(type, { minimumFractionDigits, maximumFractionDigits });
  }

  if (type === 'en') {
    let n = this.replace(/[.]/g, '');
    n = n.replace(',', '.');
    return Number(n);
  }

    
};
Number.prototype.money = function (type = 'pt-br', minimumFractionDigits = 2, maximumFractionDigits = 2) {

    if (!this) {
        return '';
    }

  if (type === 'pt-br') {
    return this.toLocaleString(type, { minimumFractionDigits, maximumFractionDigits });
  }

  if (type === 'en') {
    let n = this.replace(/[.]/g, '');
    n = n.replace(',', '.');
    return Number(n);
  }

};

String.prototype.isNumeric = function () {

    if (!this) {
      return '';
    }
    const er = /^[0-9]+$/;
    return (er.test(this));
};
  