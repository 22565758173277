/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'v1/cidades';

/**
*
* @param {*} param0
*/
export async function cidadeList({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}?${obj.filtros}&include=estado,cartorios`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function cidadeDominioList({ commit }, obj) {
  Loading.show();
  return (await http.get(`v1/dominios/search?${obj.filtros}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function cepSearch({ commit }, cep) {
  Loading.show({ message: 'Buscando endereço...' });
  return (await http.get(`v1/cep?filter[cep]=${cep}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
