/*
export function someMutation (state) {
}
*/

export function SET_PERSON(state, obj) {
  if (obj.cpf.replace(/[^0-9]/g, '').length === 14) {
    obj.type = 'juridica';
  } else {
    obj.type = 'fisica';
  }
  state.person = obj;
}
