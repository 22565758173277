/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';

const urlApi = 'central/api/v1/regularizacoes';

/**
* Enviando arquivo de Regularização
* @param {*} param0
*/
export async function listRegularizacoes({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Enviando arquivo de Regularização
* @param {*} param0
*/
export async function uploadArquivo({ commit }, obj) {
  Loading.show({ message: 'Enviando arquivo ...' });
  return (await http.post(`${urlApi}/upload`, obj)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Reprocessando envio
* @param {*} param0
*/
export async function reprocessarEnvio({ commit }, envio) {
  Loading.show({ message: 'Enviando arquivo ...' });
  return (await http.post(`${urlApi}/${envio.uuid}/reprocessar`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Listando cartorios
* @param {*} param0
*/
export async function cartoriosLista({ commit }, filtros) {
  Loading.show();
  return (await http.get(`${urlApi}/cartorios?${filtros}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Listando cartorios
* @param {*} param0
*/
export async function atosCartorio({ commit }, cartorioCns) {
  Loading.show();
  return (await http.get(`${urlApi}/cartorios/${cartorioCns}/atos`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function getCartorio({ commit }, cns) {
  Loading.show();
  return (await http.get(`api/v1/cartorios?filter[cns]=${cns}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
