/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Vue from 'vue';
import Loading from 'quasar/src/plugins/Loading.js';;
import { success, error } from '../../../services/alert-message';

export async function arquivoDownloadPublico({ state, commit }, uuid) {
  Loading.show();
  return (
    await Vue.prototype.$axios.get(`arquivo/api/publico/${uuid}`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

export async function arquivoDownload({ state, commit }, uuid) {
  Loading.show();
  return (
    await Vue.prototype.$axios.get(`arquivo/api/v1/${uuid}`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}
