// biblioteca
import bibliotecaCategoria from './biblioteca-categoria';
import bibliotecaArquivo from './biblioteca-arquivo';
import bibliotecaAutor from './biblioteca-autor';
import bibliotecaEntidade from './biblioteca-entidade';
import bibliotecaSearch from './biblioteca-search';

export default {
  bibliotecaCategoria,
  bibliotecaArquivo,
  bibliotecaAutor,
  bibliotecaEntidade,
  bibliotecaSearch,
};
