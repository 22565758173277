/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1/planos-acao';

/**
* Buscando todos planos de ação
* @param {*} param0
*/
export async function planoAcaoList({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}?${obj.page}&${obj.filter}`)
    .then((res) => {
      Loading.hide();
      commit('SET_PLANO_ACAO_LIST', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando plano de ação
* @param {*} param0
*/
export async function planoAcaoGet({ commit }, id) {
  Loading.show();
  return (await http.get(`${urlApi}/${id}`)
    .then((res) => {
      Loading.hide();
      commit('SET_PLANO_ACAO', res.data.data.planoAcao);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando plano de ação
* @param {*} param0
*/
export async function planoAcaoContextoGet({ commit }, obj) {
  Loading.show();
  return (await http.post(`${urlApi}/contexto-show`, obj)
    .then((res) => {
      Loading.hide();
      commit('SET_PLANO_ACAO', res.data.data.planoAcao);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Salvando plano de ação
* @param {*} param0
*/
export async function planoAcaoSave({ state, commit }) {
  Loading.show({ message: 'Salvando plano de ação...' });
  const planoAcao = JSON.parse(JSON.stringify(state.planoAcao));
  if (state.planoAcao.id) {
    return (
      await http.put(`${urlApi}/${state.planoAcao.id}`, planoAcao)
        .then((res) => {
          Loading.hide();
          success(res.data.data.message);
          // commit('SET_ATOS', res.data);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }

  return (
    await http.post(`${urlApi}`, planoAcao)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
* Buscando plano de ação
* @param {*} param0
*/
export async function planoAcaoDelete({ state, commit }, id) {
  Loading.show({ message: 'Removendo plano de ação...' });
  return (await http.delete(`${urlApi}/${state.planoAcao.id}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
