/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1/planos-acao';

/**
*
* @param {*} param0
*/
export async function planoAcaoCartaoItemSave({ state, commit }, obj) {
  const planoAcaoCartaoItem = JSON.parse(JSON.stringify(state.planoAcaoCartaoItem));
  // Loading.show();
  if (state.planoAcaoCartaoItem.id) {
    return (
      await http.put(`${urlApi}/${obj.planoAcao.id}/cartoes/${obj.planoAcaoCartao.id}/itens/${planoAcaoCartaoItem.id}`, planoAcaoCartaoItem)
        .then((res) => {
          // Loading.hide();
          // success(res.data.data.message);
          // commit('SET_ATOS', res.data);
          return res;
        })
        .catch((err) => {
          // Loading.hide();
          return err;
        })
    ).data;
  }

  Loading.show({ message: 'Incluindo tarefa' });
  return (
    await http.post(`${urlApi}/${obj.planoAcao.id}/cartoes/${obj.planoAcaoCartao.id}/itens`, planoAcaoCartaoItem)
      .then((res) => {
        Loading.hide();
        // success(res.data.data.message);
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}


export async function planoAcaoCartaoItemDelete({ state, commit }, obj) {
  return (
    await http.delete(`${urlApi}/cartoes/${obj.planoAcaoCartao.id}/itens/${obj.planoAcaoCartaoItem.id}`)
      .then((res) => {
        // Loading.hide();
        success(res.data.data.message);
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        // Loading.hide();
        return err;
      })
  ).data;
}

/**
 * Removendo BaseConhecimento
 * @param {*} param0
 * @param {*} obj
 * @returns
 */
export async function planoAcaoCartaoItemRemoverBaseConhecimentoItem({ state, commit }, obj) {
  Loading.show({ message: 'Removendo Evidência' });
  return (
    await http.delete(`${urlApi}/${obj.planoAcao.id}/cartoes/${obj.planoAcaoCartao.id}/itens/${obj.planoAcaoCartaoItem.id}/remover-base-conhecimento-item`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
 * Incluindo Mensagem
 * @param {*} param0
 * @param {*} obj
 * @returns
 */
export async function planoAcaoCartaoItemIncluirMensagem({ state, commit }, obj) {
  return (
    await http.post(`${urlApi}/${obj.planoAcao.id}/cartoes/${obj.planoAcaoCartao.id}/itens/${obj.planoAcaoCartaoItem.id}/incluir-mensagem`, obj.mensagem)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
 * Incluindo Mensagem
 * @param {*} param0
 * @param {*} obj
 * @returns
 */
export async function planoAcaoCartaoItemRemoverMensagem({ state, commit }, obj) {
  Loading.show({
    message: 'Removendo mensagem ...',
  });
  return (
    await http.delete(`${urlApi}/${obj.planoAcao.id}/cartoes/${obj.planoAcaoCartao.id}/itens/${obj.planoAcaoCartaoItem.id}/remover-mensagem/${obj.mensagem.id}`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
 *
 * @param {*} param0
 * @param {*} obj
 * @returns
 */
export async function planoAcaoCartaoItemLidoMensagem({ state, commit }, obj) {
  Loading.show({
    message: '',
  });
  return (
    await http.post(`${urlApi}/${obj.planoAcao.id}/cartoes/${obj.planoAcaoCartao.id}/itens/${obj.planoAcaoCartaoItem.id}/lido-mensagem/${obj.mensagem.id}`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}
