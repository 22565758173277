/*
export function someGetter (state) {
}
*/


export function getFiltro(state) {
  let filtro = '';

  if (state.filtro.termoConsultado) {
    filtro += `filter[cpfCnpj]=${state.filtro.termoConsultado}`;
  }

  if (state.filtro.natureza) {
    filtro += `&filter[natureza]=${state.filtro.natureza}`;
  }

  if (state.filtro.servico) {
    filtro += `&filter[codServico]=${state.filtro.servico.id}`;
  }

  if (state.filtro.livrosIds) {
    filtro += `&filter[codLivro]=${state.filtro.livrosIds}`;
  }

  if (state.filtro.livro) {
    filtro += `&filter[livro]=${state.filtro.livro}`;
  }

  if (state.filtro.folha) {
    filtro += `&filter[folha]=${state.filtro.folha}`;
  }

  if (state.filtro.cartorio) {
    filtro += `&filter[cns]=${state.filtro.cartorio.attributes.cns}`;
  }

  if (state.filtro.atoId) {
    filtro += `&filter[codAto]=${state.filtro.atoId}`;
  }

  if (state.filtro.dataInicio && state.filtro.dataFim) {
    filtro += `&filter[periodo]=${state.filtro.dataInicio.formatDate('YYYY-MM-DD', 'DD/MM/YYYY')} 00:00:00, ${state.filtro.dataFim.formatDate('YYYY-MM-DD', 'DD/MM/YYYY')} 23:59:59`;
  }

  if (state.filtro.regularizacaoFundiaria) {
    filtro += '&filter[regularizacaoFundiaria]=true';
  }

  if (state.filtro.cidadeId) {
    filtro += `&filter[cidadeId]=${state.filtro.cidadeId}`;
  }

  return filtro;
}
