export default function () {
  return {
    loadingDashboard: true,
    dashboard: {
      attributes: {
        saldo: 0,
        boletos: 0,
        valor_consultas: 0,
        valor_visualizacoes: 0,
      },
    },
  };
}
