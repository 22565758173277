/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1';

/**
*
* @param {*} param0
*/
export async function mapaRiscoGet({ commit }, obj) {
  Loading.show({ message: 'Buscando análise de risco...' });
  return (await http.get(`${urlApi}/processos/${obj.processo.id}/mapa-risco`)
    .then((res) => {
      Loading.hide();
      commit('SET_MAPA_RISCO', res.data.data.mapaRisco);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function mapaRiscoPergunta({ commit }, obj) {
  Loading.show({ message: 'Buscando respostas...' });
  return (await http.post(`${urlApi}/perguntas`, obj)
    .then((res) => {
      Loading.hide();
      // commit('SET_MAPA_RISCO', res.data.data.mapaRisco);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
 * Gerando plano de Ação
 */
// export async function mapaRiscoPlanoAcao({ commit }, obj) {
//   Loading.show({ message: 'Gerando plano de ação...' });
//   return (await http.post(`${urlApi}/processos/${obj.processo.id}/regulamentos/
// ${obj.regulamento.id}/contextos/${obj.contexto.id}/plano-acao`)
//     .then((res) => {
//       Loading.hide();
//       // commit('SET_MAPA_RISCO', res.data.data.mapaRisco);
//       return res;
//     })
//     .catch((err) => {
//       Loading.hide();
//       return err;
//     })
//   ).data;
// }
