/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'alienacao/contratos';

/**
* Buscando lotes
* @param {*} param0
*/
export async function listLotes({ commit }) {
  Loading.show();
  return (await http.get(`/distribuicao/api/v1/${urlApi}`)
    .then((res) => {
      commit('SET_LOTE_LIST', res.data);
      Loading.hide();
      return res;
    })
    .catch(() => {
      Loading.hide();
    })
  ).data;
}

/**
 * Buscando arquivos
 * @param {*} param0
 * @param {*} obj
 */
// export async function listContrato({ commit }) {
//   Loading.show();
//   return (await http.get(`${urlApi}`)
//     .then((res) => {
//       Loading.hide();
//       return res;
//     })
//     .catch((err) => {
//       Loading.hide();
//       error(`${err.error.message}`);
//       return err;
//     })
//   ).data;
// }


export async function listLoteContratos({ commit }, lote) {
  Loading.show();
  return (await http.get(`/distribuicao/api/v1/${urlApi}/${lote}`)
    .then((res) => {
      commit('SET_CONTRATO_LIST', res.data);
      Loading.hide();
      return res;
    })
    .catch(() => {
      Loading.hide();
    })
  ).data;
}

export async function listContratos({ commit }, obj) {
  Loading.show();
  return (await http.get(`/distribuicao/api/v1/alienacao/contratos?page[number]=${obj.pagination.page}&page[size]=${obj.pagination.rowsPerPage}`)
    .then((res) => {
      commit('SET_CONTRATO_LIST', res.data);
      Loading.hide();
      return res;
    })
    .catch(() => {
      Loading.hide();
    })
  ).data;
}

export async function getContrato({ commit }, contrato) {
  Loading.show();
  return (await http.get(`/distribuicao/api/v1/alienacao/contratos/${contrato}?include=devedores,enderecos,situacao,pedidos.cartorio.cliente,pedidos.cliente,pedidos.historicos`)
    .then((res) => {
      commit('SET_CONTRATO', res.data);
      Loading.hide();
      return res;
    })
    .catch(() => {
      Loading.hide();
    })
  ).data;
}

/**
 * Buscando Andamentos / Linha do tempo
 * @param {*} param0
 * @param {*} obj
 */
export async function getAndamentos({ commit }, obj) {
  Loading.show();
  return (await http.get(`/distribuicao/api/v1/alienacao/contratos/${obj.contrato_id}/andamentos`)
    .then((res) => {
      commit('CLOSE_SPINNER', res.data);
      Loading.hide();
      return res;
    }).catch((err) => {
      commit('CLOSE_SPINNER', err.data);
      Loading.hide();
    })
  ).data;
}

export async function getPedidos({ commit }, obj) {
  Loading.show();
  return (await http.get(`/distribuicao/api/v1/alienacao/contratos/${obj.contrato_id}/pedidos`)
    .then((res) => {
      commit('SET_CONTRATO_PEDIDOS', res.data);
      Loading.hide();
      return res;
    }).catch((err) => {
      commit('CLOSE_SPINNER', err.data);
      Loading.hide();
    })
  ).data;
}

export async function avancarFaseContrato({ commit }, contrato) {
  Loading.show();
  return (await http.post(`/distribuicao/api/v1/alienacao/contratos/${contrato.IdCartorioPedidoAlienacao}/avancar-fase`)
    .then((res) => {
      commit('CLOSE_SPINNER', res.data);
      success(res.data.data.message);
      Loading.hide();
      return res;
    })
    .catch((err) => {
      commit('CLOSE_SPINNER', err.data);
      error(err.data.data.message);
      Loading.hide();
    })
  ).data;
}

export async function estornarFaseContrato({ commit }, contrato) {
  Loading.show();
  return (await http.post(`/distribuicao/api/v1/alienacao/contratos/${contrato.IdCartorioPedidoAlienacao}/estornar-fase`)
    .then((res) => {
      commit('CLOSE_SPINNER', res.data);
      success(res.data.data.message);
      Loading.hide();
      return res;
    }).catch((err) => {
      commit('CLOSE_SPINNER', err.data);
      Loading.hide();
    })
  ).data;
}

export async function avancarFaseLote({ commit }, lote) {
  Loading.show();
  return (await http.post(`/distribuicao/api/v1/contratos/${lote.Lote}/avancar-fase`)
    .then((res) => {
      commit('CLOSE_SPINNER', res.data);
      success(res.data.data.message);
      Loading.hide();
      return res;
    }).catch((err) => {
      commit('CLOSE_SPINNER', err.data);
      Loading.hide();
    })
  ).data;
}

export async function estornarFaseLote({ commit }, lote) {
  Loading.show();
  return (await http.post(`/distribuicao/api/v1/contratos/${lote.Lote}/estonar-fase`)
    .then((res) => {
      commit('CLOSE_SPINNER', res.data);
      success(res.data.data.message);
      Loading.hide();
      return res;
    }).catch((err) => {
      commit('CLOSE_SPINNER', err.data);
      Loading.hide();
    })
  ).data;
}
