/*
export function someGetter (state) {
}
*/

export function pedidoFaseAtual(state) {
  const fase = state.fases.data.find(f => f.fase_atual);

  return fase;
}
