/*
export function someMutation (state) {
}
*/
export function SET_INFORMACAO_LIST(state, list) {
  state.informacoes = list;
}


export function SET_INFORMACAO(state, obj) {
  state.informacao = obj;
}
