export default function () {
  return {
    compras: [],
    compraDeCredito: {
      cliente_id: null,
      formaPagamento: 'boleto',
      quantidade: 0,
    },
    extrato: [],
    dashboard: {},
  };
}
