export default function () {
  return {
    informacoes: {
      data: [],
    },
    informacao: {
      attributes: {
        titulo: '',
      },
    },
  };
}
