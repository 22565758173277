/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'users';

/**
 * Buscando Pessoas
 * @param {*} param0
 * @param {*} obj
 */
export async function peopleList({ commit }, obj) {
  Loading.show();
  return (
    await http
      .get(urlApi)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

/**
 * Buscando cadastro da pessoa
 * @param {*} param0
 * @param {*} id
 */
export async function peopleGet({ commit }, id) {
  Loading.show();
  return (
    await http.get(`${urlApi}/${id}?include=scopes`).then((res) => {
      Loading.hide();
      commit('SET_PERSON', res.data.data.user);
      return res;
    })
  ).data;
}

/**
 * Salvando pessoa
 * @param {*} param0
 * @param {*} obj
 */
export async function peopleSave({ commit }, obj) {
  Loading.show();
  const person = JSON.parse(JSON.stringify(obj));
  person.cpf = person.cpf.replace(/[^0-9]/g, '');
  delete person.scopes;
  // Update
  if (obj.id) {
    return (
      await http
        .put(`${urlApi}/${person.id}`, person)
        .then((res) => {
          Loading.hide();
          success(res.data.data.message);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }
  // Create
  return (
    await http
      .post(urlApi, person)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        this.$router.push({
          name: 'admin-people-person',
          params: { person_id: res.data.data.user.id },
        });
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
 * Removendo pessoa
 * @param {*} param0
 * @param {*} obj
 */
export async function peopleDelete({ commit }, obj) {
  return (
    await http.delete(`${urlApi}/${obj.id}`).then((res) => {
      success(res.data.data.message);
      this.$router.push({ name: 'admin-people-list' }).catch(() => {});
      return res;
    })
  ).data;
}

/**
 *
 * @param {*} param0
 * @param {*} obj
 */
export async function attachScopePeople({ commit }, obj) {
  return (
    await http.post(`${urlApi}/${obj.person.id}/attach-scope`, { scope: obj.scope }).then((res) => {
      success(res.data.data.message);
      return res;
    })
  ).data;
}

export async function detachScopePeople({ commit }, obj) {
  return (
    await http.post(`${urlApi}/${obj.person.id}/detach-scope`, { scope: obj.scope }).then((res) => {
      success(res.data.data.message);
      return res;
    })
  ).data;
}
