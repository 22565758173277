export default function () {
  return {
    auditorias: {
      data: [],
    },
    auditoria: {
      attributes: {},
    },
    auditoriaPergunta: {
      attributes: {},
    },
  };
}
