/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1';

/**
*
* @param {*} param0
*/
export async function envolvidoList({ commit }, obj) {
  return (await http.get(`${urlApi}/envolvidos?${obj.page}&${obj.filter}`)
    .then((res) => {
      commit('SET_ENVOLVIDO_LIST', res.data);
      return res;
    })
    .catch((err) => {
      return err;
    })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function envolvidoSave({ state, commit }) {
  Loading.show();
  if (state.envolvido.id) {
    return (await http.put(`${urlApi}/envolvidos/${state.envolvido.id}`, state.envolvido)
      .then((res) => {
        Loading.hide();
        // commit('SET_ENVOLVIDO', res.data.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
    ).data;
  }
  return (await http.post(`${urlApi}/envolvidos`, state.envolvido)
    .then((res) => {
      Loading.hide();
      // commit('SET_ENVOLVIDO', res.data.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function envolvidoDelete({ state, commit }, obj) {
  Loading.show({ message: 'Removendo envolvido' });
  return (await http.delete(`${urlApi}/envolvidos/${obj.id}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function envolvidoArquivoSave({ state, commit }, arquivo) {
  Loading.show({ message: 'Enviando arquivo' });
  return (await http.post(`${urlApi}/envolvidos/${state.envolvido.id}/arquivo`, arquivo)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function envolvidoArquivoDelete({ state, commit }, arquivo) {
  Loading.show({ message: 'Removendo arquivo' });
  return (await http.delete(`${urlApi}/envolvidos/${state.envolvido.id}/arquivo/${arquivo.id}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
