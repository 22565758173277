/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';import date from 'quasar/src/utils/date.js';;

import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'central/api/acesso-restrito/correicao-portarias';

/**
*
* @param {*} param0
*/
export async function correicaoPortariaList({ commit }, obj) {
  return (await http.get(`${urlApi}?${obj.page}&${obj.filter}`)
    .then((res) => {
      Loading.hide();
      // commit('SET_CORREICAO_PORTARIA_LIST', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function correicaoPortariaGet({ state, commit }, portariaId) {
  Loading.show();
  return (await http.get(`${urlApi}/${portariaId}?include=pedidos.ato.tipoLivro,pedidos.ato.tipoServico,pedidos.remetente,pedidos.destinatario.cartorio,pedidos.andamentoAtual.fase`)
    .then((res) => {
      Loading.hide();
      commit('SET_CORREICAO_PORTARIA', res.data.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
 *
 * @param {*} param0
 * @param {*} obj
 */
export async function correicaoPortariaSave({ state, commit }) {
  Loading.show({ message: 'Salvando portaria' });
  const portaria = JSON.parse(JSON.stringify(state.portaria));
  let d = null;
  if (portaria.attributes.dataInicio) {
    d = date.extractDate(portaria.attributes.dataInicio, 'DD/MM/YYYY');
    portaria.attributes.dataInicio = date.formatDate(d, 'YYYY/MM/DD');
  }
  if (portaria.attributes.dataFim) {
    d = date.extractDate(portaria.attributes.dataFim, 'DD/MM/YYYY');
    portaria.attributes.dataFim = date.formatDate(d, 'YYYY/MM/DD');
  }
  if (portaria.id) {
    return (
      await http
        .put(`${urlApi}/${portaria.id}`, portaria)
        .then((res) => {
          Loading.hide();
          success(res.data.data.message);
          return res;
        })
        .catch((err) => {
          error(`${err.error.message}`);
          Loading.hide();
          return err;
        })
    ).data;
  }
  return (
    await http
      .post(urlApi, portaria)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        commit('SET_CORREICAO_PORTARIA', res.data.data.portaria);
        return res;
      })
      .catch((err) => {
        error(`${err.error.message}`);
        Loading.hide();
        return err;
      })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function correicaoPortariaExportarJson({ state, commit }, obj) {
  Loading.show({ message: 'Montando JSON ...' });
  return (await http.get(`${urlApi}/${state.portaria.id}/exportar-json`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function correicaoPortariaImprimir({ state, commit }, obj) {
  Loading.show({ message: 'Gerando relatório ...' });
  return (await http.post(`${urlApi}/${state.portaria.id}/imprimir`, obj, {
    responseType: 'arraybuffer',
  })
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function correicaoPortariaConferirAmostragem({ state, commit }, obj) {
  Loading.show({ message: 'Processando ...' });
  return (await http.post(`${urlApi}/${state.portaria.id}/conferir-amostragem`, obj)
    .then((res) => {
      Loading.hide();
      success(res.data.data.message);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function correicaoPortariaUpload({ state, commit }, obj) {
  Loading.show({ message: 'Enviando arquivo...' });
  return (await http.post(`${urlApi}/${state.portaria.id}/upload-arquivo`, obj.arquivo)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function correicaoPortariaArquivoRemover({ state, commit }, arquivoId) {
  Loading.show({ message: 'Removendo arquivo...' });
  return (await http.delete(`${urlApi}/${state.portaria.id}/arquivo/${arquivoId}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
