export default function () {
  return {
    planosAcao: {
      data: [],
    },
    planoAcao: {
      attributes: {
        titulo: '',
      },
      relationships: {
        cartoes: [],
      },
    },
    etapas: [
      {
        value: 'fazer', label: 'A fazer', color: 'grey-6', icon: 'mood_bad',
      },
      {
        value: 'andamento', label: 'Em andamento', color: 'teal-4', icon: 'sentiment_satisfied',
      },
      {
        value: 'analise', label: 'Em análise', color: 'orange-3', icon: 'sentiment_satisfied_alt',
      },
      {
        value: 'concluido', label: 'Concluído', color: 'green-4', icon: 'mood',
      },
    ],
  };
}
