// Consulta
import ceiConsultaPesquisa from './central/consulta/pesquisa';
import ceiConsultaAto from './central/consulta/ato';
import ceiConsultaCartorio from './central/consulta/cartorio';
import ceiConsultaLivro from './central/consulta/livro';
import ceiConsultaServico from './central/consulta/servico';
import ceiConsultaHistorico from './central/consulta/historico';
import ceiConsultaParte from './central/consulta/parte';
import ceiConsultaImagem from './central/consulta/imagem';
import ceiConsultaSelo from './central/consulta/selo';
import ceiConsultaConsulta from './central/consulta/consulta';
import ceiConsultaGif from './central/consulta/gif';

import ceiFinanceiroDashboard from './central/financeiro/dashboard';
import ceiFinanceiroCompra from './central/financeiro/compra';
import ceiFinanceiroPagamento from './central/financeiro/pagamento';
import ceiFinanceiroCredito from './central/financeiro/credito';
import ceiFinanceiroContaCorrente from './central/financeiro/contaCorrente';
import ceiFinanceiroContaPagar from './central/financeiro/contaPagar';
import ceiFinanceiroComprovante from './central/financeiro/comprovante';

// Acesso para Serventia e Órgão Público
import ceiCentralAcessoRestritoProtocolo from './central/acesso-restrito/protocolo';
import ceiCentralAcessoRestritoAto from './central/acesso-restrito/ato';
import ceiCentralAcessoRestritoConsulta from './central/acesso-restrito/consulta';
import ceiCentralAcessoRestritoCorreicao from './central/acesso-restrito/correicao';
import ceiCentralAcessoRestritoCorreicaoPortaria from './central/acesso-restrito/correicao-portaria';
import ceiCentralAcessoRegularizacao from './central/regularizacao';

import ceiCalendarioEnvio from './central/calendario-envio';


import ceiAuthUser from './auth/user';
import ceiAuthCartorio from './auth/cartorio';


export default {
  ceiAuthUser,
  ceiAuthCartorio,

  ceiConsultaPesquisa,
  ceiConsultaAto,
  ceiConsultaCartorio,
  ceiConsultaLivro,
  ceiConsultaServico,
  ceiConsultaHistorico,
  ceiConsultaParte,
  ceiConsultaImagem,
  ceiConsultaSelo,
  ceiConsultaConsulta,
  ceiConsultaGif,

  ceiFinanceiroDashboard,
  ceiFinanceiroCompra,
  ceiFinanceiroPagamento,
  ceiFinanceiroCredito,
  ceiFinanceiroContaCorrente,
  ceiFinanceiroContaPagar,
  ceiFinanceiroComprovante,

  ceiCentralAcessoRegularizacao,
  ceiCentralAcessoRestritoProtocolo,
  ceiCentralAcessoRestritoAto,
  ceiCentralAcessoRestritoConsulta,
  ceiCentralAcessoRestritoCorreicao,
  ceiCentralAcessoRestritoCorreicaoPortaria,

  ceiCalendarioEnvio,

};
