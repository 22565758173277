/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

/**
 * Buscando autores
 * @param {*} param0
 * @param {*} obj
 */
export async function listCategoria({ commit }, obj) {
  return (await http.get('categories')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      error(`${err.error.message}`);
      return err;
    })
  ).data;
}
