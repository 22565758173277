/*
export function someMutation (state) {
}
*/

export function SET_MODULES(state, modules) {
  state.modulesAll = modules;
}

export function SET_MODULE(state, module) {
  if (module.meta) {
    module.meta = JSON.stringify(module.meta);
  }

  state.module = module;
}
