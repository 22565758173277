import auth from './auth';
import authEntidade from './entidade';
import authCartorio from './cartorio';
import authCidade from './cidade';
import authEstado from './estado';

export default {
  auth,
  authEntidade,
  authCartorio,
  authCidade,
  authEstado,
};
