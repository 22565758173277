/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'financeiro/api/v1';

/**
 * Listando Pagamentos
 */
export async function pagamentoList({ commit }) {
  Loading.show({ message: '' });
  return (await http.get(`${urlApi}/pagamentos`)
    .then((res) => {
      commit('SET_PAGAMENTOS', res.data);
      Loading.hide();
      return res;
    })
    .catch(() => {
      Loading.hide();
    })
  ).data;
}
