/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'api/v1/cartorios';

/**
* Buscando todos os Tipo de Pedido
* @param {*} param0
*/
export async function cartorioList({ commit }, obj) {
  // include=cidade.cidade,cidade.comarca,entidade.atribuicoes&
  return (await http.get(`${urlApi}?${obj.filter}`)
    .then((res) => {
      return res.data;
    })
  ).data;
}
