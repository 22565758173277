/* eslint-disable  */
const domainCurrent = window.location.origin;
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import Platform from 'quasar/src/plugins/Platform.js';;

let chaveSite = '';
let chaveSecreta = '';

// Verificando Dev ou Prod
if (domainCurrent.split(':')[1] !== '//localhost') {
  // Produção
  chaveSite = '6Leur-MUAAAAAFRn02giIYgfxtSsA_lVKsPlhcm9';
  chaveSecreta = '6Leur-MUAAAAAMCrbCEnwso01_bSbqLwuY0KfDgI';
} else {
  // Desenvolvimento
  chaveSite = '6LdWyOMUAAAAAAu_Wc11GP2p73tl7kkiAv0loJ5i';
  chaveSecreta = '6LdWyOMUAAAAAD5-UFdvuaonW_VsFCh_Mqdrw_vO';
}

if (!Platform.is.safari) {
  Vue.use(VueReCaptcha, {
    siteKey: chaveSite,
    loaderOptions: {
      useRecaptchaNet: true,
      autoHideBadge: true,
    },
  });
}
