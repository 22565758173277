/* eslint-disable */
import axios from 'axios';
import Loading from 'quasar/src/plugins/Loading.js';;
import campoNovoSigef from '../data/campo_novo_certificados_sigef.json';
import campoNovoLei from '../data/campo_novo_certificados_lei.json';
import campoNovoRegistrados from '../data/campo_novo_certificados_registrados.json';
import campoNovoPosse from '../data/campo_novo_posse.json';

import diamantinoSigef from '../data/diamantino_certificados_sigef.json';
import diamantinoLei from '../data/diamantino_certificados_lei.json';
import diamantinoRegistrados from '../data/diamantino_certificados_registrados.json';
import diamantinoPosse from '../data/diamantino_posse.json';

export async function listCampoNovoGeolocation({ commit }, obj) {
  const properties = [];
  campoNovoPosse.features.map(((feature) => {
    feature.properties.tipo = 'posse';
    feature.properties.visible = false;
    feature.properties.location = `${feature.geometry.coordinates[0][0][0][1]},${feature.geometry.coordinates[0][0][0][0]}`;
    properties.push(feature);
    return true;
  }));
  campoNovoRegistrados.features.map(((feature) => {
    feature.properties.tipo = 'registrado';
    feature.properties.visible = false;
    feature.properties.location = `${feature.geometry.coordinates[0][0][0][1]},${feature.geometry.coordinates[0][0][0][0]}`;
    properties.push(feature);
    return true;
  }));
  campoNovoLei.features.map(((feature) => {
    feature.properties.tipo = 'lei';
    feature.properties.visible = false;
    feature.properties.location = `${feature.geometry.coordinates[0][0][0][1]},${feature.geometry.coordinates[0][0][0][0]}`;
    properties.push(feature);
    return true;
  }));
  campoNovoSigef.features.map(((feature) => {
    feature.properties.tipo = 'sigef';
    feature.properties.visible = false;
    feature.properties.location = `${feature.geometry.coordinates[0][0][0][1]},${feature.geometry.coordinates[0][0][0][0]}`;
    properties.push(feature);
    return true;
  }));
  commit('SET_PROPERTIES', properties);
  Loading.hide();
}

export async function listDiamantinoGeolocation({ commit }, obj) {
  const properties = [];
  diamantinoPosse.features.map(((feature) => {
    feature.properties.tipo = 'posse';
    feature.properties.visible = false;
    feature.properties.location = `${feature.geometry.coordinates[0][0][0][1]},${feature.geometry.coordinates[0][0][0][0]}`;
    properties.push(feature);
    return true;
  }));
  diamantinoRegistrados.features.map(((feature) => {
    feature.properties.tipo = 'registrado';
    feature.properties.visible = false;
    feature.properties.location = `${feature.geometry.coordinates[0][0][0][1]},${feature.geometry.coordinates[0][0][0][0]}`;
    properties.push(feature);
    return true;
  }));
  diamantinoLei.features.map(((feature) => {
    feature.properties.tipo = 'lei';
    feature.properties.visible = false;
    feature.properties.location = `${feature.geometry.coordinates[0][0][0][1]},${feature.geometry.coordinates[0][0][0][0]}`;
    properties.push(feature);
    return true;
  }));
  diamantinoSigef.features.map(((feature) => {
    feature.properties.tipo = 'sigef';
    feature.properties.visible = false;
    feature.properties.location = `${feature.geometry.coordinates[0][0][0][1]},${feature.geometry.coordinates[0][0][0][0]}`;
    properties.push(feature);
    return true;
  }));
  commit('SET_PROPERTIES', properties);
  Loading.hide();
}
