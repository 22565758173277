/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

export async function feriadoList({ commit }, filtro) {
  Loading.show({ message: '' });
  const response = await http.get(`v1/calendario?${filtro}`);
  Loading.hide();
  return response.data;
}

export async function feriadoSave({ commit }, obj) {
  Loading.show({ message: '' });
  return (await http.post('v1/calendario', obj)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function feriadoDelete({ commit }, feriado) {
  Loading.show({ message: '' });
  return (await http.delete(`v1/calendario/${feriado.id}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
