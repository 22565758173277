// empresa
import empresaGlobal from './empresa-global';
import empresaPeople from './empresa-people';
import empresaScope from './empresa-scope';
import empresaModule from './empresa-module';

export default {
  empresaGlobal,
  empresaPeople,
  empresaScope,
  empresaModule,
};
