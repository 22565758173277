import sateAuth from '../../auth/auth/state';

/* eslint-disable */
import { distribuicaoComponenteCheckCondicoes } from '../../../services/toolsDistribuicaoComponente';

// Sabendo se usuário logado é Remetente Destinatario Distribuidor
export function atribuicaoDoUsuario(state) {
  if (state.pedido.remetente.id === sateAuth.userCurrent.id) {
    return 'remetente';
  }

  if (state.pedido.destinatario.id === sateAuth.userCurrent.id) {
    return 'destinatario';
  }

  if (state.pedido.distribuidor.id === sateAuth.userCurrent.id) {
    return 'distribuidor';
  }
  return '';
}

export function resposavelAtual(state) {
  if (state.pedido.andamento.responsavel_id) {
    switch (state.pedido.andamento.fase.ator) {
      case 'remetente':
        state.pedido.remetente.ator = 'remetente';
        return state.pedido.remetente;
      case 'destinatario':
        state.pedido.destinatario.ator = 'destinatario';
        return state.pedido.destinatario;
      case 'distribuidor':
        state.pedido.distribuidor.ator = 'distribuidor';
        return state.pedido.distribuidor;

      default:
        return null;
    }
  }
}

/**
 * Alterando situação de cada componente (Ocultar, Mostrar, Desabilitar, Habilitar)
 * Com base nos valores das condições de cada os componentes
 * @param {*} state
 */
export function componentDisabled(state) {
  distribuicaoComponenteCheckCondicoes(state.pedidoStepComponentes);
  return '';
}

/**
 * Alterando situação dos botões da etapa (Habilitado, Desabilitado)
 * Com base nos valores dos componentes
 * @param {*} state
 */
export function stepDisabledActions(state) {

  if (state.pedido.erros.length) {
    state.actionPositivo = false;
    state.actionExcecao = false;
    state.actionNegativo = true;
    return;
  }

  const condition = conditionString => new Function(`return ${conditionString}`)();

  let conditionStringPositivo = '';
  let conditionStringExcecao = '';
  let conditionStringNegativo = '';

  state.pedidoStepComponentes.map(componenteRequired => {
    let conditionsActionPositivo = [];
    let conditionsActionExcecao = [];
    let conditionsActionNegativo = [];

    if (componenteRequired.meta.hasRequired) {
      // Pegando todos as confições para Ação de Positivo
      const positivo = componenteRequired.meta.conditionRequiredList.filter(
        f => f.requiredForAction === 'positivo',
      );

      if (positivo) {
        conditionsActionPositivo = conditionsActionPositivo.concat(positivo);
      }

      // Pegando todos as confições para Ação de Exceção
      const excecao = componenteRequired.meta.conditionRequiredList.filter(
        f => f.requiredForAction === 'excecao',
      );

      if (excecao) {
        conditionsActionExcecao = conditionsActionExcecao.concat(excecao);
      }

      // Pegando todos as confições para Ação de Venativo
      const negativo = componenteRequired.meta.conditionRequiredList.filter(
        f => f.requiredForAction === 'negativo',
      );

      if (negativo) {
        conditionsActionNegativo = conditionsActionNegativo.concat(negativo);
      }
    }

    // Caso tenha condições para ação Positivo
    if (conditionsActionPositivo.length) {
      state.actionPositivo = false;

      // Montando string da condição
      conditionsActionPositivo.map((condition, i) => {
        conditionStringPositivo += `${conditionStringPositivo ? `&& ` : ''}`;

        conditionStringPositivo += `${['file', 'quiz', 'person'].indexOf(componenteRequired.type) > -1
          ? componenteRequired.value.length.toString()
          : componenteRequired.value
          } ${condition.conditionOperatorValue} ${condition.conditionComponentValue} `;
      });
    }

    // Caso tenha condições para ação Exceção
    if (conditionsActionExcecao.length) {
      state.actionExcecao = false;
      // Montando string da condição
      conditionsActionExcecao.map(condition => {
        conditionStringExcecao += `${conditionStringExcecao ? `&& ` : ''}`;

        conditionStringExcecao += `${['file', 'quiz', 'person'].indexOf(componenteRequired.type) > -1
          ? componenteRequired.value.length.toString()
          : componenteRequired.value
          } ${condition.conditionOperatorValue} ${condition.conditionComponentValue} `;
      });
    }

    // Caso tenha condições para ação Negativo
    if (conditionsActionNegativo.length) {
      state.actionNegativo = false;
      // Montando string da condição
      conditionsActionNegativo.map(condition => {
        conditionStringNegativo += `${conditionStringNegativo ? `&& ` : ''}`;

        conditionStringNegativo += `${['file', 'quiz', 'person'].indexOf(componenteRequired.type) > -1
          ? componenteRequired.value.length.toString()
          : componenteRequired.value
          } ${condition.conditionOperatorValue} ${condition.conditionComponentValue} `;
      });
    }
  });

  conditionStringPositivo ? state.actionPositivo = condition(conditionStringPositivo) : state.actionPositivo = true;
  conditionStringExcecao ? state.actionExcecao = condition(conditionStringExcecao) : state.actionExcecao = true;
  conditionStringNegativo ? state.actionNegativo = condition(conditionStringNegativo) : state.actionNegativo = true;

  return '';
}

/**
 * Montando filtro para listar Pedidos
 * @param {*} state
 */
export function getFiltros(state) {

  state.pedidoFiltros.ativo = false;

  let filterString = '';

  if (state.pedidoFiltros.lote.id) {
    filterString += `&filter[lote_id]=${state.pedidoFiltros.lote.id}`;
  }

  if (state.pedidoFiltros.status) {
    if (state.pedidoFiltros.status !== 'aberto') {
      state.pedidoFiltros.ativo = true;
    }
    filterString += `&filter[status]=${state.pedidoFiltros.status}`;
  } else {
    state.pedidoFiltros.ativo = true;
  }

  if (state.pedidoFiltros.ordemCriacao) {
    filterString += `&sort=-created_at`;
  }

  if (state.pedidoFiltros.criadosPorMim) {
    filterString += `&filter[remetente_id]=${sateAuth.userCurrent.id}`;
  }

  if (!state.pedidoFiltros.criadosPorMim) {
    if (state.pedidoFiltros.remetenteAtribuicao) {
      state.pedidoFiltros.ativo = true;
      filterString += `&filter[remetente_atribuicoes]=${state.pedidoFiltros.remetenteAtribuicao}`;
    }
  }

  if (state.pedidoFiltros.pedido.id) {
    state.pedidoFiltros.ativo = true;
    filterString += `&filter[pedido_tipo_id]=${state.pedidoFiltros.pedido.id}`;
  }

  if (state.pedidoFiltros.agrupamento) {
    if (state.pedidoFiltros.agrupamento.id) {
      state.pedidoFiltros.ativo = true;
      filterString += `&filter[agrupamento_id]=${state.pedidoFiltros.agrupamento.id}`;
    }
  }

  if (state.pedidoFiltros.categorias) {
    state.pedidoFiltros.ativo = true;
    filterString += `&filter[categorias]=${state.pedidoFiltros.categorias.join(',')}`;
  }

  if (state.dashboardFiltro.diasInicio || state.dashboardFiltro.diasFim) {
    state.pedidoFiltros.ativo = true;
    filterString += `&filter[dias_atraso]=${state.dashboardFiltro.diasInicio},${state.dashboardFiltro.diasFim}`;
  }

  if (state.pedidoFiltros.dataCriadoInicio && state.pedidoFiltros.dataCriadoFim) {
    filterString += `&filter[periodo]=${state.pedidoFiltros.dataCriadoInicio.formatDate('YYYY-MM-DD', 'DD/MM/YYYY')},${state.pedidoFiltros.dataCriadoFim.formatDate('YYYY-MM-DD', 'DD/MM/YYYY')}`;
  }

  if (state.pedidoFiltros.remetentecpfcnpj) {
    filterString += `&filter[remetentecpfcnpj]=${state.pedidoFiltros.remetentecpfcnpj}`;
  }

  filterString += `&filter[meus_pedidos]=${state.pedidoFiltros.meusPedidos}`;
  localStorage.setItem('filterDistribuicao', JSON.stringify(state.pedidoFiltros));

  return filterString;
}
