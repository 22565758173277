export default function () {
  return {
    entidades: {
      data: [],
      meta: {},
    },
    entidade: {
      attributes: {},
      relationships: {
        politicaPrivacidade: 0,
        mapaRisco: 0,
        relatorioImpacto: 0,
        adesaoStart: 0,
      },
    },
  };
}
