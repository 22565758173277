const routes = [
  {
    path: '/publico',
    component: () => import('layouts/clean.vue'),
    children: [
      {
        path: 'gestao-organizacao',
        name: 'lgpd-publica-index',
        meta: {
          scopes: [],
          requiresAuth: false,
        },
        component: () => import('pages/publica/lgpd/lgpd-index.vue'),
      },
      {
        path: 'gestao-organizacao/dados',
        name: 'lgpd-publica-dados',
        meta: {
          scopes: [],
          requiresAuth: false,
        },
        component: () => import('pages/publica/lgpd/lgpd-dados.vue'),
      },
      {
        path: 'gestao-organizacao/cartorio/:entidadeId?',
        name: 'lgpd-publica-cartorio',
        meta: {
          scopes: [],
          requiresAuth: false,
        },
        component: () => import('pages/publica/lgpd/lgpd-cartorio.vue'),
      },
      {
        path: 'gestao-organizacao/fornecedor/:uuid',
        name: 'lgpd-publica-fornecedor',
        meta: {
          scopes: [],
          requiresAuth: false,
        },
        component: () => import('pages/publica/lgpd/lgpd-fornecedor.vue'),
      },
      {
        path: 'gestao-organizacao/fornecedor-cartorio/:fornecedorUuid/cartorio/:cartorioUuid',
        name: 'lgpd-publica-fornecedor-cartorio',
        meta: {
          scopes: [],
          requiresAuth: false,
        },
        component: () => import('pages/publica/lgpd/lgpd-fornecedor-cartorio.vue'),
      },

      {
        path: 'assinatura',
        name: 'assinatura-publica-solicitacao',
        meta: {
          scopes: [],
          requiresAuth: false,
        },
        component: () => import('pages/publica/assinador/solicitacao.vue'),
      },
    ],
  },
];

export default routes;
