/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../../services/alert-message';

const urlApi = 'api/v1/users';


export async function pesquisaCpf({ commit }, cpf) {
  Loading.show({ message: 'Pesquisando CPF...' });
  return (await http.get(`${urlApi}/pesquisa-cpf/${cpf}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
 * Alterando senha do usuário Logado
 * @param {*} password
 */
export async function userAlterarSenha({ commit }, password) {
  // const response = await http.post(`${urlApi}/users/password-update`, { password });
  return http.post(`${urlApi}/password-update`, { password });
}

/**
*
* @param {*} param0
*/
export async function listFileUser({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/${obj.user.id}/arquivos`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function getFileUser({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/${obj.user.id}/arquivos/${obj.arquivo.id}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


/**
*
* @param {*} param0
*/
export async function uploadFileUser({ commit }, obj) {
  Loading.show({ message: 'Enviando arquivo...' });
  return (await http.post(`${urlApi}/${obj.user_id}/arquivos/upload`, obj.formData)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function deleteFileUser({ commit }, obj) {
  Loading.show({ message: 'Removendo arquivo...' });
  return (await http.delete(`${urlApi}/${obj.user.id}/arquivos/${obj.arquivo.id}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
