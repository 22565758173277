const routes = [
  {
    path: '/cei',
    component: () => import('layouts/cei.vue'),
    children: [
      // Visão geral
      {
        path: '',
        redirect: 'visao-geral',
      },
      {
        path: 'visao-geral',
        name: 'cei-visao-geral',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'CEI', path: '' },
            { label: 'Visão Geral', path: 'cei-visao-geral' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/visao-geral.vue'),
      },

      /**
       * Central
       */

      // Consulta/Pesquisa
      {
        path: 'consulta',
        name: 'cei-consulta',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'CEI', path: 'cei-visao-geral' },
            { label: 'Pesquisa', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/consulta/cei-consulta-pesquisa.vue'),
      },
      {
        path: 'consulta-historico',
        name: 'cei-consulta-historico',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'CEI', path: 'cei-visao-geral' },
            { label: 'Histórico', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/consulta/cei-consulta-historico.vue'),
      },
      {
        path: 'consulta-servico',
        name: 'cei-consulta-servico',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'CEI', path: 'cei-visao-geral' },
            { label: 'Pesquisa', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/consulta/cei-consulta-servico.vue'),
      },
      {
        path: 'consulta-ato',
        name: 'cei-consulta-ato',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'CEI', path: 'cei-visao-geral' },
            { label: 'Pesquisa', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/consulta/cei-consulta-ato.vue'),
      },

      /** *************************
       * Distribuicao
       * ************************* */

      // Lista de Pedidos
      {
        path: 'pedidos',
        name: 'cei-pedido-list',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Início', path: 'default-home' },
            { label: 'CEI', path: 'cei-visao-geral' },
            { label: 'Pedidos', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/pedido/cei-pedido-list.vue'),
      },
      // Novo Pedido
      {
        path: 'novo-pedido',
        name: 'cei-novo-pedido',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'CEI', path: 'cei-visao-geral' },
            { label: 'Novo pedido', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/novo-pedido.vue'),
      },
      // Pedido
      {
        path: 'pedido/:pedido_id',
        name: 'cei-pedido',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'CEI', path: 'cei-visao-geral' },
            { label: 'Pedidos', path: 'cei-pedido-list' },
            { label: 'Pedido', path: '' },
          ],
          requiresAuth: true,
          scopes: ['r-distribuicao', 'w-distribuicao'],
        },
        component: () => import('pages/cei/pedido/cei-pedido.vue'),
      },
      // Lotes
      {
        path: 'lotes',
        name: 'cei-lote-list',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Início', path: 'default-home' },
            { label: 'CEI', path: 'cei-visao-geral' },
            { label: 'Lotes', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/pedido/cei-lote-list.vue'),
      },
      {
        path: 'lotes/:lote_id/pedidos',
        name: 'cei-lote-pedido-list',
        meta: {
          title: '',
          breadCrumb: [
            // { label: 'Início', path: 'default-home' },
            { label: 'CEI', path: 'cei-visao-geral' },
            { label: 'Pedidos do lote', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/pedido/cei-lote-pedido-list.vue'),
      },
      // Financeiro
      {
        path: 'financeiro',
        name: 'cei-financeiro',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'CEI', path: 'cei-visao-geral' },
            { label: 'Financeiro', path: '' },
          ],
          requiresAuth: true,
          scopes: ['r-distribuicao', 'w-distribuicao'],
        },
        component: () => import('pages/cei/financeiro/cei-financeiro.vue'),
      },
      // Outros
      {
        path: 'credito',
        name: 'cei-credito',
        meta: {
          title: 'Créditos',
          breadCrumb: [
            { label: 'Início', path: 'default-home' },
            { label: 'CEI', path: 'cei-index' },
            { label: 'Créditos', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/credito/cei-credito.vue'),
      },
      {
        path: 'credito/extrato-solicitacao',
        name: 'cei-credito-extrato-solicitacao',
        meta: {
          title: 'Extrato Solicitação',
          breadCrumb: [
            { label: 'Início', path: 'default-home' },
            { label: 'CEI', path: 'cei-index' },
            { label: 'Extrato Solicitação', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/credito/cei-credito-extrato-solicitacao.vue'),
      },
      {
        path: 'credito/extrato-solicitacao',
        name: 'cei-credito-extrato-consulta',
        meta: {
          title: 'Extrato Consulta',
          breadCrumb: [
            { label: 'Início', path: 'default-home' },
            { label: 'CEI', path: 'cei-index' },
            { label: 'Extrato Consulta', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/credito/cei-credito-extrato-consulta.vue'),
      },
      {
        path: 'cartorio',
        name: 'cei-cartorio-list',
        meta: {
          title: 'Cartórios',
          breadCrumb: [
            { label: 'Início', path: 'default-home' },
            { label: 'CEI', path: 'cei-index' },
            { label: 'Cartórios Consulta', path: '' },
          ],
          requiresAuth: true,
          scopes: ['w-cei', 'r-cei'],
        },
        component: () => import('pages/cei/cartorio/cei-cartorio-list.vue'),
      },
      {
        path: 'stream',
        name: 'cei-stream',
        meta: {
          title: '',
          breadCrumb: [
            { label: 'Início', path: 'default-home' },
            { label: 'CEI', path: 'cei-index' },
            { label: 'Stream', path: 'cei-stream' },
          ],
          requiresAuth: true,
          scopes: ['r-distribuicao', 'w-distribuicao'],
        },
        component: () => import('pages/cei/stream/cei-stream.vue'),
      },
    ],
  },
];

export default routes;
