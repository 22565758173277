export default function () {
  return {
    correicoes: {
      data: [],
      meta: {},
    },
    correicao: {
      attributes: {
        descricao: '',
      },
      relationships: {},
    },
  };
}
