/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'relatorio/api/admin';

/**
 * Gerando JSON Relatorio
 * @param {*} param0
 * @param {*} obj
 */
export async function gerarJson({ commit }, params) {
  Loading.show();
  params.tipo = 'json';
  return (
    await http.post(`${urlApi}/gerar?${params.filterUrl ? params.filterUrl : ''}`, params)
      .then((res) => {
        const element = document.createElement('a');

        element.setAttribute(
          'href',
          `data:text/plain;charset=utf-8,${encodeURIComponent(JSON.stringify(res.data))}`,
        );
        element.setAttribute(
          'download',
          'relatorio.json',
        );

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
        Loading.hide();
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

export async function gerarPdf({ commit }, params) {
  Loading.show();
  params.tipo = 'pdf';
  return (
    await http.post(`${urlApi}/gerar`, params, {
      responseType: 'arraybuffer',
    })
      .then((res) => {
        Loading.hide();
        this.gerarloading = false;

        const blob = new Blob([res.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        window.open(link);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

export async function gerarDomPdf({ commit }, params) {
  Loading.show();
  params.tipo = 'domPdf';
  return (
    await http.post(`${urlApi}/gerar?${params.filterUrl ? params.filterUrl : ''}`, params)
      .then((res) => {
        Loading.hide();
        window.open(res.data.data.url);
        // this.gerarloading = false;

        // const blob = new Blob([res.data], { type: 'application/pdf' });
        // const link = document.createElement('a');
        // link.href = window.URL.createObjectURL(blob);
        // window.open(link);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}
