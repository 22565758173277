/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'pedido';

export async function pesquisaCpfCnpj({ commit }, cpfCnpj) {
  Loading.show();
  return (
    await http.get(`/distribuicao/api/v1/pessoas/${cpfCnpj}`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
