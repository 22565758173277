/* eslint-disable */
import date from 'quasar/src/utils/date.js';;


/**
 * Convertendo Segundos em Hora
 */
String.prototype.converterSegundosParaHora = function () {

  const hora = duasCasas(Math.trunc(this / 3600));
  const minuto = duasCasas(Math.trunc((this % 3600) / 60));
  const segundo = duasCasas((this % 3600) % 60);
  const formatado = `${hora}:${minuto}:${segundo}`;
  return formatado;

};


function duasCasas(numero) {
  if (numero <= 9) {
    numero = `0${numero}`;
  }
  return numero;
};


/**
 * Formatando data
 */
String.prototype.formatDate = function (format, maskAtual = null) {
  if (!maskAtual) {
    return date.formatDate(this, format);
  }

  const d = date.extractDate(this, maskAtual);
  return date.formatDate(d, format);
  
};
