/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'financeiro/api/v1';

/**
 * Listando Conta Pagar
 */
export async function contaPagarList({ commit }, obj) {
  Loading.show({ message: '' });
  return (await http.get(`${urlApi}/contas-pagar?${obj.page}&${obj.filtros}`)
    .then((res) => {
      // commit('SET_CONTAS_PAGAR', res.data);
      Loading.hide();
      return res;
    })
    .catch(() => {
      Loading.hide();
    })
  ).data;
}

export async function contaPagarJson({ commit }, obj) {
  Loading.show({ message: '' });
  return (await http.get(`${urlApi}/contas-pagar/json?${obj.filtros}`)
    .then((res) => {
      // const element = document.createElement('a');
      // const contasPagar = res.data;
      // element.setAttribute(
      //   'href',
      //   `data:text/plain;charset=utf-8,${encodeURIComponent
      // (JSON.stringify({ data: contasPagar }))}`,
      // );
      // element.setAttribute(
      //   'download',
      //   'conta_pagar.json',
      // );

      // element.style.display = 'none';
      // document.body.appendChild(element);

      // element.click();

      // document.body.removeChild(element);
      Loading.hide();
      return res;
    })
    .catch(() => {
      Loading.hide();
    })
  ).data;
}

export async function contaPagarRelatorio({ commit }, obj) {
  Loading.show({ message: 'Gerando relatório' });
  return (await http.post(`${urlApi}/contas-pagar/relatorio?${obj.filtros}`, obj, {
    responseType: 'arraybuffer',
  })
    .then((res) => {
      // commit('SET_CONTAS_PAGAR', res.data);
      Loading.hide();
      const blob = new Blob([res.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      window.open(link);
      return res;
    })
    .catch(() => {
      Loading.hide();
    })
  ).data;
}
