/*
export function someMutation (state) {
}
*/


export function SET_ENTIDADES(state, list) {
  state.entidades = list;
}

export function SET_ENTIDADE(state, obj) {
  state.entidade = obj;
}
