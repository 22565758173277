/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';


/**
 * Orçamento
 */
export async function orcamentoSaveAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/orcamento-incluir`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function orcamentoPagarAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem/${obj.mensagem.id}/pagar-orcamento`,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function orcamentoRemoverAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .delete(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/orcamento-remover/${obj.mensagem.id}`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function orcamentoEnviarAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem/${obj.mensagem.id}/orcamento-enviar`,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function orcamentoItemSaveAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem/${obj.mensagem.id}/orcamento-incluir-item`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function orcamentoItemRemoveAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .delete(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem/${obj.mensagem.id}/orcamento-remover-item/${obj.item.id}`,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function orcamentoImprimirAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .get(`/distribuicao/app/v1/pedidos/${obj.pedido.id}/chat-mensagem/${obj.mensagem.id}/imprimir-orcamento`)
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
