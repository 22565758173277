/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'search';

/**
 * Buscando Pedidos
 * @param {*} param0
 */
export async function distribuicaoSearch({ commit }, obj) {
  // Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
