/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1';

/**
*
* @param {*} param0
*/
export async function respostaList({ state, commit }, obj) {
  Loading.show();
  return (
    await http.get(`${urlApi}/${obj.pergunta.id}/respostas`)
      .then((res) => {
        Loading.hide();
        commit('SET_RESPOSTA_LIST', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function respostaSave({ state, commit }, obj) {
  Loading.show({ message: 'Salvando resposta ...' });
  const resposta = JSON.parse(JSON.stringify(state.resposta));

  if (resposta.id) {
    return (
      await http.put(`${urlApi}/respostas/${resposta.id}/alternativa/${resposta.attributes.modeloRespostaId}`, resposta)
        .then((res) => {
          Loading.hide();
          // success(res.data.message);
          // commit('SET_ATOS', res.data);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }

  return (
    await http.post(`${urlApi}/processos/${obj.processo.id}/regulamentos/${obj.regulamento.id}/contextos/${obj.contexto.id}/perguntas/${obj.pergunta.id}/respostas`, resposta)
      .then((res) => {
        Loading.hide();
        // success(res.data.message);
        commit('SET_RESPOSTA', res.data.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
 * Salvando Multiplas alternativas
 * @param {*} param0
 */
export async function respostaMultiplaSave({ state, commit }, obj) {
  Loading.show();
  return (
    await http.post(`${urlApi}/processos/${obj.processo.id}/regulamentos/${obj.regulamento.id}/contextos/${obj.contexto.id}/perguntas/${obj.pergunta.id}/resposta-multipla`, obj.respostas)
      .then((res) => {
        Loading.hide();
        // success(res.data.message);
        // commit('SET_RESPOSTA', res.data.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}


/**
*
* @param {*} param0
*/
export async function respostaDelete({ state, commit }, obj) {
  Loading.show({ message: 'Removendo resposta ...' });
  return (
    await http.delete(`${urlApi}/respostas/${obj.resposta.id}`)
      .then((res) => {
        Loading.hide();
        // success(res.data.message);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}
