
function filtroBase(state) {
  const filtro = JSON.parse(JSON.stringify(state.filtro));
  let filter = '';

  if (filtro.periodo && !filtro.correicaoPortariaId) {
    if (filtro.periodo.from && filtro.periodo.to) {
      const atoInicio = filtro.periodo.from.formatDate('YYYY-MM-DD', 'DD/MM/YYYY');
      const atoFim = filtro.periodo.to.formatDate('YYYY-MM-DD', 'DD/MM/YYYY');

      filter += `&filter[periodo]=${atoInicio} 00:00:00,${atoFim} 23:59:59`;
    }
  }

  if (filtro.atoId) {
    filter += `&filter[codAto]=${filtro.atoId}`;
  }

  if (filtro.statusCorreicaoOnline) {
    filter += `&filter[statusCorreicaoOnline]=${filtro.statusCorreicaoOnline}`;
  }

  if (filtro.correicaoPortariaId) {
    filter += `&filter[correicaoPortariaId]=${filtro.correicaoPortariaId}`;
  }

  if (filtro.atoCodServico) {
    filter += `&filter[codServico]=${filtro.atoCodServico.id}`;
  }

  if (filtro.atoCodLivro) {
    filter += `&filter[codLivro]=${filtro.atoCodLivro}`;
  }

  if (filtro.atoLivro) {
    filter += `&filter[livro]=${filtro.atoLivro}`;
  }

  if (filtro.atoFolha) {
    filter += `&filter[folha]=${filtro.atoFolha}`;
  }

  if (filtro.numeroSelo) {
    filter += `&filter[selo]=${filtro.numeroSelo}`;
  }

  if (filtro.cns) {
    filter += `&filter[cns]=${filtro.cns}`;
  }

  if (filtro.atoNatureza) {
    filter += `&filter[natureza]=${filtro.atoNatureza}`;
  }


  return filter;
}


export function getFiltroPage(state) {
  return filtroBase(state);
}


export function getFiltros(state) {
  let filter = filtroBase(state);

  if (state.filtro.atoSelecionados.length) {
    filter += `&filter[ids]=${state.filtro.atoSelecionados}`;
  }

  return filter;
}
