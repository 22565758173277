import date from 'quasar/src/utils/date.js';;
// import { alert } from '../../../../../services/alert-message';


export function SET_DASHBOARD(state, data) {
  state.dashboard = data;
}

export function SET_PROTOCOLOS(state, list) {
  if (!list.data.length) {
    // alert('Não encontramos protocolos com o filtro informado');
    return;
  }

  if (list.clear) {
    list = {
      data: [],
      links: {},
      meta: {},
    };
  }

  list.data.map((ato) => {
    ato.selected = false;
    state.protocolos.data.push(ato);
    return true;
  });

  state.protocolos = list;
}

export function PUSH_PROTOCOLOS(state, list) {
  list.data.map((ato) => {
    ato.selected = false;
    state.protocolos.data.push(ato);
    return true;
  });

  state.protocolos.meta = list.meta;
}

export function RESET_PROTOCOLOS(state) {
  state.protocolos = {
    data: [],
  };
}

export function SET_PROTOCOLO(state, obj) {
  state.protocolo = obj;
}

export function RESET_FILTER(state) {
  const dateNow = Date.now();
  // const dataInicio = date.subtractFromDate(Date.now(), { days: 7 });
  state.filtro = {
    protocoloSelecionados: [],
    periodoEnvio: {
      from: `${date.formatDate(dateNow, 'YYYY/MM/DD')} 00:00:00`,
      to: `${date.formatDate(dateNow, 'YYYY/MM/DD')} 23:59:59`,
    },
    // periodoAto: null,
    dataEnvioInicio: date.formatDate(dateNow, 'DD/MM/YYYY'),
    dataEnvioFim: date.formatDate(dateNow, 'DD/MM/YYYY'),
    dataAtoInicio: '',
    dataAtoFim: '',
    atoHomologacao: false,
    integracoes: [],
    integracoesStatus: ['T', 'P', 'E'],
    xmlExportado: false,
    naoRecebidoComSucesso: false,
    atoId: null,
    atoCodServico: null,
    atoProtocolo: null,
    atoCodLivro: null,
    atoLivro: null,
    atoFolha: null,
  };
}
