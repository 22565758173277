/* eslint-disable */
/* eslint-disable no-unused-vars */
import Loading from 'quasar/src/plugins/Loading.js';;
import axios from 'axios';
import http from "../http/http";
import { error, success } from "../../../services/alert-message";

const urlApi = "entidades";


/**
 * Buscando Entidades
 * @param {*} param0
 * @param {*} obj
 */
export async function entidadeList({ commit }, obj) {
  Loading.show();
  return (
    await http
      .get(`api/v1/${urlApi}?${obj.page}&${obj.filtro}&${obj.include}`)
      .then(res => {
        Loading.hide();
        commit('SET_ENTIDADES', res.data.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Pesquisando empresa pelo CNPJ
 * @param {*} param0
 * @param {*} obj
 */
export async function entidadeCnpjSearch({ commit }, obj) {
  Loading.show({ message: 'Verificando CNPJ ...' });
  return (
    await http
      .post(`api/v1/${urlApi}/pesquisa-cnpj`, obj)
      .then(res => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;

}

/**
 * Pesquisando empresa pelo Email
 * @param {*} param0
 * @param {*} obj
 */
export async function entidadeEmailSearch({ commit }, obj) {
  Loading.show({ message: 'Verificando E-mail ...' });
  return (
    await http
      .post(`api/v1/${urlApi}/pesquisa-email`, obj)
      .then(res => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;

}

/**
 * Buscando Entidade
 * @param {*} param0
 * @param {*} endidadeId
 */
export async function entidadeGet({ commit }, endidadeId) {
  Loading.show();
  return (
    await http
      .get(`api/v1/${urlApi}/${endidadeId}?include=users,contasBancaria,cidade,cartorio,arquivos`)
      .then(res => {
        Loading.hide();
        commit('SET_ENTIDADE_CURRENT', res.data.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Salvando Entidade
 * @param {*} param0
 * @param {*} obj
 */
export async function entidadeSave({ commit }, obj) {
  Loading.show();
  let entidade = JSON.parse(JSON.stringify(obj));
  if (entidade.cpf_cnpj) {
    entidade.cpf_cnpj = obj.cpf_cnpj.replace(/[^0-9]/g, '');
  }
  if (entidade.fone1) {
    entidade.fone1 = obj.fone1.replace(/[^0-9]/g, '');
  }
  if (entidade.cep) {
    entidade.cep = obj.cep.replace(/[^0-9]/g, '');
  }

  return (
    await http
      .post(`api/v1/${urlApi}`, entidade)
      .then(res => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function entidadeUpdate({ commit }, obj) {
  let entidade = JSON.parse(JSON.stringify(obj));
  entidade.cpf_cnpj = entidade.cpf_cnpj.replace(/[^0-9]/g, '');
  if (entidade.id) {
    return (
      await http
        .put(`api/v1/${urlApi}/${entidade.id}`, entidade)
        .then(res => {
          Loading.hide();
          return res;
        })
        .catch(() => {
          Loading.hide();
        })
    ).data;
  }
}

/**
 * Buscando tokens da entidade
 * @param {*} param0
 * @param {*} obj
 */
export async function tokenList({ state, commit }) {
  Loading.show();
  return (
    await http
      .get(`api/v1/${urlApi}/${state.entidadeCurrent.id}/tokens`)
      .then(res => {
        Loading.hide();
        commit('SET_TOKENS', res.data.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}


/**
 * Salvando token
 * @param {*} param0
 * @param {*} obj
 */
export async function tokenSave({ commit }, obj) {
  Loading.show();
  return (
    await http.post(`api/v1/${urlApi}/${obj.entidade.id}/tokens`)
      .then(res => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Removendo token
 * @param {*} param0
 * @param {*} obj
 */
export async function tokenDelete({ commit }, obj) {
  Loading.show();
  return (
    await http.delete(`api/v1/${urlApi}/${obj.entidadeId}/tokens/${obj.tokenId}`)
      .then(res => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Buscando usuário da entidade
 * @param {*} param0
 * @param {*} obj
 */
export async function userList({ commit }, obj) {
  Loading.show();
  return (
    await http
      .get(`${urlApi}/${obj.id}/users`)
      .then(res => {
        Loading.hide();
        commit('SET_USERS', res.data.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Incluindo Usuário na entidade
 * @param {*} param0
 * @param {*} obj
 */
export async function userAttach({ commit }, obj) {
  Loading.show();
  return (
    await http.post(`api/v1/${urlApi}/${obj.entidadeId}/users/attach`, obj)
      .then(res => {
        Loading.hide();
        // success('Usuário removido com sucesso.');
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return { data: err };
      })
  ).data;
}

/**
 * Removendo Usuário
 * @param {*} param0
 * @param {*} obj
 */
export async function userDelete({ commit }, obj) {
  Loading.show();
  return (
    await http.delete(`api/v1/${urlApi}/${obj.entidadeId}/users/${obj.user.id}/detach`)
      .then(res => {
        Loading.hide();
        success('Usuário removido com sucesso.');
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * 
 * @param {*} param0
 * @param {*} obj
 */
export async function userRoleSync({ state, commit }, obj) {
  Loading.show();
  return (
    await http.post(`api/v1/${urlApi}/${state.entidadeCurrent.id}/users/${obj.user.id}/roleSync`, obj)
      .then(res => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return { data: err };
      })
  ).data;
}

/**
 * 
 * @param {*} param0
 * @param {*} obj
 */
export async function userNotificacaoSync({ state, commit }, obj) {
  Loading.show();
  return (
    await http.post(`api/v1/${urlApi}/${state.entidadeCurrent.id}/users/${obj.user.id}/notificacaoSync`, obj)
      .then(res => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return { data: err };
      })
  ).data;
}

// export async function procuracaoUpload({ commit }, obj) {
//   Loading.show();
//   const token = localStorage.getItem('token');
//   return (
//     await axios
//       .post(
//         `https://auth.ws-anoregmt.org.br/v1/entidades/${obj.entidade_id}/users/${obj.user_id}/procuracao`,
//         obj.formData,
//         {
//           headers: {
//             'Content-Type': 'multipart/form-data',
//             Authorization: `Bearer ${token}`,
//           },
//           onUploadProgress() {
//             // porcent = Math.round((progressEvent.loaded /
//             // progressEvent.total) * 100);
//           },
//         },
//       )
//       .then(() => {
//         Loading.hide();
//       })
//       .catch(() => {
//         Loading.hide();
//       })
//   ).data;
// }

// export async function procuracaoDownload({ commit }, pathProcuracao) {
//   Loading.show();
//   const token = localStorage.getItem('token');
//   return (
//     await axios
//       .post(
//         'https://auth.ws-anoregmt.org.br/v1/procuracao',
//         {
//           path_procuracao: pathProcuracao,
//         },
//         {
//           responseType: 'arraybuffer',
//           headers: {
//             // 'Content-Type': 'multipart/form-data',
//             // 'X-Custom-Header': 'foobar',
//             Authorization: `Bearer ${token}`,
//           },
//         },
//       )
//       .then((res) => {
//         Loading.hide();
//         const blob = new Blob([res.data], { type: 'application/pdf' });
//         const link = document.createElement('a');
//         link.href = window.URL.createObjectURL(blob);
//         window.open(link);
//         return res;
//       })
//       .catch(() => {
//         Loading.hide();
//       })
//   ).data;
// }

export async function entidadeTokenAppEnvio({ commit }, endidadeId) {
  Loading.show({ message: 'Gerado token ...' });
  return (
    await http
      .get(`api/v1/chave-envio`)
      .then(res => {
        const element = document.createElement('a');
        const blob = new Blob([res.data.data.chave], { type: 'text' });
        element.href = window.URL.createObjectURL(blob);
        element.setAttribute(
          'download',
          'chave.dat',
        );
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}


export async function entidadeGestaoOrganizacao({ commit }, obj) {
  Loading.show({ message: '' });
  return (
    await http.get(`api/publico/${urlApi}/${obj.entidade.id}/getao-organizacao`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}
