export default function () {
  return {
    loteListloading: true,
    dashboardLote: {
      financeiro: {},
      lote: {},
      status: {},
    },
    lotes: {
      data: [],
      meta: {},
    },
    loteAcoes: {
      data: [],
      meta: {},
    },
    acaoAtual: {},
  };
}
