/*
export function someMutation (state) {
}
*/
export function SET_BASE_CONHECIMENTO_LIST(state, list) {
  state.baseConhecimentos = list;
}

export function SET_BASE_CONHECIMENTO_TREE(state, list) {
  state.baseConhecimentoTree = list;
}


export function SET_BASE_CONHECIMENTO(state, obj) {
  state.baseConhecimento = obj;
}

export function SET_BASE_CONHECIMENTO_ITEM(state, obj) {
  state.baseConhecimentoItem = obj;
}

export function REMOVE_BASE_CONHECIMENTO_ITEM(state, item) {
  const i = state.baseConhecimento.relationships
    .baseConhecimentoItens.findIndex(it => it.id === item.id);
  if (i > -1) {
    state.baseConhecimento.relationships
      .baseConhecimentoItens.splice(i, 1);
  }
}
