/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1';

/**
*
* @param {*} param0
*/
export async function perguntaGeraPlanoAcao({ commit }, obj) {
  Loading.show({ message: 'Buscando análise de risco...' });
  return (await http.post(`${urlApi}/processos/${obj.processo.id}/regulamentos/${obj.regulamento.id}/contextos/${obj.contexto.id}/perguntas/${obj.pergunta.id}/gera-plano-acao`)
    .then((res) => {
      Loading.hide();

      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function perguntaList({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/processos/${obj.processo.id}/regulamentos/${obj.regulamento.id}/contextos/${obj.contexto.id}/perguntas`)
    .then((res) => {
      Loading.hide();
      commit('SET_PERGUNTA_LIST', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function perguntaCartorioServicoLgpd({ commit }, obj) {
  return (await http.get(`${urlApi}/perguntas/${obj.pergunta.id}/estrutura-lgpd`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
