/* eslint-disable  */
import { error, success } from './alert-message';

export const checkError = err => {
  if (err.response) {
    return errorTreatment(err.response);
  } else {

    error('Desculpe. Houve um problema. Tente novamente mais tarde.');
  }
};

export const checkSuccess = res => {
  if (res.success && res.message) {
    success(res.message);
  }
  if (res.success === false) {
    error(res.message);
  }
}

/**
 * Tratamento de erros
 * @param {*} error
 */
function errorTreatment(err) {

  if (err.data.success === false && err.data.message) {
    error(err.data.message);
    return Promise.reject(err.data);
  }

  if (!err.data) {
    error('Desculpe. Houve um problema. Tente novamente mais tarde.');
  }

  // Erros Sesp
  if (err.data.code) {
    if (err.data.code === 404) {
      if (err.data.message) {
        error(err.data.message);
        return Promise.reject(err.data);
      }
      error('Desculpe. Houve um problema. Tente novamente mais tarde.');
    }
    if (err.data.code === 500) {
      if (err.data.message) {
        error(err.data.message);
        return Promise.reject(err.data);
      }
      if (!err.data.errors) {
        error('Desculpe. Houve um problema. Tente novamente mais tarde.');
      }
    }
    if (err.data.errors.length) {
      error(err.data.errors[0]);
    }
    if (err.data.message) {
      error(err.data.message);
    }
  }

  if (err.data.error)
    if (err.data.error.name == 'InvalidJwtToken') {
      window.location.href = '#/opcoes';
      error('Credenciais inválidas');
      return true;
    }

  /**
   * Caso API retornar um erro
   */
  if (err.data.error) {
    switch (err.data.error.code) {
      case 401:
        error(err.data.error.message);
        localStorage.clear();
        window.location.href = '#/opcoes';
        break;
      case 500:
        error(err.data.error.message);
        break;
      default:
        error(err.data.error.message);
        if (err.data.error.message === 'O usuário não confirmou seu email.') {
          window.location.href = '#/email-validacao';
        }
        break;
    }
  }

  // console.log(err.data, 'Erro');

  /**
   * Novo Erros
   */
  if (err.data.errors) {

    switch (err.status) {
      case 404:
        error('Desculpe. Houve um problema. Tente novamente mais tarde.');
        break;
      case 401:
        error('Usuário não autorizado');
        localStorage.clear();
        window.location.href = '#/opcoes';
        break;
      case 422:
        if (err.data.errors) {
          if (err.data.errors[Object.keys(err.data.errors)[0]].detail) {
            error(err.data.errors[Object.keys(err.data.errors)[0]].detail);
          } else {
            error(err.data.errors[Object.keys(err.data.errors)[0]]);
          }
        }
        break;
      case 500:
        return Promise.reject(err.data);
      default:
        if (err.data.errors[Object.keys(err.data.errors)[0]].detail) {
          error(err.data.errors[Object.keys(err.data.errors)[0]].detail);
        } else {
          error(err.data.errors[Object.keys(err.data.errors)[0]]);
        }

        break;
    }

    // if () {

    // }


  }

  /********************
   ** Tratando erros **
   ********************/

  if (err.status === 401) {
    window.location.href = '#/opcoes';
  }

  // if (err.status === 404) {
  //   error('(404) Rota não encontrada.');
  //   return true;
  // }

  if (err.status === 500) {
    switch (err.data.error.code) {
      case 422:
        Object.keys(err.data.error).map(key => {
          if (key !== 'code' && key != 'message') {
            error(err.data.error[key]);
          }
        });
        break;

      default:
        break;
    }
  }

  if (err.data.success === false && err.data.message) {
    error(err.data.message);
  }

  return Promise.reject(err.data);
}
