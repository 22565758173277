/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import axios from 'axios';
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'pedido';

export async function pedidoLoteAction({ commit }, obj) {
  Loading.show({
    message: obj.loadingMessage,
  });
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/lote-action`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function pedidoNotificacaoList({ commit }) {
  return (
    await http
      .get(`/distribuicao/api/v1/${urlApi}/notificacoes`)
      .then((res) => {
        Loading.hide();
        commit('SET_PEDIDO_NOTIFICACOES', res.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function pedidoNotificacaoDelete({ commit }, pedidoId) {
  return (
    await http
      .delete(`/distribuicao/api/v1/${urlApi}/${pedidoId}/notificacoes`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Buscando Pedidos
 * @param {*} param0
 */
export async function pedidoList({ commit }, obj) {
  return (
    await http
      .get(
        `/distribuicao/api/v1/${urlApi}?${obj.page}${obj.filtros}${obj.limit}&${obj.includes}`,
      )
      .then((res) => {
        Loading.hide();
        commit('SET_PEDIDOS', res.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Buscando Pedidos Oficios
 * @param {*} param0
 */
export async function pedidoOficiosList({ commit }, obj) {
  // Loading.show({
  //   message: 'Buscando pedidos...',
  // });
  return (
    await http
      .get(
        `/distribuicao/api/v1/${urlApi}-destaque?`, // &filter[dias_atraso]=15
      )
      .then((res) => {
        Loading.hide();
        commit('SET_PEDIDOS_OFICIO', res.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 *  Alterando Observação do Pedido
 */
export async function pedidoObservacaoUpdate({ commit }, pedido) {
  Loading.show({
    message: '',
  });
  return (
    await http
      .patch(`/distribuicao/api/v1/pedido/${pedido.id}/observacao`, {
        observacao_remetente: pedido.observacao_remetente,
        observacao_destinatario: pedido.observacao_destinatario,
      })
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Removendo Pedido
 * @param {*} param0
 * @param {*} id
 */
export async function pedidoDelete({ commit }, id) {
  Loading.show({
    message: 'Removendo pedido...',
  });
  return (
    await http
      .delete(`/distribuicao/api/v1/${urlApi}/${id}`)
      .then((res) => {
        Loading.hide();
        success('Pedido removido com sucesso!');
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Informando que pessoa leu o pedido
 * @param {*} param0
 * @param {*} obj
 */
export async function pedidoPessoaLer({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/pessoas/${obj.pessoaId}/ler`,
      )
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Informando que pessoa deu ciente no pedido
 * @param {*} param0
 * @param {*} obj
 */
export async function pedidoPessoaCiente({ commit }, obj) {
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/pessoa-ciente`)
      .then((res) => {
        return res;
      })
      .catch(() => { })
  ).data;
}

/**
 * Buscando Etapa atual para montar Relatório
 * @param {*} param0
 */
export async function pedidoEtapaRelatorio({ commit }, obj) {
  Loading.show({
    message: 'Gerando relatório ...',
  });
  return (
    await http
      .post(
        `/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/fase/${obj.etapaId}/gerar-relatorio`,
        obj,
        {
          responseType: 'arraybuffer',
        },
      )
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Buscando Etapa atual do Pedido
 * @param {*} param0
 */
export async function pedidoEtapaAtualGet({ commit }, obj) {
  Loading.show({
    message: 'Buscando informações...',
  });
  return (
    await http
      .get(
        `/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/etapa${obj.andamentoId ? `/${obj.andamentoId}` : ''
        }`,
        obj,
      )
      .then((res) => {
        Loading.hide();
        commit('SET_PEDIDO', res.data.data.andamento);
        commit(
          'SET_STEP_COMPONENTE',
          res.data.data.andamento.andamento.fase.componente,
        );
        return res.data;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Buscando valores dos componentes da etapa
 * @param {*} param0
 */
export async function pedidoEtapaExportar({ commit }, obj) {
  // Loading.show();
  return (
    await http
      .post(
        `/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/fase/${obj.faseId}/exportar`,
        obj,
      )
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Buscando valores dos componentes da etapa
 * @param {*} param0
 */
export async function pedidoExportar({ state, commit }, obj) {
  Loading.show({ message: 'Exportando pedido...' });
  return (
    await http
      .get(`/distribuicao/api/v2/pedidos/${obj.pedidoId}/exportar`, obj)
      .then((res) => {
        axios
          .get(res.data.data.url)
          .then((resXml) => {
            const element = document.createElement('a');

            element.setAttribute(
              'href',
              `data:text/plain;charset=utf-8,${resXml.data}`,
            );
            element.setAttribute('download', 'relatorio.json');

            element.setAttribute('download', `pedido-${state.pedido.id}.xml`);

            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            Loading.hide();
          })
          .catch(() => {
            Loading.hide();
          });
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Assinarndo Requerimento dos pedidos
 * @param {*} param0
 * @param {*} obj
 */
export async function assinarPadesPedidosStart({ commit }, obj) {
  Loading.show({
    message: 'Assinando documentos ...',
  });
  return (
    await http
      .post('/distribuicao/api/v1/assinar-pades-pedidos-start', obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Assinarndo Requerimento dos pedidos
 * @param {*} param0
 * @param {*} obj
 */
export async function assinarPadesPedidosComplete({ commit }, obj) {
  Loading.show({
    message: 'Assinando documentos ...',
  });
  return (
    await http
      .post('/distribuicao/api/v1/assinar-pades-pedidos-complete', obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Avançando fase do pedido
 * @param {*} param0
 */
export async function pedidoAvancarFase({ commit }, id) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${id}/avanca-fase`)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function pedidoAvancarFaseAppV2({ commit }, pedido) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/app/v1/pedidos/${pedido.id}/avanca-fase`)
      .then((res) => {
        Loading.hide();
        success(res.data.message);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Avançando fase do pedido etapa de Excecao
 * @param {*} param0
 */
export async function pedidoAvancarExcecao({ commit }, id) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${id}/avanca-fase-excecao`)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Estornando fase do pedido
 * @param {*} param0
 */
export async function pedidoEstornarFase({ commit }, obj) {
  Loading.show({
    message: 'Estornando pedido...',
  });
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/estorna-fase`, obj)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Cancelando pedido
 * @param {*} param0
 */
export async function pedidoCancalar({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/cancelar`, obj)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Reabrir pedido
 * @param {*} param0
 */
export async function pedidoReabrir({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/reabrir`, obj)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Baixar pedido
 * @param {*} param0
 */
export async function pedidoBaixar({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/baixar`, obj)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Buscando Pedidos
 * @param {*} param0
 */
export async function pedidoTimelineGet({ commit }, id) {
  Loading.show();
  return (
    await http
      .get(`/distribuicao/api/v1/${urlApi}/${id}/timeline`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 *  Buscando atores do pedido
 *  Informando PedidoTipoSlug
 * @param {*} param0
 * @param {*} obj
 */
export async function atoresTipoList({ commit }, obj) {
  return (
    await http
      .get(
        `/distribuicao/api/v1/${urlApi}-entidade?slug=${obj.pedido_tipo_slug}&tipo_ator=${obj.tipo_ator}`,
      )
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Salvando pedido
 * @param {*} param0
 * @param {*} obj
 */
export async function pedidoSave({ commit }, obj) {
  const pedido = JSON.parse(JSON.stringify(obj));
  return (
    await http.post(`/distribuicao/api/v1/${urlApi}`, pedido).then((res) => {
      success(res.data.data.message);
      return res;
    })
  ).data;
}

export async function pedidoGetAppV2({ commit }, pedidoId) {
  Loading.show();
  return (
    await http
      .get(`/distribuicao/app/v1/pedidos/${pedidoId}`)
      .then((res) => {
        Loading.hide();
        commit('SET_PEDIDO', res.data.data);
        commit('SET_STEP_COMPONENTE', res.data.data.dadosAbertura);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

export async function pedidoSaveAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedido-tipos/${obj.pedidoVersaoId}/criar-pedido`,
        obj,
      )
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Importando Pedidos de um arquivo
 * @param {*} param0
 * @param {*} obj
 */
export async function pedidoImport({ commit }, obj) {
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/importar`, obj)
      .then((res) => {
        return res;
      })
  ).data;
}

/**
 * Enviando avaliação do pedido
 * @param {*} param
 * @param {*} obj
 */
export async function pedidoAvaliacao({ commit }, obj) {
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.id}/avaliacao`, obj)
      .then((res) => {
        success(res.data.data.message);
        return res.data;
      })
  ).data;
}

/**
 *
 * @param {*} param0
 * @param {*} obj
 */
export async function padidoFaseSave({ commit }, obj) {
  Loading.show({
    message: 'Salvando informações...',
  });

  const componenteValues = [];
  const componentes = JSON.parse(JSON.stringify(obj.componentes));
  // Montando Componente
  // Tratamento dos valores de cada Componente
  componentes.map((componente) => {
    // Caso tenha Mascara e Valor
    if (componente.meta.mask && componente.value) {
      componente.value = componente.value.replace(/[^a-z0-9]/g, '');
    }
    // Caso for do tipo Date
    if (componente.type === 'date') {
      if (componente.value) {
        const dateArray = componente.value.split('/');
        componente.value = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`;
      }
    }
    if (!componente.meta.isHide && componente.type !== 'group') {
      componenteValues.push({
        id: componente.id,
        value: componente.value,
      });
    }
    return true;
  });
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedido_id}/salvar-campos`, {
        pedido_id: obj.pedido_id,
        componentes: componenteValues,
      })
      .then((res) => {
        success(res.data.data.message);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Buscando todos os arquivos do pedido
 * @param {*} param0
 * @param {*} obj
 */
export async function pedidoGetFileAll({ commit }, obj) {
  return (
    await http
      .get(`/distribuicao/api/v1/${urlApi}/${obj.pedido_id}/arquivos`)
      .then((res) => {
        return res;
      })
  ).data;
}

/**
 * Jundando todos os arquivos do pedido
 * @param {*} param0
 * @param {*} obj
 */
export async function pedidoGetFileAllJunto({ commit }, obj) {
  Loading.show();
  return (
    await http
      .get(`/distribuicao/api/v1/${urlApi}/${obj.pedido_id}/processo-digital`)
      .then((res) => {
        Loading.hide();
        return res;
      })
  ).data;
}

/**
 * Remove arquivo
 */
export async function pedidoRemoveFile({ commit }, obj) {
  Loading.show({
    message: 'Removendo documento...',
  });
  return (
    await http
      .post(`/distribuicao/api/v1/pedido/${obj.pedido_id}/remover-arquivo`, obj)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Gerando relatório das mensagens
 * @param {*} param0
 * @param {*} obj
 */
export async function printTimeLine({ state, commit }, obj) {
  Loading.show({ message: 'Gerando relatório...' });
  return (
    await http
      .get(
        `/distribuicao/api/v1/${urlApi}/${state.pedido.id}/timeline-historico`,
        {
          responseType: 'arraybuffer',
          headers: {
            Accept: 'application/pdf',
          },
        },
      )
      .then((res) => {
        Loading.hide();

        const blob = new Blob([res.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        window.open(link);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
 * Exportando protocolos em JSON
 * @param {*} param0
 */
export async function jsonTimeLine({ state, commit }, obj) {
  Loading.show({ message: 'Gerando JSON' });

  return (
    await http
      .get(
        `/distribuicao/api/v1/${urlApi}/${state.pedido.id}/timeline-historico`,
      )
      .then((res) => {
        Loading.hide();
        const element = document.createElement('a');
        element.setAttribute(
          'href',
          `data:text/plain;charset=utf-8,${encodeURIComponent(
            JSON.stringify(res.data),
          )}`,
        );
        element.setAttribute('download', 'timeline-pedido.json');

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
 * Iniciando Scanner
 */
export async function scanSession({ commit }, obj) {
  return (
    await http
      .post(
        `/distribuicao/api/v1/pedido/${obj.pedidoId}/scanner/scan-session`,
        obj,
      )
      .then((res) => {
        // success(res.data.data.message);
        return res;
      })
      .catch(() => {
        error('Erro');
      })
  ).data;
}

/**
 * Enviando SessionId para Api
 * Capturar os arquivos
 */
export async function scanSessionCallback({ commit }, obj) {
  return (
    await http
      .post(
        `/distribuicao/api/v1/pedido/${obj.pedidoId}/scanner/scan-session-callback`,
        obj,
      )
      .then((res) => {
        // success(res.data.data.message);
        return res;
      })
      .catch(() => {
        error('Erro');
      })
  ).data;
}

/**
 * Gerando venda do pedido
 * @param {*} param0
 */
export async function pedidoGeraVenda({ state, commit }, obj) {
  Loading.show({ message: 'Gerando boleto' });
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${state.pedido.id}/gera-venda`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Pagar pedido Com Créditos
 * @param {*} param0
 */
export async function pedidoPagarComCreditos({ state, commit }) {
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${state.pedido.id}/pagamento-creditos`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
 * Buscando todos os Tipo de Pedido
 * Liberado para todos (Usamos no filtro)
 * @param {*} param0
 */
export async function pedidoParametrosFiltro({ commit }) {
  return (
    await http
      .get(
        '/distribuicao/api/v1/pedido-filtro?include=atores,agrupamentosAtivos,categoria',
      )
      .then((res) => {
        return res;
      })
  ).data;
}

/**
 * Buscando erros do pedido
 * @param {*} param0
 * @returns
 */
export async function pedidoErrosList({ state, commit }) {
  Loading.show();
  return (
    await http
      .get(`/distribuicao/api/v1/${urlApi}/${state.pedido.id}/erros`)
      .then((res) => {
        commit('SET_ERROS_PEDIDO', res.data.data);
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
