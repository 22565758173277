export default function () {
  return {
    servicosGif: [
      { identificadosAto: 11, servicoCod: 1, servicoNome: 'Cartões de Firmas' },
      { identificadosAto: 18, servicoCod: 1, servicoNome: 'Cartões de Firmas' },
      { identificadosAto: 19, servicoCod: 1, servicoNome: 'Cartões de Firmas' },
      { identificadosAto: 20, servicoCod: 1, servicoNome: 'Cartões de Firmas' },
      { identificadosAto: 21, servicoCod: 1, servicoNome: 'Cartões de Firmas' },
      { identificadosAto: 187, servicoCod: 1, servicoNome: 'Cartões de Firmas' },
      { identificadosAto: 188, servicoCod: 1, servicoNome: 'Cartões de Firmas' },
      { identificadosAto: 3, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 4, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 5, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 7, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 11, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 12, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 13, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 166, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 167, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 183, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 184, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 185, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 186, servicoCod: 2, servicoNome: 'Escritura' },
      { identificadosAto: 101, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 103, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 107, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 108, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 113, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 160, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 161, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 162, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 163, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 164, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 165, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 178, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 179, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 180, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 181, servicoCod: 3, servicoNome: 'Procuração' },
      { identificadosAto: 20, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 34, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 36, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 37, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 39, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 40, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 41, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 42, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 43, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 44, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 46, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 47, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 48, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 49, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 50, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 52, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 53, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 54, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 55, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 56, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 57, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 58, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 59, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 62, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 63, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 64, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 65, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 66, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 148, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 149, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 150, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 151, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 152, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 153, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 154, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 155, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 156, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 157, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 158, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 176, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 177, servicoCod: 4, servicoNome: 'Nascimento' },
      { identificadosAto: 1, servicoCod: 5, servicoNome: 'Casamento' },
      { identificadosAto: 6, servicoCod: 5, servicoNome: 'Casamento' },
      { identificadosAto: 16, servicoCod: 5, servicoNome: 'Casamento' },
      { identificadosAto: 22, servicoCod: 5, servicoNome: 'Casamento' },
      { identificadosAto: 175, servicoCod: 5, servicoNome: 'Casamento' },
      { identificadosAto: 0, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 31, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 143, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 144, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 145, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 146, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 147, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 173, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 174, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 194, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 527, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 528, servicoCod: 7, servicoNome: 'Registro Especial' },
      { identificadosAto: 67, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 68, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 69, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 70, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 71, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 72, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 73, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 74, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 75, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 76, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 77, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 78, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 79, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 80, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 81, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 82, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 83, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 84, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 85, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 86, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 87, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 88, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 89, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 90, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 91, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 92, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 93, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 94, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 95, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 96, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 97, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 98, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 142, servicoCod: 8, servicoNome: 'Protesto' },
      { identificadosAto: 25, servicoCod: 9, servicoNome: 'Títulos e Documentos' },
      { identificadosAto: 26, servicoCod: 9, servicoNome: 'Títulos e Documentos' },
      { identificadosAto: 27, servicoCod: 9, servicoNome: 'Títulos e Documentos' },
      { identificadosAto: 0, servicoCod: 10, servicoNome: 'Pessoa Jurídica' },
      { identificadosAto: 8, servicoCod: 10, servicoNome: 'Pessoa Jurídica' },
      { identificadosAto: 14, servicoCod: 10, servicoNome: 'Pessoa Jurídica' },
      { identificadosAto: 15, servicoCod: 10, servicoNome: 'Pessoa Jurídica' },
      { identificadosAto: 28, servicoCod: 10, servicoNome: 'Pessoa Jurídica' },
      { identificadosAto: 171, servicoCod: 10, servicoNome: 'Pessoa Jurídica' },
      { identificadosAto: 172, servicoCod: 10, servicoNome: 'Pessoa Jurídica' },
      { identificadosAto: 189, servicoCod: 10, servicoNome: 'Pessoa Jurídica' },
      { identificadosAto: 474849, servicoCod: 10, servicoNome: 'Pessoa Jurídica' },
      { identificadosAto: 110, servicoCod: 11, servicoNome: 'Registro de Imóveis' },
      { identificadosAto: 111, servicoCod: 11, servicoNome: 'Registro de Imóveis' },
      { identificadosAto: 112, servicoCod: 11, servicoNome: 'Registro de Imóveis' },
      { identificadosAto: 116, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 117, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 118, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 119, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 120, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 122, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 123, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 124, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 125, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 127, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 128, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 130, servicoCod: 14, servicoNome: '14-Não encontrado' },
      { identificadosAto: 131, servicoCod: 14, servicoNome: '14-Não encontrado' },
    ],
  };
}
