export default {
  // Usuário Logado
  user: {
    name: '',
    cpfCnpj: '',
    current_company: '',
    email: '',
    cep: '',
    estado: null,
    cidade: null,
    endereco: '',
    bairro: '',
    racaptcha: '',
    professional_consultations: [],
    image_path: 'https://cdn.quasar.dev/img/boy-avatar.png',
    remember_me: false,
  },
  // Usuário do acesso
  // Podeser a PF ou PJ
  userCurrent: {
    tipo: 'F',
    cpf_cnpj: '',
    cartorio: {
      ibge_code: '',
      estado: {
        abbreviation: '',
      },
    },
  },
  token: '',
  warmup: {},
  certificadoAtual: {},
  // https://unstats.un.org/unsd/methodology/m49/
  nacionalidades: [
    {
      value: '028', sigla: 'ATG', label: 'Antiguano', pais: 'Antígua e Barbuda',
    },
    {
      value: '032', sigla: 'ARG', label: 'Argentino', pais: 'Argentina',
    },
    {
      value: '044', sigla: 'BHS', label: 'Bahamense', pais: 'Bahamas',
    },
    {
      value: '052', sigla: 'BRB', label: 'Barbadense', pais: 'Barbados - Barbadiano',
    },
    {
      value: '084', sigla: 'BLZ', label: 'Belizenho', pais: 'Belize',
    },
    {
      value: '068', sigla: 'BOL', label: 'Boliviano', pais: 'Bolívia',
    },
    {
      value: '076', sigla: 'BRA', label: 'Brasileiro', pais: 'Brasil',
    },
    {
      value: '152', sigla: 'CHL', label: 'Chileno', pais: 'Chile',
    },
    {
      value: '170', sigla: 'COL', label: 'Colombiano', pais: 'Colômbia',
    },
    {
      value: '188', sigla: 'CRI', label: 'Costarriquenho', pais: 'Costa Rica',
    },
    {
      value: '192', sigla: 'CUB', label: 'Cubano', pais: 'Cuba',
    },
    {
      value: '212', sigla: 'DMA', label: 'Dominicano', pais: 'Dominica',
    },
    {
      value: '226', sigla: 'GNQ', label: 'Equatoriano', pais: 'Equador',
    },
    {
      value: '222', sigla: 'SLV', label: 'Salvadorenho', pais: 'El Salvador',
    },
    // {
    //   value: 'Granadino', sigla: '', label: 'Granadino', pais: 'Granada',
    // },
    {
      value: '320', sigla: 'GTM', label: 'Guatemalteco', pais: 'Guatemala',
    },
    // {
    //   value: 'Guianês', sigla: '', label: 'Guianês', pais: 'Guiana',
    // },
    {
      value: '254', sigla: 'GUF', label: 'Guianense', pais: 'Guiana Francesa',
    },
    {
      value: '332', sigla: 'HTI', label: 'Haitiano', pais: 'Haiti',
    },
    {
      value: '340', sigla: 'HND', label: 'Hondurenho', pais: 'Honduras',
    },
    {
      value: '388', sigla: 'JAM', label: 'Jamaicano', pais: 'Jamaica',
    },
    {
      value: '484', sigla: 'MEX', label: 'Mexicano', pais: 'México',
    },
    {
      value: '558', sigla: 'NIC', label: 'Nicaraguense', pais: 'Nicarágua',
    },
    {
      value: '591', sigla: 'PAN', label: 'Panamenho', pais: 'Panamá',
    },
    {
      value: '600', sigla: 'PRY', label: 'Paraguaio', pais: 'Paraguai',
    },
    {
      value: '604', sigla: 'PER', label: 'Peruano', pais: 'Peru',
    },
    {
      value: '630', sigla: 'PRI', label: 'Portorriquenho', pais: 'Porto Rico',
    },
    {
      value: '214', sigla: 'DOM', label: 'Dominicana', pais: 'República Dominicana',
    },
    {
      value: '659', sigla: 'KNA', label: 'São-cristo', pais: 'São Cristóvão e Nevis',
    },
    // {
    //   value: 'São-vicentino', sigla: '',
    // label: 'São-vicentino', pais: 'São Vicente e Granadinas',
    // },
    {
      value: '662', sigla: 'LCA', label: 'Santa-lucense', pais: 'Santa Lúcia ',
    },
    {
      value: '740', sigla: 'SUR', label: 'Surinamês', pais: 'Suriname',
    },
    {
      value: '780', sigla: 'TTO', label: 'Trindadense', pais: 'Trinidad e Tobago ',
    },
    {
      value: '858', sigla: 'URY', label: 'Uruguaio', pais: 'Uruguai',
    },
    {
      value: '862', sigla: 'VEN', label: 'Venezuelano', pais: 'Venezuela',
    },
    {
      value: '276', sigla: 'DEU', label: 'Alemão', pais: 'Alemanha',
    },
    {
      value: '040', sigla: 'AUT', label: 'Austríaco', pais: 'Áustria',
    },
    {
      value: '056', sigla: 'BEL', label: 'Belga', pais: 'Bélgica',
    },
    {
      value: '191', sigla: 'HRV', label: 'Croata', pais: 'Croácia',
    },
    {
      value: '208', sigla: 'DNK', label: 'Dinamarquês', pais: 'Dinamarca',
    },
    {
      value: '703', sigla: 'SVK', label: 'Eslovaco', pais: 'Eslováquia',
    },
    {
      value: '705', sigla: 'SVN', label: 'Esloveno', pais: 'Eslovênia',
    },
    {
      value: '724', sigla: 'ESP', label: 'Espanhol', pais: 'Espanha',
    },
    {
      value: '250', sigla: 'FRA', label: 'Francês', pais: 'França',
    },
    {
      value: '300', sigla: 'GRC', label: 'Grego', pais: 'Grécia',
    },
    {
      value: '348', sigla: 'HUN', label: 'Húngaro', pais: 'Hungria',
    },
    {
      value: '372', sigla: 'IRL', label: 'Irlandês', pais: 'Irlanda',
    },
    {
      value: '380', sigla: 'ITA', label: 'Italiano', pais: 'Itália',
    },
    {
      value: '578', sigla: 'NOR', label: 'Noruego', pais: 'Noruega',
    },
    {
      value: '528', sigla: 'NLD', label: 'Holandês', pais: 'Países Baixos',
    },
    {
      value: '616', sigla: 'POL', label: 'Polonês', pais: 'Polônia',
    },
    {
      value: '620', sigla: 'PRT', label: 'Português', pais: 'Portugal',
    },
    {
      value: '826', sigla: 'GBR', label: 'Britânico', pais: 'Reino Unido',
    },
    // {
    //   value: 'Inglês', sigla: '', label: 'Inglês', pais: 'Inglaterra',
    // },
    // {
    //   value: 'Galês', sigla: '', label: 'Galês', pais: 'País de Gales',
    // },
    // {
    //   value: 'Escocês', sigla: '', label: 'Escocês', pais: 'Escócia',
    // },
    {
      value: '642', sigla: 'ROU', label: 'Romeno', pais: 'Romênia',
    },
    {
      value: '643', sigla: 'RUS', label: 'Russo', pais: 'Rússia',
    },
    // {
    //   value: 'Sérvio', sigla: '', label: 'Sérvio', pais: 'Sérvio',
    // },
    {
      value: '752', sigla: 'SWE', label: 'Sueco', pais: 'Suécia',
    },
    {
      value: '756', sigla: 'CHE', label: 'Suíço', pais: 'Suíça',
    },
    {
      value: '792', sigla: 'TUR', label: 'Turco', pais: 'Turquia',
    },
    {
      value: '804', sigla: 'UKR', label: 'Ucraniano', pais: 'Ucrânia',
    },
    {
      value: '840', sigla: 'USA', label: 'Americano', pais: 'Estados Unidos',
    },
    {
      value: '124', sigla: 'CAN', label: 'Canadense', pais: 'Canadá',
    },
    {
      value: '024', sigla: 'AGO', label: 'Angolano', pais: 'Angola',
    },
    {
      value: '508', sigla: 'MOZ', label: 'Moçambicano', pais: 'Moçambique',
    },
    {
      value: '710', sigla: 'ZAF', label: 'Sul - africano', pais: 'África do Sul',
    },
    {
      value: '716', sigla: 'ZWE', label: 'Zimbabuense', pais: 'Zimbabue',
    },
    {
      value: '012', sigla: 'DZA', label: 'Argélia', pais: 'Argélia',
    },
    {
      value: '174', sigla: 'COM', label: 'Comorense', pais: 'Comores',
    },
    {
      value: '818', sigla: 'EGY', label: 'Egípcio', pais: 'Egito',
    },
    {
      value: '434', sigla: 'LBY', label: 'Líbio', pais: 'Líbia',
    },
    {
      value: '504', sigla: 'MAR', label: 'Marroquino', pais: 'Marrocos',
    },
    {
      value: '288', sigla: 'Ghana', label: 'Ganés', pais: 'Gana',
    },
    {
      value: '404', sigla: 'KEN', label: 'Queniano', pais: 'Quênia',
    },
    {
      value: '646', sigla: 'RWA', label: 'Ruandês', pais: 'Ruanda',
    },
    {
      value: '800', sigla: 'UGA', label: 'Ugandense', pais: 'Uganda',
    },
    {
      value: '072', sigla: 'BWA', label: 'Bechuano', pais: 'Botsuana',
    },
    // {
    //   value: 'Marfinense', sigla: '', label: 'Marfinense', pais: 'Costa do Marfim',
    // },
    {
      value: '120', sigla: 'CMR', label: 'Camaronense', pais: 'Camarões',
    },
    {
      value: '566', sigla: 'NGA', label: 'Nigeriano', pais: 'Nigéria',
    },
    {
      value: '706', sigla: 'SOM', label: 'Somali', pais: 'Somália',
    },
    {
      value: '036', sigla: 'AUS', label: 'Australiano', pais: 'Austrália',
    },
    {
      value: '554', sigla: 'NZL', label: 'Neozelandês', pais: 'Nova Zelândia',
    },
    {
      value: '004', sigla: 'AFG', label: 'Afegão', pais: 'Afeganistão',
    },
    {
      value: '682', sigla: 'SAU', label: 'Saudita', pais: 'Arábia Saudita',
    },
    {
      value: '051', sigla: 'ARM', label: 'Armeno', pais: 'Armênia',
    },
    // {
    //   value: 'Bangladesh', sigla: '', label: 'Bangladesh', pais: 'Armeno',
    // },
    {
      value: '156', sigla: 'CHN', label: 'Chinês', pais: 'China',
    },
    // {
    //   value: 'Norte-coreano', sigla: '', label: 'Norte-coreano', pais: 'Coréia do Norte',
    // },
    // {
    //   value: 'Sul - coreano', sigla: '', label: 'Sul - coreano', pais: 'Coréia do Sul',
    // },
    {
      value: '356', sigla: 'IND', label: 'Indiano', pais: 'Índia',
    },
    {
      value: '360', sigla: 'IDN', label: 'Indonésio', pais: 'Indonésia',
    },
    {
      value: '368', sigla: 'IRQ', label: 'Iraquiano', pais: 'Iraque',
    },
    {
      value: '364', sigla: 'IRN', label: 'Iraniano', pais: 'Irã',
    },
    {
      value: '376', sigla: 'ISR', label: 'Israelita', pais: 'Israel',
    },
    {
      value: '392', sigla: 'JPN', label: 'Japonês', pais: 'Japão',
    },
    {
      value: '458', sigla: 'MYS', label: 'Malaio', pais: 'Malásia',
    },
    {
      value: '524', sigla: 'NPL', label: 'Nepalês', pais: 'Nepal',
    },
    {
      value: '512', sigla: 'OMN', label: 'Omanense', pais: 'Omã',
    },
    {
      value: '586', sigla: 'PAK', label: 'Paquistanês', pais: 'Paquistão',
    },
    // {
    //   value: 'Palestino', sigla: '', label: 'Palestino', pais: 'Palestina',
    // },
    {
      value: '634', sigla: 'QAT', label: 'Qatarense', pais: 'Qatar',
    },
    {
      value: '760', sigla: 'SYR', label: 'Sírio', pais: 'Síria',
    },
    {
      value: '144', sigla: 'LKA', label: 'Cingalês', pais: 'Sri Lanka',
    },
    {
      value: '764', sigla: 'THA', label: 'Tailandês', pais: 'Tailândia',
    },
    // {
    //   value: 'Emirados Árabes Unidos', sigla: '', label: 'Emirados Árabes Unidos', pais: 'Árabe',
    // },
    // {
    //   value: 'Vietnamita', sigla: '', label: 'Vietnamita', pais: 'Vietnã',
    // },
    {
      value: '887', sigla: 'YEM', label: 'Iemenita', pais: 'Iêmen',
    },
  ],
};
