// Distribuição
import distribuicaoBase from './base';
import distribuicaoArquivo from './arquivo';
import distribuicaoAgrupamento from './agrupamento';
import distribuicaoVersao from './versao';
import distribuicaoAssinatura from './assinatura';
import distribuicaoComponente from './componente';
import distribuicaoContrato from './contrato';
import distribuicaoAndamento from './andamento';
import distribuicaoLote from './lote';
import distribuicaoPedido from './pedido';
import distribuicaoPedidoFase from './pedido-fase';
import distribuicaoPedidoFaseAndamentoOrcamento from './pedido-fase-andamento-orcamento';
import distribuicaoPedidoFaseAndamentoAvaliacao from './pedido-fase-andamento-avaliacao';
import distribuicaoPedidoTipo from './pedido-tipo';
import distribuicaoChat from './chat';
import distribuicaoSearch from './search';
import distribuicaoPessoa from './pessoa';

export default {
  distribuicaoBase,
  distribuicaoArquivo,
  distribuicaoAgrupamento,
  distribuicaoVersao,
  distribuicaoAssinatura,
  distribuicaoComponente,
  distribuicaoContrato,
  distribuicaoPedido,
  distribuicaoPedidoFase,
  distribuicaoPedidoFaseAndamentoOrcamento,
  distribuicaoPedidoFaseAndamentoAvaliacao,
  distribuicaoAndamento,
  distribuicaoLote,
  distribuicaoPedidoTipo,
  distribuicaoChat,
  distribuicaoSearch,
  distribuicaoPessoa,
};
