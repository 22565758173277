/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'pedido';

export async function assinarPadesStart({ commit }, obj) {
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/assinar-pades-start`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

export async function assinarPadesComplete({ commit }, obj) {
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/assinar-pades-complete`, obj)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

/**
 * Verificando se arquivo está assinado
 * @param {*} param0
 * @param {*} obj
 */
export async function verificaAssinaturaPades({ commit }, obj) {
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/verificar-assinatura-pades`, obj)
      .then((res) => {
        Loading.hide();
        // success(res.data.data.message);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

/**
 * Verificando metadados do arquivo
 * @param {*} param0
 * @param {*} obj
 */
export async function verificaMetadata({ commit }, obj) {
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/verificar-metadados`, obj)
      .then((res) => {
        Loading.hide();
        // success(res.data.data.message);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

/**
 * Salvando metadados do arquivo
 * @param {*} param0
 * @param {*} obj
 */
export async function saveMetadata({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/atualizar-metadados`, obj)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

export async function imprimirMetadados({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/imprimir-metadados`, obj, {
        responseType: 'arraybuffer',
      })
      .then((res) => {
        Loading.hide();
        // success(res.data.data.message);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        // error(`${err.error.message}`);
        return err;
      })
  ).data;
}

export async function imprimirAssinatura({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(`/distribuicao/api/v1/${urlApi}/${obj.pedidoId}/imprimir-assinatura`, obj, {
        responseType: 'arraybuffer',
      })
      .then((res) => {
        Loading.hide();
        // success(res.data.data.message);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        // error(`${err.error.message}`);
        return err;
      })
  ).data;
}
