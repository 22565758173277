/*
export function someMutation (state) {
}
*/
export function SET_PAGAMENTOS(state, list) {
  state.pagamentos = list;
}


export function RESET_PAGAMENTO(state, obj) {
  state.pagamento = obj;
}
