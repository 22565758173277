/* eslint-disable  */
/**
 * Verificando condições dos componentes
 * @param {*} n
 */
export function distribuicaoComponenteCheckCondicoes(pedidoStepComponentes) {
  const condition = conditionString => new Function(`return ${conditionString}`)();

  // Percorrendo todos os componentes da etapa
  pedidoStepComponentes.map(componentStep => {
    // Caso componente tenha condições de (Habilitar, Ocultar)
    if (componentStep.meta.hasCondition) {
      let conditionString = '';
      componentStep.meta.conditionHideList.map(condition => {
        // Pegando o componente da Condição
        const conditionComponent = pedidoStepComponentes.find(
          condi => condi.id === condition.conditionHideComponentId,
        );

        // Verificando se encontrou componente.
        // Não vai encontrar quando estiver abrindo no componente Validator
        if (conditionComponent) {
          // Montando string de condição
          if (condition.conditionOperatorValue && condition.conditionComponentValue) {
            conditionString += `${conditionComponent.value} ${condition.conditionOperatorValue} ${condition.conditionComponentValue} `;
            conditionString += `${
              condition.conditionOperatorCondition
                ? `${condition.conditionOperatorCondition} `
                : ';'
              }`;
          }
          return conditionComponent;
        }
      });

      if (conditionString) {
        // Tipo da condição
        switch (componentStep.meta.conditionType) {
          case 'disabled':
            componentStep.meta.disabled = condition(conditionString);
            break;
          case 'hide':
            componentStep.meta.isHide = condition(conditionString);
            break;

          default:
            break;
        }
      }
    }
  });
  return '';
}
