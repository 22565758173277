/* eslint-disable  */
import Vue from 'vue';
import Vuex from 'vuex';
import env from '../.env.js';

import system from './system';
import cei from './cei';
import misc from './misc';
import sesp from './sesp';
import geolocalizacao from './geolocalizacao';
import distribuicao from './distribuicao';
import biblioteca from './biblioteca';
import empresa from './empresa';
import auth from './auth';
import financeiro from './financeiro';
import arquivos from './arquivos';
import socket from './socket';
import lgpd from './lgpd';
import relatorio from './relatorio';

import estatisticas from './estatisticas';

Vue.use(Vuex);

const m = Object.assign(
  distribuicao,
  system,
  biblioteca,
  empresa,
  cei,
  misc,
  sesp,
  geolocalizacao,
  auth,
  financeiro,
  arquivos,
  socket,
  estatisticas,
  lgpd,
  relatorio
);

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: m 
  });

  return Store;
}
