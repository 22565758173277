/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'modules';

/**
 * Buscando Modulos
 * @param {*} param0
 * @param {*} obj
 */
export async function moduleList({ commit }, page) {
  Loading.show();
  return (
    await http
      .get(`${urlApi}?include=scopes`)
      .then((res) => {
        Loading.hide();
        commit('SET_MODULES', res.data.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

/**
 * Buscando cadastro da module
 * @param {*} param0
 * @param {*} id
 */
export async function moduleGet({ commit }, id) {
  Loading.show();
  return (
    await http.get(`${urlApi}/${id}?include=scopes`).then((res) => {
      Loading.hide();
      commit('SET_MODULE', res.data.data.module);
      return res;
    })
  ).data;
}

/**
 * Salvando module
 * @param {*} param0
 * @param {*} obj
 */
export async function moduleSave({ commit }, obj) {
  Loading.show();
  // Update
  if (obj.id) {
    return (
      await http
        .put(`${urlApi}/${obj.id}`, obj)
        .then((res) => {
          Loading.hide();
          success(res.data.data.message);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }
  // Create
  return (
    await http
      .post(urlApi, obj)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        // this.$router.push({ name: 'admin-module',
        // params: { module_id: res.data.data.module.id } });
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
 * Removendo module
 * @param {*} param0
 * @param {*} obj
 */
export async function moduleDelete({ commit }, obj) {
  return (
    await http.delete(`${urlApi}/${obj.id}`).then((res) => {
      success(res.data.data.message);
      this.$router.push({ name: 'admin-module-list' }).catch(() => {});
      return res;
    })
  ).data;
}

/**
 * Incluindo Scope no Módulo
 * @param {*} param0
 * @param {*} obj
 */
export async function attachScopeModule({ commit }, obj) {
  return (
    await http.post(`${urlApi}/${obj.module.id}/attach-scope`, { scope: obj.scope }).then((res) => {
      success(res.data.data.message);
      return res;
    })
  ).data;
}

/**
 * Removendo Scope do Módulo
 * @param {*} param0
 * @param {*} obj
 */
export async function detachScopeModule({ commit }, obj) {
  return (
    await http.post(`${urlApi}/${obj.module.id}/detach-scope`, { scope: obj.scope }).then((res) => {
      success(res.data.data.message);
      return res;
    })
  ).data;
}
