/*
export function someMutation (state) {
}
*/
export function SET_PARTES(state, list) {
  state.partes = list;
}

export function CONCAT_PARTES(state, list) {
  list.data.map((parte) => {
    state.partes.data.push(parte);
    return true;
  });
  state.partes.links = list.links;
  state.partes.meta = list.meta;
}
