/* eslint-disable  */
import Vue from 'vue';

// vm, info
Vue.config.errorHandler = (err) => {
  // console.log(`Error: ${err.toString()}\nInfo: ${info}`);
  if (err.name === 'NavigationDuplicated') {
    return '';
  }
  console.error('errorHandler', err);
};

// vm, trace
Vue.config.warnHandler = function (msg) {
  if (msg === `Avoid mutating a prop directly since the value will be overwritten whenever the parent component re-renders. Instead, use a data or computed property based on the prop's value. Prop being mutated: "openModal"`) {
    return '';
  }
  console.error('warnHandler', msg);
}
