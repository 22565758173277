// LGPD
import lgpdEntidade from './entidade';
import lgpdContexto from './contexto';
import lgpdFornecedor from './fornecedor';
import lgpdProcesso from './processo';
import lgpdInformacao from './informacao';
import lgpdRegulamento from './regulamento';
import lgpdPergunta from './pergunta';
import lgpdResposta from './resposta';
import lgpdMapaRisco from './mapa-risco';
import lgpdPlanoAcao from './plano-acao';
import lgpdPlanoAcaoCartao from './plano-acao-cartao';
import lgpdPlanoAcaoCartaoItem from './plano-acao-cartao-item';
import lgpdBaseConhecimento from './base-conhecimento';
import lgpdBaseConhecimentoItem from './base-conhecimento-item';
import lgpdEnvolvido from './envolvido';
import lgpdAuditoria from './auditoria';

export default {
  lgpdEntidade,
  lgpdContexto,
  lgpdFornecedor,
  lgpdProcesso,
  lgpdInformacao,
  lgpdRegulamento,
  lgpdPergunta,
  lgpdResposta,
  lgpdMapaRisco,
  lgpdPlanoAcao,
  lgpdPlanoAcaoCartao,
  lgpdPlanoAcaoCartaoItem,
  lgpdBaseConhecimento,
  lgpdBaseConhecimentoItem,
  lgpdEnvolvido,
  lgpdAuditoria,
};
