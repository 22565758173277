import date from 'quasar/src/utils/date.js';;


export function SET_PEDIDO_NOTIFICACOES(state, list) {
  state.pedidoNotificacoes = list;
}

export function SET_PEDIDOS(state, obj) {
  if (obj.meta.current_page === 1) {
    state.pedidos.data = obj.data;
  } else {
    obj.data.map((pedido) => {
      state.pedidos.data.push(pedido);
      return true;
    });
  }
  state.pedidoListloading = false;
  state.pedidos.links = obj.links;
  state.pedidos.meta = obj.meta;
}

export function SET_PEDIDO(state, obj) {
  if (obj) {
    state.pedido = obj;
  }
  obj = {
    destinatario_id: null,
    avaliacao_nota: 5,
  };
}

export function SET_PEDIDO_RESUMO(state, obj) {
  if (obj) {
    state.pedidoResumo = obj;
  } else {
    state.pedidoResumo = {
      versao: {
        attributes: {},
      },
      agrupamento: {},
      cidade: {},
      cartorio: {},
      tipoServico: {},
      servicos: [],
    };
  }
}

export function SET_PEDIDOS_OFICIO(state, list) {
  state.pedidoOficios = list;
}

export function SET_DASHBOARD_FILTROS(state, obj) {
  state.dashboardFiltro = obj;
}

export function SET_PEDIDO_FILTROS(state, obj) {
  state.pedidoFiltros = obj;
  localStorage.setItem('filterDistribuicao', JSON.stringify(state.pedidoFiltros));
}

export function RESET_PEDIDO_FILTROS(state) {
  state.pedidoFiltros = {
    ativo: false,
    showLotes: false,
    ordemCriacao: 0,
    criadosPorMim: 0,
    meusPedidos: 0,
    remetenteAtribuicao: '',
    status: 'aberto',
    pedido: {
      id: 0,
      nome: '',
      agrupamentos_ativos: [],
    },
    agrupamento: {
      nome: '',
      id: 0,
    },
    lote: {
      id: null,
    },
    categorias: null,
  };
  localStorage.setItem('filterDistribuicao', JSON.stringify(state.pedidoFiltros));
}

export function SET_PEDIDO_LIST_LOADING(state) {
  state.pedidoListloading = true;
}

/**
 * Setando Componentes da Etapa Corrente
 */
export function SET_STEP_COMPONENTE(state, componentes) {
  state.pedidoStepComponentes = [];
  componentes.map((componente) => {
    // componente.meta.disabled = false;
    if (typeof componente.meta === 'string') {
      componente.meta = {};
    }

    // Caso for Arquivo
    if (componente.type === 'file') {
      componente.arquivosSelecionados = [];
      componente.arquivoNaoEnviados = [];
      if (!componente.value) {
        componente.value = [];
      }
    }
    // Caso for Data
    if (componente.type === 'date') {
      if (!componente.value) {
        componente.value = null;
      }
      componente.value = date.formatDate(componente.value, 'DD/MM/YYYY');
    }
    // Caso for Caixa de Seleção
    if (componente.type === 'checkbox' && !componente.value) {
      if (!componente.value) {
        componente.value = false;
      }
    }
    // Caso for Validator
    if (componente.type === 'validator' && !componente.value) {
      if (!componente.value) {
        componente.value = false;
      }
    }
    // Caso for Pessoa
    if (componente.type === 'person') {
      if (!componente.value) {
        componente.value = [];
      } else {
        componente.value.map((person) => {
          person.selected = false;
          if (person.data_pacto) {
            person.data_pacto = date.formatDate(person.data_pacto, 'DD/MM/YYYY');
          }
          if (person.data_casamento) {
            person.data_casamento = date.formatDate(person.data_casamento, 'DD/MM/YYYY');
          }
          if (!person.menor_de_idade) {
            person.menor_de_idade = false;
          }
          return true;
        });
      }
      componente.pessoasSelected = [];
    }
    // Caso for Resposta de Questionário
    if (componente.type === 'quizAnswer') {
      componente.value.map((questao) => {
        if (questao.respostas.length) {
          // caso for resposta livre
          if (!questao.multipla_escolha && !questao.varias_respostas) {
            questao.respostas = questao.respostas[0].descricao;
          }

          // caso for multipla escolha
          if (questao.multipla_escolha && !questao.varias_respostas) {
            questao.respostas = Number(questao.respostas[0].descricao);
          }

          // caso for multiplas opções
          if (questao.varias_respostas) {
            if (questao.respostas) {
              questao.respostas = questao.respostas[0].descricao.split(',');
            } else {
              questao.respostas = [];
            }
          }
        }
        return true;
      });
    }
    // Caso for Garantia
    if (componente.type === 'warranty') {
      componente.value.map((garantia) => {
        if (garantia.data_registro) {
          garantia.data_registro = date.formatDate(garantia.data_registro, 'DD/MM/YYYY');
        }
        return true;
      });
    }
    // Caso for Procuração
    if (componente.type === 'letterAttorney') {
      componente.value.map((procuracao) => {
        if (procuracao.data_registro) {
          procuracao.data_registro = date.formatDate(procuracao.data_registro, 'DD/MM/YYYY');
        }
        return true;
      });
    }

    state.pedidoStepComponentes.push(JSON.parse(JSON.stringify(componente)));
    return true;
  });

  // state.pedidoStepComponentes = componentes;
}

export function FASE_DISABLED(state, status) {
  state.faseDisabled = status;
  if (state.pedido.andamento.id !== state.pedido.andamento_atual.id) {
    state.faseDisabled = true;
  }
}

/**
 * Setando erros do pedido
 * @param {*} state
 * @param {*} erros
 */
export function SET_ERROS_PEDIDO(state, erros) {
  state.pedido.erros = erros;
}
