/**
 * Buscando Scopo no usuário
 * @param {*} scopes
 * @param {*} scopeName
 */
export function getScopeUser(scopes, scopeName) {
  let acesso = '';
  scopes.map((scope) => {
    const arrayScope = scope.split('-');
    if (scope.indexOf(scopeName) !== -1) {
      acesso += arrayScope[0];
    }
    return true;
  });

  return acesso;
}

/**
 * Verificando Permissão em Distribuição
 * @param {*} state
 */
export function userHasScopeDistribuicao(state) {
  if (!state.user.id) {
    return 0;
  }
  return getScopeUser(state.user.scopes, 'distribuicao');
}

/**
 * Verificando Permissão em CEI
 * @param {*} state
 */
export function userHasScopeCei(state) {
  if (!state.user.id) {
    return 0;
  }
  return getScopeUser(state.user.scopes, 'cei');
}

/**
 * Verificando Permissão em Acompanhamento
 * @param {*} state
 */
export function userHasScopeAcompanhamento(state) {
  if (!state.user.id) {
    return 0;
  }
  return getScopeUser(state.user.scopes, 'acompanhamento');
}

/**
 * Verificando Permissão em Monicípio
 * @param {*} state
 */
export function userHasScopeMunicipio(state) {
  if (!state.user.id) {
    return 0;
  }
  return getScopeUser(state.user.scopes, 'municipio');
}

/**
 * Verificando Permissão em Biblíoteca
 * @param {*} state
 */
export function userHasScopeBiblioteca(state) {
  if (!state.user.id) {
    return 0;
  }
  return getScopeUser(state.user.scopes, 'biblioteca');
}


/**
 * Verificando Permissão em Guarda e Conservação
 * @param {*} state
 */
export function userHasScopeGuardaConservacao(state) {
  if (!state.user.id) {
    return 0;
  }
  return getScopeUser(state.user.scopes, 'guarda-conservacao');
}
