export function SET_LOTE_LIST(state, list) {
  state.lotes.data = [];
  state.lotes.pagination.page = list.meta.current_page;
  state.lotes.pagination.last_page = list.meta.last_page;
  state.lotes.pagination.rowsNumber = list.meta.total;
  state.lotes.pagination.rowsPerPage = list.meta.per_page;
  state.lotes.pagination['rows-per-page-options'] = [30, 40, 50];
  state.lotes.data = list.data;
}

export function SET_CONTRATO_LIST(state, list) {
  state.contratos.data = [];

  if (list.meta) {
    state.contratos.pagination.page = list.meta.current_page;
    state.contratos.pagination.last_page = list.meta.last_page;
    state.contratos.pagination.rowsNumber = list.meta.total;
    state.contratos.pagination.rowsPerPage = list.meta.per_page;
    state.contratos.pagination['rows-per-page-options'] = [30, 40, 50];
  }
  state.contratos.data = list.data;
}

export function SET_CONTRATO(state, contrato) {
  state.contrato = contrato.data;
}
