export default function () {
  return {
    mensagensLoad: false,
    mensagens: {
      data: [],
    },
    mensagensOcultasLoad: false,
    mensagensOcultas: {
      data: [],
    },
  };
}
