export default function () {
  return {
    planoAcaoCartaoItens: {
      data: [],
    },
    planoAcaoCartaoItem: {
      attributes: {
        titulo: '',
      },
      relationships: {
      },
    },
  };
}
