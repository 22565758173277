import date from 'quasar/src/utils/date.js';;


export function SET_CORREICAO_PORTARIA_LIST(state, list) {
  state.portarias = list;
}

export function PUSH_CORREICAO_PORTARIA_LIST(state, list) {
  list.data.map((p) => {
    state.portarias.data.push(p);
    return true;
  });
}


export function SET_CORREICAO_PORTARIA(state, obj) {
  let dateTime = '';
  if (obj.attributes.dataInicio) {
    dateTime = date.extractDate(obj.attributes.dataInicio, 'YYYY-MM-DD');
    obj.attributes.dataInicio = date.formatDate(dateTime, 'DD/MM/YYYY');
  }
  if (obj.attributes.dataFim) {
    dateTime = date.extractDate(obj.attributes.dataFim, 'YYYY-MM-DD');
    obj.attributes.dataFim = date.formatDate(dateTime, 'DD/MM/YYYY');
  }

  state.portaria = obj;
}
