/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = '';

/**
 * Buscando dashboard
 * @param {*} param0
 * @param {*} obj
 */
export async function getDashboard({ commit }, obj) {
  // Loading.show();
  return (
    await http
      .get('/distribuicao/api/v1/dashboard')
      .then((res) => {
        Loading.hide();
        commit('SET_DASHBOARD', res.data.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
