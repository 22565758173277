import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import { userValidate } from './route-guard';
import authStore from '../store/auth/auth';

Vue.use(VueRouter);

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */
export default function (/* { store, ssrContext } */) {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),

    routes,

    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,
  });

  Router.beforeEach((to, from, next) => {
    // Caso estiver em Manutenção
    if (authStore.state.user && authStore.state.user.cadastro_incompleto) {
      if (to.name !== 'verifica-cadastro-usuario') {
        next({ name: 'verifica-cadastro-usuario' });
      } else {
        next();
      }
    } else if (authStore.state.user && authStore.state.user.cadastro_incompleto_entidade) {
      if (to.name !== 'verifica-cadastro-entidade') {
        next({ name: 'verifica-cadastro-entidade' });
      } else {
        next();
      }
    } else if (to.meta.requiresAuth) {
      /**
       * Verifica se rota solicita Autenticação
       */
      // const user = JSON.parse(localStorage.getItem('user'));
      // Verificando se tem usuário no Store
      if (authStore.state.user.id) {
        // Comparando os Scopes do usuário com os Scopes da rota
        const routerName = userValidate(to.meta.scopes);

        setTimeout(() => {
          // Caso usuário n tenha scope da rota
          if (routerName && routerName !== to.name) {
            next({ name: routerName });
          } else {
            if (Vue.prototype.$anoreg.parametes.maintenance && !authStore.state.user.manager) {
              if (to.name !== 'default-maintenance') {
                next({ name: 'default-maintenance' });
              } else {
                next();
              }
            }
            // Caso estiver tudo certo
            // Manda usuário para página desejada
            next();
          }
        }, 50);
        // Caso não tiver usuário informado
      } else {
        // Verificando Token
        authStore.actions
          .checkToken()
          .then((res) => {
            // Setando usuário no Store
            authStore.mutations.SET_USER(authStore.state, res);

            // Comparando os Scopes do usuário com os Scopes da rota
            const routerName = userValidate(to.meta.scopes);

            setTimeout(() => {
              // Caso usuário n tenha scope da rota
              if (routerName && routerName !== to.name) {
                next({ name: routerName });
              } else {
                // Caso estiver tudo certo
                // Manda usuário para página desejada
                next();
              }
            }, 50);
          })
          .catch(() => {
            next({ name: 'auth-login' });
          });
      }
      /**
         * Caso rota Não solicita Autenticação
         */
    } else {
      next();
    }
  });

  /**
   * Criando push Forçado,
   * Redireciona usuário para Tela Branca
   * Depois para página desejada
   */
  Router.pushForce = (to, from) => {
    if (!from) {
      Router.push({ name: 'default-blank' });
    }
    setTimeout(() => {
      Router.push(to);
    }, 500);
  };

  return Router;
}
