export default function () {
  return {
    portarias: {
      data: [],
      meta: {},
    },
    portaria: {
      attributes: {},
      relationships: {
        pedidos: [],
        users: [],
      },
    },
  };
}
