import { error } from '../../../../../services/alert-message';

export function CLEAR_ATOS(state) {
  state.atos.data = [];
}

export function SET_ATOS(state, list) {
  if (!list.data.length) {
    error('Não encontramos dados com os parâmetros informados.');
    return;
  }

  if (list.clear) {
    list = {
      data: [],
      links: {},
      meta: {},
    };
  }

  state.atos = list;
}

export function PUSH_ATOS(state, list) {
  list.data.map((ato) => {
    state.atos.data.push(ato);
    return true;
  });

  state.atos.meta = list.meta;
}

export function CONCAT_ATOS(state, list) {
  list.data.map((ato) => {
    state.atos.data.push(ato);
    return true;
  });
  state.atos.links = list.links;
  state.atos.meta = list.meta;
}


export function SET_ATO(state, obj) {
  const ato = JSON.parse(JSON.stringify(obj));

  // Canculando tempo de Busca
  if (ato.relationships.consultasAtivas) {
    const busca = ato.relationships.consultasAtivas
      .find(c => c.attributes.tipoConsulta === 'B');
    if (busca) {
      ato.attributes.tempoConsulta = busca.attributes.tempoValido;
      setInterval(() => {
        ato.attributes.tempoConsulta -= 1;
      }, 1000);
    }
  }

  // // Calculando tempo de Visualização
  if (ato.relationships.consultasAtivas) {
    const visualizacao = ato.relationships.consultasAtivas
      .find(c => c.attributes.tipoConsulta === 'V');
    if (visualizacao) {
      ato.attributes.tempoVisualizacao = visualizacao.attributes.tempoValido;
      setInterval(() => {
        ato.attributes.tempoVisualizacao -= 1;
      }, 1000);
    }
  }

  state.ato = ato;
}
