/*
export function someMutation (state) {
}
*/


export function SET_BASE_CONHECIMENTO_ITEM(state, obj) {
  if (obj) {
    state.baseConhecimentoItem = obj;
  } else {
    state.baseConhecimentoItem = {
      attributes: {},
      relationships: {
        arquivos: [],
        baseConhecimentoItens: [],
      },
    };
  }
}
