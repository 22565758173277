/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'financeiro/api/v1';

/**
 * Listando Conta Corrente
 */
export async function contaCorrenteList({ commit }, obj) {
  return (await http.get(`${urlApi}/conta-corrente?${obj.page}&${obj.filter}`)
    .then((res) => {
      // commit('SET_CONTA_CORRENTE', res.data);
      return res;
    })
  ).data;
}

export async function saldoUsuario({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/verifica-saldo`)
    .then((res) => {
      Loading.hide();
      // commit('SET_CONTA_CORRENTE', res.data);
      return res;
    })
    .catch(() => {
      Loading.hide();
    })
  ).data;
}
