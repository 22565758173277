/*
export function someMutation (state) {
}
*/

/**
 * Setando Lista entidades
 * @param {*} state
 * @param {*} list
 */
export function SET_ENTIDADES(state, list) {
  state.entidades = list;
}

/**
 * Setando Entidade atual
 * @param {*} state
 * @param {*} list
 */
export function SET_ENTIDADE_CURRENT(state, obj) {
  if (obj.relationships.users.length) {
    obj.relationships.users.map((u) => {
      u.id = Number(u.id);
      return u;
    });
  }
  state.entidadeCurrent = obj;
}

/**
 * Setando tokens
 * @param {*} state
 * @param {*} list
 */
export function SET_TOKENS(state, list) {
  state.tokens = list;
}

/**
 * Setando users
 * @param {*} state
 * @param {*} list
 */
export function SET_USERS(state, list) {
  state.users = [];
  setTimeout(() => {
    state.users = list;
  }, 100);
}
