import Vue from 'vue';
import LacunaWebPKI from 'web-pki';

const keyPki = 'AkMAYXBwLmFub3JlZ210Lm9yZy5icixkZXYtYXBwLmFub3JlZ210Lm9yZy5icix3d3cuYXBwLmFub3JlZ210Lm9yZy5ickMAaXA0OjEwLjAuMC4wLzgsaXA0OjEyNy4wLjAuMC84LGlwNDoxNzIuMTYuMC4wLzEyLGlwNDoxOTIuMTY4LjAuMC8xNgMAUHJvAAAAAVWqcJWFOHirj/WFG4+I6YmxGCKBHVjYypngUQ4r6w2UYKw4z5UMuFg3qYQ4CCx+OiAbfjpIqPrd9+NfCPwU8ZvkIVR+zwd/x+dLxP9Yf5+E8zLxUi/pzPFkARrtSzxp5FfYEjsvjz255lDoVcQBVGTaCfS8a4kGNzF8Rlo8mejx1L1gNYRQP31dunCu9Sz9RhFNxa+StPNQ9/7HZX3oHH7qdAzsZ9pwAVH7FDRAeNkbZe3r/QeIhMwNifs3EhRQ+qpjcS0ZqLW7LvSypintz7+2mNy+wXPl5pbf901k7vxqgQt7yKGOYXPAMPh62XyhHcZ/HIPiZYmCtn96SGBZaBI=';

Vue.prototype.$pki = new LacunaWebPKI(keyPki);

/**
 * Quando n encontrado plugin WebPki
 */
Vue.prototype.$pkiInstall = () => {
  Vue.prototype.$q
    .dialog({
      title: 'PKI não instalado',
      message: 'Deseja instalar controlador de certificado?',
      ok: {
        label: 'Sim',
      },
      cancel: {
        label: 'Não',
      },
      persistent: true,
    })
    .onOk(() => {
      Vue.prototype.$pki.redirectToInstallPage();
    })
    .onOk(() => {

    })
    .onCancel(() => {

    });
};

/**
 * Quando encontramos um erro
 */
Vue.prototype.$pkiWebFail = (ex) => {
  if (ex.code !== 'user_cancelled') {
    Vue.prototype.$q
      .dialog({
        title: 'Encontramos um erro no certificado.',
        message: `Erro: ${ex.error} Código: ${ex.code}`,
        ok: {
          label: 'Ok',
        },
        // cancel: {
        //   label: 'Não',
        // },
        persistent: true,
      })
      .onOk(() => {
        // Vue.prototype.$pki.redirectToInstallPage();
      })
      .onOk(() => {

      })
      .onCancel(() => {

      });
  }
};
