/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'central/api/acesso-restrito/atos';

/**
* Buscando todos atos
* @param {*} param0
*/
export async function atoList({ commit }, obj) {
  return (await http.get(`${urlApi}?${obj.page}&${obj.filter}&include=tipoServico,tipoLivro,cartorio.entidade,partes,gif.itens`)
    .then((res) => {
      Loading.hide();
      // commit('SET_PROTOCOLOS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando ato
* @param {*} param0
*/
export async function atoGet({ commit }, id) {
  Loading.show();
  return (await http.get(`${urlApi}/${id}?include=partes,cartorio.entidade,tipoServico,tipoLivro,gif.itens`)
    .then((res) => {
      Loading.hide();
      commit('SET_ATO', res.data.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando imagem do ato
* @param {*} param0
*/
export async function imagemGet({ commit }, obj) {
  Loading.show();
  return (await http.post(`${urlApi}/${obj.ato_id}/arquivo`, obj)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
 * Alterando status Correição Online
 * @param {*} param0
 * @param {*} obj
 */
export async function updateStatusCorreicao({ commit }, obj) {
  Loading.show();
  return (await http.post(`${urlApi}/${obj.atoId}/correicao/status`, obj)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Exportando JSON atos
* @param {*} param0
*/
export async function exportaJsonAto({ commit }, obj) {
  Loading.show({ message: 'Gerando JSON, esse processo poderá levar vários minutos' });
  return (await http.get(`${urlApi}/exportar-json?${obj.filtro}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


/**
* Imprimir atos
* @param {*} param0
*/
export async function imprimirAto({ state, commit }, obj) {
  Loading.show({ message: 'Gerando relatório, esse processo poderá levar vários minutos' });

  return (await http.post(`${urlApi}/imprimir?${obj.filtro}`, obj.relatorio, {
    responseType: 'arraybuffer',
  })
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Lista cartórios com ultimo envio
* @param {*} param0
*/
export async function cartoriosUltimoEnvioList({ state, commit }, obj) {
  Loading.show();

  return (await http.get(`${urlApi}/lista-cartorio-envio-ato-10-dias?${obj.filters}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscar cartórios com ultimo envio
* @param {*} param0
*/
export async function cartorioUltimoEnvioShow({ state, commit }, obj) {
  Loading.show();

  return (await http.get(`${urlApi}/busca-cartorio-envio-ato-10-dias/${obj.cns}?filter[periodoInicial]=${obj.periodoInicial} 00:00:00&filter[periodoFinal]=${obj.periodoFinal} 23:59:59`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Justificar envio pendente
* @param {*} param0
*/
export async function justificarEnvioPendente({ state, commit }, justificativas) {
  Loading.show();

  return (await http.post(`${urlApi}/envio-ato-10-dias/justificar`, { justificativas })
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Justificar envio pendente
* @param {*} param0
*/
export async function removerJustificativaEnvioPendente({ state, commit }, justificativaId) {
  Loading.show();

  return (await http.delete(`${urlApi}/envio-ato-10-dias/justificativa/${justificativaId}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
