export default function () {
  return {
    pagamento: {
      attributes: {},
    },
    pagamentos: {
      data: [],
    },
  };
}
