/* eslint-disable */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = '';

/**
 * Buscando componente
 * @param {*} param0
 * @param {*} obj
 */
export async function getComponente({ commit }, obj) {
  return (
    await http
      .post(`/distribuicao/api/v1/pedido/${obj.pedidoId}/componentes`, obj,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      .then(res => {
        Loading.hide();
        return res;
      })
      .catch(err => {
        error(`${err.error.message}`);
        Loading.hide();
        return err;
      })
  ).data;
}


export async function showComponente({ commit }, obj) {
  return (
    await http
      .post(`/distribuicao/api/v1/pedido/${obj.pedidoId}/componente/${obj.componenteId}/show`)
      .then((res) => {
        return res;
      })
  ).data;
}


export async function actionComponente({ commit }, obj) {
  if (obj.loadingTitulo) {
    Loading.show({
      message: obj.loadingTitulo
    });
  }
  return (
    await http
      .post(`/distribuicao/api/v1/pedido/${obj.pedido_id}/componente/${obj.componente_id}/action`, obj, obj.header)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error('Tivemos um erro, favor tente mais tarde');
      })
  ).data;
}

export async function tabelaCustaItens({ commit }, obj = { filtro: '' }) {
  // Loading.show({ message: 'Buscando tabela de custas ...' });
  return (
    await http.get(`financeiro/api/v1/itens-tabela-custas?${obj.filtro}`)
      .then(res => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}