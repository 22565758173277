export default {
  lotes: {
    data: [],
    pagination: {},
    links: {},
    meta: {},
  },
  contratos: {
    data: [],
    pagination: {},
    links: {},
    meta: {},
  },
  contrato: {
    situacao: {},
    pedidos: [],
  },
};
