/*
export function someMutation (state) {
}
*/
import { error } from '../../../../../services/alert-message';

export function SET_HISTORICO(state, list) {
  if (!list.data.length) {
    error('Não encontramos histórico para este ato.');
    return;
  }

  if (list.clear) {
    state.historico.data = [];
    list = {
      data: [],
      links: {},
      meta: {},
    };
  }

  state.historico.meta = list.meta;
  list.data.forEach((element) => {
    state.historico.data.push(element);
  });
}

export function CONCAT_HISTORICO(state, list) {
  list.data.map((ato) => {
    state.historico.data.push(ato);
    return true;
  });
  state.historico.links = list.links;
  state.historico.meta = list.meta;
}
