/* eslint-disable  */
import axios from 'axios';
import Vue from 'vue';
import { checkError } from '../../../services/httpMessage';

// HTPP
let http = axios.create({
  baseURL: Vue.prototype.$anoreg.sesp.urlApi,
  timeout: 0,
  headers: {
    // 'X-Custom-Header': 'foobar',
  },
});

http.interceptors.request.use(request => {
  const token = localStorage.getItem('token');
  request.headers.authorization = `Bearer ${token}`;
  return request;
});

http.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    checkError(error);
  },
);

export default http;
