const routes = [
  {
    path: '/gestao-organizacao',
    component: () => import('layouts/lgpd.vue'),
    children: [
      // Visão geral
      {
        path: '',
        redirect: 'visao-geral',
      },
      {
        path: 'visao-geral',
        name: 'lgpd-visao-geral',
        meta: {
          title: 'Visão geral',
          breadCrumb: [
            // { label: 'Gestão e organização', path: '' },
            // { label: 'Visão geral', path: 'lgpd-visao-geral' },
          ],
          requiresAuth: true,
          emDesenvolvimento: true,
          scopes: ['start'],
        },
        component: () => import('pages/lgpd/visao-geral.vue'),
      },
      {
        path: 'prioridades',
        name: 'lgpd-processos',
        meta: {
          title: 'Prioridades',
          breadCrumb: [
            // { label: 'Gestão e organização', path: '' },
            // { label: 'Prioridade', path: 'lgpd-processos' },
          ],
          requiresAuth: true,
          emDesenvolvimento: true,
          scopes: ['start'],
        },
        component: () => import('pages/lgpd/processos.vue'),
      },
      {
        path: 'auditoria',
        name: 'lgpd-auditoria',
        meta: {
          title: 'Auditoria',
          breadCrumb: [
            // { label: 'Gestão e organização', path: '' },
            // { label: 'Prioridade', path: 'lgpd-processos' },
          ],
          requiresAuth: true,
          emDesenvolvimento: true,
          scopes: ['start'],
        },
        component: () => import('pages/lgpd/auditoria.vue'),
      },
      {
        path: 'base-conhecimento',
        name: 'lgpd-base-conhecimento',
        meta: {
          title: 'Registros/Evidências',
          descricao: '',
          breadCrumb: [
            // { label: 'Gestão e organização', path: '' },
            // { label: 'Base de conhecimento', path: 'lgpd-base-conhecimento' },
          ],
          requiresAuth: true,
          emDesenvolvimento: true,
          scopes: ['start'],
        },
        component: () => import('pages/lgpd/base-conhecimento.vue'),
      },
      {
        path: 'fornecedores',
        name: 'lgpd-fornecedores',
        meta: {
          title: 'Fornecedores',
          breadCrumb: [
            // { label: 'Gestão e organização', path: '' },
            // { label: 'Fornecedores', path: 'lgpd-fornecedores' },
          ],
          requiresAuth: true,
          emDesenvolvimento: true,
          scopes: ['start'],
        },
        component: () => import('pages/lgpd/fornecedores.vue'),
      },
      {
        path: 'mapa-de-risco',
        name: 'lgpd-mapa-risco',
        meta: {
          title: 'Análise de risco',
          breadCrumb: [
            // { label: 'Gestão e organização', path: '' },
            // { label: 'Análise de risco', path: 'lgpd-mapa-risco' },
          ],
          requiresAuth: true,
          emDesenvolvimento: true,
          scopes: ['start'],
        },
        component: () => import('pages/lgpd/mapa-risco.vue'),
      },
      {
        path: 'plano-de-acao',
        name: 'lgpd-plano-acao',
        meta: {
          title: 'Plano de ação',
          breadCrumb: [
            // { label: 'Gestão e organização', path: '' },
            // { label: 'Plano de ação', path: 'lgpd-plano-acao' },
          ],
          requiresAuth: true,
          emDesenvolvimento: true,
          scopes: ['start'],
        },
        component: () => import('pages/lgpd/plano-acao.vue'),
      },
      {
        path: 'auditorias',
        name: 'lgpd-auditorias',
        meta: {
          title: 'Auditorias',
          breadCrumb: [
            // { label: 'Gestão e organização', path: '' },
            // { label: 'Plano de ação', path: 'lgpd-plano-acao' },
          ],
          requiresAuth: true,
          emDesenvolvimento: true,
          scopes: ['start-auditor'],
        },
        component: () => import('pages/cei/acesso-restrito/lgpd-auditoria-list.vue'),
      },
      {
        path: 'auditorias/:auditoriaId',
        name: 'lgpd-auditorias-show',
        meta: {
          title: 'Auditorias',
          breadCrumb: [
            // { label: 'Gestão e organização', path: '' },
            // { label: 'Plano de ação', path: 'lgpd-plano-acao' },
          ],
          requiresAuth: true,
          emDesenvolvimento: true,
          scopes: ['start-auditor'],
        },
        component: () => import('pages/cei/acesso-restrito/lgpd-auditoria-show.vue'),
      },
      {
        path: 'auditoria-cartorios',
        name: 'lgpd-auditoria-cartorio',
        meta: {
          title: 'Auditoria cartórios',
          breadCrumb: [
            // { label: 'Gestão e organização', path: '' },
            // { label: 'Plano de ação', path: 'lgpd-plano-acao' },
          ],
          requiresAuth: true,
          emDesenvolvimento: true,
          scopes: ['start-auditor'],
        },
        component: () => import('pages/cei/acesso-restrito/lgpd-cartorio-list.vue'),
      },
    ],
  },
];

export default routes;
