/* eslint-disable */
export default {
  geoJson: {
    type: "FeatureCollection",
    name: "imoveis_certificados_lei",
    crs: { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
    features: []
  },
  filter: [],
  mapRef: null,
};
