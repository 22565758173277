/*
export function someMutation (state) {
}
*/


export function SET_CIDADES(state, list) {
  state.cidades = list;
}
export function SET_CIDADE(state, obj) {
  state.cidade = obj;
}
