/*
export function someGetter (state) {
}
*/

import authState from '../../auth/auth/state';

export function envolvidoCorrente(state) {
  const userCpf = authState.user.cpf_cnpj.replace(/[^0-9]/g, '');
  const envolvido = state.envolvidos.data.find(e => e.attributes.cpfCnpj === userCpf);
  if (!envolvido) {
    return false;
  }
  const papel = state.papeis.find(p => p.value === envolvido.attributes.papel);
  if (papel) {
    envolvido.attributes.papelLabel = papel.label;
  }
  return envolvido;
}
