/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'central/api/v1/atos';

/**
* Buscando todos atos
* @param {*} param0
*/
export async function historicoAtoList({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/${obj.atoId}/historicos?&page[number]=${obj.page}`)
    .then((res) => {
      Loading.hide();
      commit('SET_HISTORICO', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
