//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import date from 'quasar/src/utils/date.js';;

export default {
  name: 'ComponentUtilInputDate',
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    stackLabel: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    time: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {

    mascaraInput() {
      return this.time ? 'XX/XX/XXXX XX:XX' : 'XX/XX/XXXX';
    },

    mascaraTimeEng() {
      return this.time ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD ';
    },

    mascaraTimePt() {
      return this.time ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
    },

    data() {
      return date.formatDate(this.value, this.mascaraTimePt, this.mascaraTimeEng);
    },
  },
  methods: {

    input(val) {
      this.$emit('input', date.formatDate(val, this.mascaraTimeEng, this.mascaraTimePt));
    },

    inputModal(val) {
      this.input(date.extractDate(val, this.mascaraTimePt));
    },

  },
};
