/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

/**
 * Removendo lido do Andamento
 * @param {*} param0
 */
export async function andamentoRemoveLido({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/api/v1/pedido/${obj.pedidoId}/etapa/${obj.faseId}/andamento/${obj.andamentoId}/remove-lido`,
      )
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
