const routes = [
  // {
  //   path: '/distribuicao',
  //   component: () => import('layouts/distribuicao.vue'),
  //   children: [
  //     {
  //       path: '',
  //       name: 'distribuicao-index',
  //       meta: {
  //       },
  //       component: () => import('pages/distribuicao/Index.vue'),
  //     },
  //   ],
  // },
];

export default routes;
