/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'pedido-tipo';

/**
 * Buscando todos os Tipo de Pedido
 * Liberado apenas para o usuário logado (Usamos na criação do pedido)
 * @param {*} param0
 */
export async function pedidoTipoList({ commit }, obj) {
  return (
    await http.get(`/distribuicao/api/v1/${urlApi}?${obj.filtro}&include=atores.atribuicao,agrupamentosAtivos.versaoAtiva`).then((res) => {
      return res.data;
    })
  ).data;
}

export async function pedidoTipoListaAppV2({ commit }, obj) {
  return (
    await http.get('/distribuicao/app/v1/pedido-tipos')
      .then((res) => {
        commit('SET_PEDIDO_TIPOS', res.data);
        return res.data;
      })
  ).data;
}

export async function pedidoTipoShowAppV2({ commit }, pedidoTipo) {
  return (
    await http.get(`/distribuicao/app/v1/pedido-tipos/${pedidoTipo}`)
      .then((res) => {
        commit('SET_PEDIDO_TIPOS', res.data);
        return res.data;
      })
  ).data;
}
