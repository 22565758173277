/*
export function someMutation (state) {
}
*/

// import moment from 'moment';

export function SET_CONSULTAS_HISTORICO(state, list) {
  list.data.map((consulta) => {
    // const now = new Date();
    // const dataAtual = moment(now);
    // const dataConsulta = moment(consulta.attributes.dataBusca);

    // consulta.time = dataAtual.diff(dataConsulta, 'seconds');
    // if (consulta.time >= 3600) {
    //   consulta.time = 3600;
    //   return 0;
    // }
    // setInterval(() => {
    //   consulta.time += 1;
    // }, 1000);
    if (consulta.attributes.tempoValido) {
      setInterval(() => {
        consulta.attributes.tempoValido -= 1;
      }, 1000);
    }

    return true;
  });

  setTimeout(() => {
    state.consultaHistorico = list.data;
  }, 100);
}


export function PUSH_CONSULA_HISTORICO(state, consulta) {
  state.consultaHistorico.push(consulta);
}
