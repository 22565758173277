/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1';

/**
* Buscando todos contextos
* @param {*} param0
*/
export async function contextoList({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/contextos?${obj.page}&${obj.filter}`)
    .then((res) => {
      Loading.hide();
      commit('SET_CONTEXTO_LIST', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Informações do contexto
* @param {*} param0
*/
export async function contextoGet({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/processos/${obj.processo.id}/regulamento/${obj.regulamento.id}/contexto/${obj.contexto.id}`)
    .then((res) => {
      Loading.hide();
      commit('SET_CONTEXTO', res.data.data.contexto);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


/**
* Salvando
* @param {*} param0
*/
export async function contextoSave({ state, commit }) {
  Loading.show({ message: 'Salvando ...' });
  // const regulamento = JSON.parse(JSON.stringify(state.regulamento));
  return (
    await http.put(`${urlApi}/contextos/${state.contexto.id}`, state.contexto)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}
