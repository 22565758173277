//
//
//
//
//
//

import { mapState } from 'vuex';
import Package from '../package.json';

export default {
  name: 'Appe',
  components: {
  },
  data() {
    return {
      versionSystem: Package.version,
      teste: null,
    };
  },
  created() { },
  mounted() {
    if (this.$anoreg.parametes.maintenance) {
      this.$router.push({ name: 'default-maintenance' });
    }
    document.addEventListener(
      'keydown',
      (e) => {
        // Bloqueando (CTRL+S)
        if (e.keyCode === 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
          e.preventDefault();
        }
        // Bloqueando (CTRL+F)
        if (e.keyCode === 70 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
          const element = document.getElementsByClassName('inputSearch');
          if (!element[0]) {
            return;
          }
          const input = element[0].querySelector('input');
          if (input) {
            input.focus();
          } else {
            element[0].focus();
          }
          e.preventDefault();
        }
      },
      false,
    );
  },
  updated() { },
  computed: {
    ...mapState('auth', ['user']),
  },
  methods: {},
};
