/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../../services/alert-message';

const urlApi = 'api/v1/cartorios';


/**
*
* @param {*} param0
*/
export async function cartorioList({ commit }, obj) {
  Loading.show();
  // include=cidade.cidade,cidade.comarca,entidade.atribuicoes&
  return (await http.get(`${urlApi}?${obj.filter}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function modeloSinalPublico({ commit }, users) {
  Loading.show({ message: 'Gerando modelo ...' });
  return (await http.post(`${urlApi}/modelo-sinal-publico`, users, {
    responseType: 'arraybuffer',
  })
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function getCartorioUuid({ commit }, uuid) {
  Loading.show();
  // include=cidade.cidade,cidade.comarca,entidade.atribuicoes&
  return (await http.get(`api/publico/cartorios/${uuid}`)
    .then((res) => {
      Loading.hide();
      commit('SET_CARTORIO', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
