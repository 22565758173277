export default function () {
  return {
    params: {
      esconderInativo: false,
    },
    contextos: {
      data: [],
    },
    contexto: {
      attributes: {
        titulo: '',
      },
      relationships: {
        perguntas: [],
      },
    },
  };
}
