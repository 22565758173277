import Vue from 'vue';
import money from 'v-money';

Vue.use(money, { precision: 2 });

import inputDate from 'components/utilities/date-time/input-date';
import inputMoney from 'components/utilities/money/input-money';

Vue.component('input-date', inputDate);
Vue.component('input-money', inputMoney);
