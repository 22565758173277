export default function () {
  return {
    entidades: [],
    entidadeCurrent: {
      cidade: null,
      estado: null,
      relationships: {
        users: [],
        access_tokens: [],
      },
    },

    tokens: [],
    users: [],

  };
}
