/**
 * Incluindo Lista Notificações
 * @param {*} state
 * @param {*} notification
 */
export function SET_NOTIFICATIONS(state, notifications) {
  state.notifications = notifications;
}

/**
 * Incluindo notificação na Lista
 * @param {*} state
 * @param {*} notification
 */
export function PUSH_NOTIFICATIONS(state, notification) {
  state.notifications.unshift(notification);
}

/**
 * Incluindo notificação na Lista
 * @param {*} state
 * @param {*} notification
 */
export function UNSHIT_NOTIFICATIONS(state, notification) {
  state.notifications.unshift(notification);
}

/**
 * Alterando estado da Notificação de Lido e Não lido
 * @param {*} state
 * @param {*} notification
 */
export function UPDATE_READ_AT_NOTIFICATION(state, notification) {
  const i = state.notifications.data.findIndex(not => not.id === notification.id);
  state.notifications.data[i].read_at = !state.notifications.data[i].read_at;
}

/**
 * Incluindo Lista Relatórios
 * @param {*} state
 * @param {*} notification
 */
export function SET_RELATORIOS(state, relatorios) {
  state.relatorios = relatorios;
}

/**
 * Incluindo Relatório na lista
 * @param {*} state
 * @param {*} obj
 */
export function PUSH_RELATORIO(state, obj) {
  state.relatorios.push(obj.report);

  // Preparando Downlaod do relatório
  const bytes = new Uint8Array(obj.report.binary.data);

  const blob = new Blob([bytes], { type: `application/${obj.report.type}` });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = obj.report.name;
  link.click();
}

/**
 * Removendo relatório da lista
 * @param {*} state
 * @param {*} id
 */
export function REMOVE_RELATORIO(state, id) {
  const i = state.relatorios.findIndex(r => r.id === id);

  if (i > -1) {
    state.relatorios.splice(i, 1);
  }
}
