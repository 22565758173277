/*
export function someMutation (state) {
}
*/

/**
 * Setando Entidade
 */
export function SET_ENTIDADE(state, obj) {
  state.entidade = obj;
}
