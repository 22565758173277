/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1/processos';

/**
* Modelos de processos
* @param {*} param0
*/
export async function processoModeloList({ commit }, obj) {
  Loading.show();
  return (await http.get('lgpd/api/v1/modelo-processos')
    .then((res) => {
      Loading.hide();
      commit('SET_PROCESSO_MODELO_LIST', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando todos processos
* @param {*} param0
*/
export async function processoList({ commit }, obj) {
  Loading.show({ message: 'Buscando prioridades...' });
  return (await http.get(`${urlApi}?${obj.page}&${obj.filter}`)
    .then((res) => {
      Loading.hide();
      commit('SET_PROCESSO_LIST', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando processo
* @param {*} param0
*/
export async function processoGet({ commit }, id) {
  Loading.show();
  return (await http.get(`${urlApi}/${id}`)
    .then((res) => {
      Loading.hide();
      commit('SET_PROCESSO', res.data.data.processo);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


/**
* Salvando processo
* @param {*} param0
*/
export async function processoSave({ state, commit }) {
  Loading.show({ message: 'Salvando ...' });
  const processo = JSON.parse(JSON.stringify(state.processo));
  // processo.responsavel_telefone = processo.responsavel_telefone;
  if (state.processo.id) {
    return (
      await http.put(`${urlApi}/${state.processo.id}`, processo)
        .then((res) => {
          Loading.hide();
          success(res.data.data.message);
          // commit('SET_ATOS', res.data);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }

  return (
    await http.post(`${urlApi}`, processo)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
* Remover todos processos
* @param {*} param0
*/
export async function processoRemove({ state, commit }) {
  Loading.show({ message: 'Removendo processo...' });
  return (await http.delete(`${urlApi}/${state.processo.id}`)
    .then((res) => {
      Loading.hide();
      success(res.data.data.message);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


export async function processoInformacoesList({ state, commit }) {
  Loading.show();
  return (await http.get(`${urlApi}/${state.processo.id}/informacoes`)
    .then((res) => {
      Loading.hide();

      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


export async function processoAuditoriaPerguntaList({ state, commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/${state.processo.id}/auditoria-perguntas?${obj.filtros}`)
    .then((res) => {
      Loading.hide();

      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
