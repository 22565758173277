/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

/**
 * Iniciando assinatura
 * @param {*} param0
 * @param {*} obj
 */
export async function arquivosAssinarStartAppV2({ commit }, obj) {
  // Loading.show();
  return (
    await http
      .post(
        '/distribuicao/app/v1/arquivos/assinar-start', obj,
      )
      .then((res) => {
        Loading.hide();
        // commit('SET_MENSAGENS', res.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}

/**
 * Completando assinatura
 * @param {*} param0
 * @param {*} obj
 */
export async function arquivosAssinarCompleteAppV2({ commit }, obj) {
  // Loading.show();
  return (
    await http
      .post(
        '/distribuicao/app/v1/arquivos/assinar-complete', obj,
      )
      .then((res) => {
        Loading.hide();
        // commit('SET_MENSAGENS', res.data);
        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
