/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'scopes';

/**
 * Buscando Scopes
 * @param {*} param0
 * @param {*} obj
 */
export async function scopeList({ commit }, obj) {
  Loading.show();
  return (await http.get(urlApi)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      error(`${err.error.message}`);
      return err;
    })
  ).data;
}

/**
 * Buscando cadastro da Scope
 * @param {*} param0
 * @param {*} id
 */
export async function scopeGet({ commit }, id) {
  Loading.show();
  return (await http.get(`${urlApi}/${id}?include=scopes`)
    .then((res) => {
      Loading.hide();
      commit('SET_SCOPE', res.data.data.scope);
      return res;
    })
  ).data;
}

/**
 * Salvando Scope
 * @param {*} param0
 * @param {*} obj
 */
export async function scopeSave({ commit }, obj) {
  Loading.show();
  // Update
  if (obj.id) {
    return (await http.put(`${urlApi}/${obj.id}`, obj)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
    ).data;
  }
  // Create
  return (await http.post(urlApi, obj)
    .then((res) => {
      Loading.hide();
      success(res.data.data.message);
      this.$router.push({ name: 'admin-scope', params: { scope_id: res.data.data.scope.id } })
        .catch(() => {});
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
 * Removendo Scope
 * @param {*} param0
 * @param {*} obj
 */
export async function scopeDelete({ commit }, obj) {
  return (await http.delete(`${urlApi}/${obj.id}`)
    .then((res) => {
      success(res.data.data.message);
      this.$router.push({ name: 'admin-scope-list' }).catch(() => {});
      return res;
    })
  ).data;
}
