/*
export function someGetter (state) {
}
*/
import moment from 'moment';

export function tempoConsultaAto(state) {
  const consulta = state.ato.relationships.consultasAtivas.find(c => c.attributes.tipoConsulta === 'B');

  if (!consulta) {
    return false;
  }

  const now = new Date();
  const dataAtual = moment(now);
  const dataConsulta = moment(consulta.attributes.dataBusca);

  consulta.time = dataAtual.diff(dataConsulta, 'seconds');

  if (consulta.time >= 3600) {
    consulta.time = 3600;
    return false;
  }

  setInterval(() => {
    // if (consulta.time >= 3600) {
    //   const i = list.data.findIndex(c => c.id === consulta.id);

    //   if (i > -1) {
    //     list.data.splice(i, 1);
    //   }
    // }
    consulta.time += 1;
  }, 1000);

  return consulta.time;
}


export function tempoVisualizacaoAto() {

}
