export default function () {
  return {
    loadingProtocoloDashboard: false,
    dashboard: {
      falhasCei: 0,
      falhasCrc: 0,
      falhasSinter: 0,
      falhasSirc: 0,
    },
    filtro: {
      protocoloSelecionados: [],
      periodoEnvio: { from: '2020/07/08', to: '2020/07/17' },
      periodoAto: { from: '2020/07/08', to: '2020/07/17' },
      integracoes: ['sirc', 'sinter', 'crc'],
      integracoesStatus: ['T', 'P', 'E'],
      xmlExportado: false,
      naoRecebidoComSucesso: false,

      atoHomologacao: false,
      dataEnvioInicio: '',
      dataEnvioFim: '',
      dataAtoInicio: '',
      dataAtoFim: '',
      protocoloCei: null,
      atoId: null,
      atoCodServico: null,
      atoProtocolo: null,
      atoCodLivro: null,
      atoLivro: null,
      atoFolha: null,
    },
    protocolos: {
      data: [],
      meta: {},
    },
    protocolo: {
      relationships: {},
    },
  };
}
