/*
export function someMutation (state) {
}
*/
export function SET_CONTAS_PAGAR(state, list) {
  state.contasPagar = list;
}

export function PUSH_CONTAS_PAGAR(state, list) {
  list.data.map((cc) => {
    state.contasPagar.data.push(cc);
    return true;
  });

  state.contasPagar.meta = list.meta;
}


export function RESET_CONTA_PAGAR(state, obj) {
  state.contaPagar = obj;
}
