
/**
 *
 * @param {*} state
 * @param {*} autor
 */
export function setAutor(state, autor) {
  state.autor = autor;
}

/**
 * Incluindo competencia no autor
 * @param {*} state
 * @param {*} competencia
 */
export function insertCompetencia(state, competencia) {
  state.autor.relationships.proficiencies.push(competencia);
}

/**
 * Removendo Competência do autor
 * @param {*} state
 * @param {*} id
 */
export function removeCompetencia(state, id) {
  const i = state.autor.relationships.proficiencies.findIndex(comp => comp.id === id);
  if (i > -1) {
    state.autor.relationships.proficiencies.splice(i, 1);
  }
}
