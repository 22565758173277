export default function () {
  return {
    filtros: {
      termo: '',
    },
    resultado: {
      authors: [],
      files: [],
      paragraphs: [],
    },
  };
}
