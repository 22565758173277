export default function () {
  return {
    atos: {
      data: [],
      links: {},
      meta: {},
    },
    ato: {
      attributes: {},
      relationships: {
        consultasAtivas: [],
      },
    },
    tipoServicoAtos: [
      {
        servicoId: 1,
        servicoNome: '',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Cartão',
      },
      {
        servicoId: 2,
        servicoNome: '',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Identificador interno',
      },
      {
        servicoId: 3,
        servicoNome: '',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Identificador interno',
      },
      {
        servicoId: 4,
        servicoNome: '',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Termo',
      },
      {
        servicoId: 5,
        servicoNome: '',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Termo',
      },
      {
        servicoId: 6,
        servicoNome: '',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Termo',
      },
      {
        servicoId: 7,
        servicoNome: '',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Termo',
      },
      {
        servicoId: 8,
        servicoNome: '',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Protesto',
      },
      {
        servicoId: 9,
        servicoNome: '',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Registro',
      },
      {
        servicoId: 10,
        servicoNome: '',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Registro',
      },
      {
        servicoId: 11,
        servicoNome: '',
        codLivro: 0,
        labelDataAto: 'Praticado em',
        labelCodAto: 'Matrícula',
      },
      {
        servicoId: 11,
        servicoNome: '',
        codLivro: 18,
        labelDataAto: 'Praticado em',
        labelCodAto: 'Matrícula',
      },
    ],
  };
}
