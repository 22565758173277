/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../../../financeiro/http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'comprovantes';

/**
* Buscar Comprovantes Pagamento IR
* @param {*} param0
*/
export async function comprovantePagamentoIRLista({ state, commit }, obj) {
  return (
    await http.get(`${urlApi}?filter[tipo]=comprovante-pagamento-ir`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
* Buscar Comprovantes Pagamento IR
* @param {*} param0
*/
export async function comprovantePagamentoIRDownload({ state, commit }, obj) {
  return (
    await http.post(`${urlApi}/download`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}
