/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'api/v1/cartorios';

/**
* Buscando todos atos
* @param {*} param0
*/
export async function cartorioList({ commit }, obj) {
  return (await http.get(`${urlApi}?${obj.filter}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    })
  ).data;
}


/**
* Buscando sinal público cartórios
* @param {*} param0
*/
export async function cartorioSinalPublico({ commit }, obj) {
  return (await http.get(`${urlApi}/arquivos?${obj.filter}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    })
  ).data;
}

/**
* Buscando todos atos
* @param {*} param0
*/
export async function cartorioSinalPublicoShow({ commit }, uuid) {
  Loading.show({ message: 'Buscando arquivo ...' });
  return (await http.get(`${urlApi}/arquivos/${uuid}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
