import Vue from 'vue';
import Echo from 'laravel-echo';

window.Pusher = require('pusher-js');
// import Ws from '@adonisjs/websocket-client/index';
// import { error } from '../../../services/alert-message';
/**
 * Salvando o token
 * @param {*} state
 * @param {*} obj
 */
export function SAVE_TOKEN(state, obj) {
  localStorage.setItem('token', obj.access_token);
}

/**
 * Limpando usuário
 * @param {*} state
 */
export function CLEAR_USER(state) {
  state.user = { cidade: null, estado: null };
  localStorage.removeItem('user');
  localStorage.removeItem('token');
  localStorage.removeItem('updateToken');
  localStorage.removeItem('pusherTransportTLS');
  localStorage.removeItem('filterDistribuicao');
}

/**
 * Setando Usuário
 * Quem está usando essa function é rota/index
 * @param {*} state
 * @param {*} obj
 */
export function SET_USER(state, obj) {
  // Formatando Cpf Cnpj
  obj.data.me.cpf_cnpj = obj.data.me.cpf_cnpj.cpfCnpjFormat();
  if (obj.data.me.entidade_atual) {
    obj.data.me.entidade_atual.cpf_cnpj = obj.data.me.entidade_atual.cpf_cnpj.cpfCnpjFormat();
  }
  obj.data.me.entidades.map((entidade) => {
    entidade.cpf_cnpj = entidade.cpf_cnpj.cpfCnpjFormat();
    return true;
  });
  state.user = obj.data.me;
  state.user.image_path = 'https://cdn.quasar.dev/img/boy-avatar.png';

  if (state.user.entidade_atual) {
    state.userCurrent = state.user.entidade_atual;
  } else {
    state.userCurrent = state.user;
  }

  const userHoney = {};
  userHoney.name = state.user.name;
  userHoney.email = state.user.email;
  userHoney.cpf = state.user.cpf_cnpj;

  if (state.user.entidade_atual) {
    userHoney.entidade = state.user.entidade_atual.name;
    userHoney.cnpj = state.user.entidade_atual.cpf_cnpj;
    userHoney.email = state.user.entidade_atual.email;
  }

  Vue.$honeybadger.setContext({
    user: userHoney,
    entidade: state.entidade_atual,
  });

  if (Vue.prototype.$anoreg.socket.active) {
    Vue.prototype.$echoNotification = new Echo({
      broadcaster: Vue.prototype.$anoreg.socket.broadcaster,
      key: Vue.prototype.$anoreg.socket.key,
      wsHost: Vue.prototype.$anoreg.socket.urlApi,
      wsPort: Vue.prototype.$anoreg.socket.portaApi,
      authEndpoint: `${Vue.prototype.$anoreg.base.urlApi}/${Vue.prototype.$anoreg.socket.urlAuth}`,
      enabledTransports: Vue.prototype.$anoreg.socket.enabledTransports,
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
      cluster: Vue.prototype.$anoreg.socket.cluster,
      forceTLS: Vue.prototype.$anoreg.socket.forceTLS,
      disableStats: Vue.prototype.$anoreg.socket.disableStats,
      encrypted: Vue.prototype.$anoreg.socket.encrypted,
    });
  }
}

/**
 * Setando parâmetro para autentuicação com Certificado
 * @param {*} state
 * @param {*} obj
 */
export function SET_WARMUP(state, obj) {
  state.warmup = obj;
}

export function SET_CERTIFICADO_ATUAL(state, obj) {
  state.certificadoAtual = obj;
}
