/*
export function someGetter (state) {
}
*/


export function consultasAtivas(state) {
  const consultas = state.consultaHistorico.filter(c => c.attributes.tempoValido > 0);
  return consultas;
}
