import publicaRoutes from './publica-routes';
import authRouter from './auth-routes';
import estatisticaRouter from './estatistica-routes';
import distribuicaoRouter from './distribuicao-routes';
import ceiRouter from './cei-routes';
import geolocalizacaoRouter from './geolocalizacao-routes';
import bibliotecaAnoregRouter from './biblioteca-routes';
import empresaRouter from './empresa-routes';
import MinhaServentiaRouter from './minha-serventia-routes';
import orgaoPublicoRouter from './orgao-publico-routes';
import sespRouter from './sesp-routes';
import lgpdRouter from './lgpd-routes';

const routes = [
  {
    path: '',
    component: () => import('layouts/clean.vue'),
    children: [
      {
        path: '',
        redirect: 'opcoes',
      },
      {
        path: 'carregando-pagina',
        name: 'default-blank',
        meta: {
          title: '',
          scopes: ['can-login'],
        },
        component: () => import('pages/blank.vue'),
      },
      {
        path: 'manutencao',
        name: 'default-maintenance',
        meta: {
          title: 'Manutenção',
          scopes: [],
        },
        component: () => import('pages/maintenance.vue'),
      },
    ],
  },
  {
    path: '/',
    component: () => import('layouts/default.vue'),
    children: [
      {
        path: 'home',
        name: 'default-home',
        meta: {
          title: '',
          requiresAuth: true,
          scopes: ['can-login'],
        },
        component: () => import('pages/home.vue'),
      },
      {
        path: 'perfil',
        name: 'user-profile',
        meta: {
          title: 'Perfil',
          requiresAuth: true,
          scopes: ['can-login'],
        },
        component: () => import('pages/auth/profile.vue'),
      },
    ],
  },
];

// Always leave this as last one d
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/Error404.vue'),
  });
}

const retoruRouter = routes.concat(
  publicaRoutes,
  authRouter,
  distribuicaoRouter,
  estatisticaRouter,
  ceiRouter,
  geolocalizacaoRouter,
  bibliotecaAnoregRouter,
  empresaRouter,
  MinhaServentiaRouter,
  orgaoPublicoRouter,
  sespRouter,
  lgpdRouter,
);

export default retoruRouter;
