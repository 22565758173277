/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'central/api/v1/atos';

/**
* Buscando todos atos
* @param {*} param0
*/
export async function atosList({ commit }, obj) {
  Loading.show({ message: 'Buscando atos ...' });
  return (await http.get(`${urlApi}?${obj.page}&${obj.filter}&include=cartorio,tipoServico,tipoLivro`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function checkCobrancaAto({ commit }, obj) {
  Loading.show();
  return (await http.post(`${urlApi}/checkCobranca`, obj)
    .then((res) => {
      Loading.hide();
      // commit('SET_ATOS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando ato
* @param {*} param0
*/
export async function atoGet({ commit }, obj) {
  Loading.show();
  return (await http.post(`${urlApi}/${obj.id}?include=consultasAtivas,regularizacaoFundiaria`, obj)
    .then((res) => {
      Loading.hide();
      commit('SET_ATO', res.data.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}


export async function atosListPagina({ commit }, link) {
  return (await http.get(link.split('/')[4])
    .then((res) => {
      commit('CONCAT_ATOS', res.data);
      return res;
    })
    .catch((err) => {
      return err;
    })
  ).data;
}

/**
 * Buscando Tipo de dados ref. CPFCNPJ
 * @param {*} param0
 * @param {*} cpfCnpj
 * @returns
 */
export async function atosPesquisaTipoDados({ commit }, cpfCnpj) {
  Loading.show({ message: 'Pesquisando ...' });
  return (await http.get(`central/api/acesso-publico/tipo-dados/${cpfCnpj}`)
    .then((res) => {
      Loading.hide();
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando imóvel do ato
* @param {*} param0
*/
export async function getImovelAto({ state, commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/${state.ato.id}/imovel`, obj)
    .then((res) => {
      Loading.hide();
      // commit('SET_ATO', res.data.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
