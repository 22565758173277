/**
 * THIS FILE IS GENERATED AUTOMATICALLY.
 * DO NOT EDIT.
 *
 * You are probably looking on adding startup/initialization code.
 * Use "quasar new boot <name>" and add it there.
 * One boot file per concern. Then reference the file(s) in quasar.conf.js > boot:
 * boot: ['file', ...] // do not add ".js" extension to it.
 *
 * Boot files are your "main.js"
 **/

import lang from 'quasar/lang/pt-br'


import Vue from 'vue'

import Quasar from 'quasar/src/vue-plugin.js';import QBtn from 'quasar/src/components/btn/QBtn.js';import QToggle from 'quasar/src/components/toggle/QToggle.js';import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';import QIcon from 'quasar/src/components/icon/QIcon.js';import QBadge from 'quasar/src/components/badge/QBadge.js';import QImg from 'quasar/src/components/img/QImg.js';import QBanner from 'quasar/src/components/banner/QBanner.js';import QScrollObserver from 'quasar/src/components/scroll-observer/QScrollObserver.js';import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';import QChip from 'quasar/src/components/chip/QChip.js';import Scroll from 'quasar/src/directives/Scroll.js';import Notify from 'quasar/src/plugins/Notify.js';import Dialog from 'quasar/src/plugins/Dialog.js';import BottomSheet from 'quasar/src/plugins/BottomSheet.js';import Loading from 'quasar/src/plugins/Loading.js';import Cookies from 'quasar/src/plugins/Cookies.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';


Vue.use(Quasar, { config: {"brand":{"primary":"#1b5d93"}},lang: lang,components: {QBtn,QToggle,QScrollArea,QIcon,QBadge,QImg,QBanner,QScrollObserver,QOptionGroup,QChip},directives: {Scroll},plugins: {Notify,Dialog,BottomSheet,Loading,Cookies,AppFullscreen} })
