/* eslint-disable  */
import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';

// Vue.prototype.$axios = axios;
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDMS5OAgcuucTTRsxBSlkY2Ko_JxRXfaIw',
    libraries: 'places',
  },
});
