/* eslint-disable */


String.prototype.apenasNumeros = function () {
  return this.replace(/[^0-9]/g, '');
};

/**
 * Formatando CPF CNPJ
 */
String.prototype.cpfCnpjFormat = function () {

  if (!this) {
    return '';
  }
  if (!this.replace(/[^0-9]/g, '')) {
    return 'CPF inválido';
  }
  if (this.length > 11) {
    return this.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
  if (this.length > 10) {
    return this.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  return 'CPF inválido';
};

/**
 * Formatando CPF CNPJ Ocultando digitos
 */
String.prototype.cpfCnpjFormatOcultando = function () {

  if (!this) {
    return '';
  }
  if (!this.replace(/[^0-9]/g, '')) {
    return 'CPF inválido';
  }
  if (this.length > 11) {
    return this.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '##.$2.$3/####-##');
  }
  if (this.length > 10) {
    return this.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '###.$2.$3-##');
  }
  return 'CPF inválido';
};

/**
 * Formatando CNS
 */
String.prototype.cnsFormat = function () {

  if (!this) {
    return '';
  }

  return this.replace(/(\d{2})(\d{3})(\d{1})/, '$1.$2-$3');
};

/**
 * Formatando CEP
 */
String.prototype.cepFormat = function () {

  if (!this) {
    return '';
  }
  if (!this.replace(/[^0-9]/g, '')) {
    return 'CEP inválido';
  }
  if (this.length === 8) {
    return this.replace(/(\d{6})(\d{2})/, '$1-$2');
  }
  return 'CEP inválido';
};


/**
 * Formatando telefone
 */
String.prototype.telefoneFormat = function () {

  if (!this) {
    return '';
  }
  if (!this.replace(/[^0-9]/g, '')) {
    return 'Telefone inválido';
  }

  if (this.length < 11) {
    return this.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3');
  }
  if (this.length === 11) {
    return this.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
  }
  return 'Telefone inválido';

}

String.prototype.titleize = function () {
  var words = this.toLowerCase().split(' ');
  for (var a = 0; a < words.length; a++) {
    var w = words[a];
    words[a] = w[0].toUpperCase() + w.slice(1);
  }
  return words.join(' ');
};

String.prototype.tradutor = function () {
  let text = '';
  switch (this) {
    case 'Success (The content-type and message digest present in the signed attributes match the ones in the CMS)':
      text =
        'O tipo de conteúdo e o resumo da mensagem presentes nos atributos assinados correspondem aos do CMS - Sucesso';
      break;
    case 'destinatario':
      text = 'Destinatário';
      break;
    case 'remetente':
      text = 'Remetente';
      break;
    case 'distribuidor':
      text = 'Distribuidor';
      break;
    case 'baixa':
      text = 'Baixa';
      break;
    case 'media':
      text = 'Média';
      break;
    case 'alta':
      text = 'Alta';
      break;

    default:
      text = this;
      break;
  }

  return text;
};

String.prototype.statusPedido = function () {
  let status = '';

  status = this.replace(/[_]/g, ' ');
  status = status.charAt(0).toUpperCase() + status.slice(1);
  status = { label: status, bgColor: 'grey-4', textColor: 'black' };

  if (this == 'aberto') {
    status = { label: 'Em aberto', bgColor: 'yellow-9', textColor: 'white' };
  }
  if (this == 'encerrado') {
    status = { label: 'Finalizado', bgColor: 'green-9', textColor: 'white' };
  }
  if (this == 'cancelado') {
    status = { label: 'Cancelado', bgColor: 'orange-10', textColor: 'white' };
  }
  if (this == 'analise') {
    status = { label: 'Em análise', bgColor: 'grey-4', textColor: 'black' };
  }
  if (this == 'pendente') {
    status = { label: 'Pendente', bgColor: 'yellow-10', textColor: 'white' };
  }
  if (this == 'bloqueado') {
    status = { label: 'Bloqueado', bgColor: 'grey-9', textColor: 'white' };
  }

  return status;

}


String.prototype.tratarTag = function () {
  const array = this.split(' ');
  let t = '';

  for (let index = 0; index < array.length; index += 1) {
    const tt = array[index];

    if (tt) {
      if (tt.indexOf("http") !== -1) {
        t += ` <a target="_blank" href="${tt}">${tt}</a>`;
      } else {
        t += ` ${tt}`;
      }
    }
  }

  return t;
}

String.prototype.avatar = function () {
  const nomeArray = this.trim().split(' ');
  let avatar = '';

  if (nomeArray.length > 1) {
    avatar = nomeArray[0].substr(0, 1);
    avatar += nomeArray[nomeArray.length - 1].substr(0, 1);
  } else {
    avatar = nomeArray[0].substr(0, 2);
  }

  return avatar.toUpperCase();
}

/**
 * verificando se é url válida
 */
String.prototype.validarUrl = function () {
  var pattern = new RegExp('http[s]?:\\/\\/' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(this);
}

String.prototype.startCalculaRisco = function () {
  let r = {};
  if (this == 0 || !this) {
    r = {
      label: 'Não definido',
      color: 'grey-5',
      textColor: 'black',
    };
  }
  if (this >= 1 && this <= 5) {
    r = {
      label: 'Risco baixo',
      color: 'green-7',
      textColor: 'black',
    };
  }
  if (this >= 6 && this <= 9) {
    r = {
      label: 'Risco médio',
      color: 'yellow-8',
      textColor: 'black',
    };
  }
  if (this >= 10 && this <= 15) {
    r = {
      label: 'Risco alto',
      color: 'orange-7',
      textColor: 'black',
    };
  }
  if (this >= 16 && this <= 25) {
    r = {
      label: 'Risco muito alto',
      color: 'red-7',
      textColor: 'black',
    };
  }
  return r;
}

Number.prototype.startCalculaRisco = String.prototype.startCalculaRisco;