/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'gif/api/v1';

/**
* Buscando ato do Gif
* @param {*} param0
*/
export async function atoGifLista({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/atos-praticados?${obj.page}&${obj.filtros}`)
    .then((res) => {
      Loading.hide();
      commit('SET_ATO', res.data.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
* Buscando ato do Gif
* @param {*} param0
*/
export async function atoTjmtGifGet({ commit }, obj) {
  Loading.show();
  // filter[numeroSeloDigital] = ${ selo }
  return (await http.get(`${urlApi}/tjmt/atos-praticados?${obj.page}&${obj.filtros}`)
    .then((res) => {
      Loading.hide();
      commit('SET_ATO', res.data.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
