/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import Vue from 'vue';
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = Vue.prototype.$anoreg.base.urlAssinador;

/**
 * Buscando arquivos
 * @param {*} param0
 * @param {*} obj
 */
export async function solicitacaoShow({ commit }, obj) {
  Loading.show({ message: '' });
  return (
    await http.get(`${urlApi}/api/v2/flows/${obj.lote}?token=${obj.token}`)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

/**
 *
 * @param {*} param0
 * @param {*} obj
 */
export async function nonceWarmupGet({ commit }, obj) {
  Loading.show({ message: '' });
  return (
    await http.get(`${urlApi}/api/v2/nonce`)
      .then((res) => {
        Loading.hide();
        commit('SET_NONCE_WARMUP', res.data.data.warmup);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

/**
 *
 * @param {*} param0
 * @param {*} obj
 */
export async function iniciarAssinatura({ commit }, obj) {
  Loading.show({ message: '' });
  return (
    await http.post(`${urlApi}/api/v2/flows/${obj.flow}/iniciar-assinatura?token=${obj.token}`, { certContent: obj.certContent })
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

/**
 *
 * @param {*} param0
 * @param {*} obj
 */
export async function concluirAssinatura({ commit }, obj) {
  Loading.show({ message: '' });
  return (
    await http.post(`${urlApi}/api/v2/flows/${obj.flow}/concluir-assinatura?token=${obj.token}`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}

/**
 *
 * @param {*} param0
 * @param {*} obj
 */
export async function rejeitarAssinatura({ commit }, obj) {
  Loading.show({ message: '' });
  return (
    await http.post(`${urlApi}/api/v2/flows/${obj.flow}/rejeitar-assinatura?token=${obj.token}`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}
