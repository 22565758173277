export default function () {
  return {
    servicos: [
      {
        id: 1,
        nome: 'Cartões de Firmas',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Cartão',
        showProtocolo: true,
        livros: [
          {
            id: 1,
            nome: 'Cartões de Firmas',
          },
        ],
      },
      {
        id: 5,
        nome: 'Casamento',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Termo',
        livros: [
          {
            id: 7,
            nome: 'Livro B-Casamento',
          },
          {
            id: 8,
            nome: 'Livro B-Auxiliar Casamento religioso para efeitos civis',
          },
        ],
        showProtocolo: true,
      },
      {
        id: 2,
        nome: 'Escritura',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Identificador interno',
        showProtocolo: true,
        livros: [
          {
            id: 2,
            nome: 'I - Livro de Notas',
          },
          {
            id: 3,
            nome: 'II - Livro de Testamento',
          },
        ],
      },
      {
        id: 4,
        nome: 'Nascimento',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Termo',
        showProtocolo: true,
        livros: [
          {
            id: 6,
            nome: 'Livro A',
          },
        ],
      },
      {
        id: 10,
        nome: 'Pessoa Jurídica',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Registro',
        showProtocolo: true,
        livros: [
          {
            id: 15,
            nome: 'Livro A',
          },
          {
            id: 16,
            nome: 'Livro B',
          },
        ],
      },
      {
        id: 3,
        nome: 'Procuração',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Identificador interno',
        showProtocolo: true,
        livros: [
          {
            id: 4,
            nome: 'III - Livro de Procurações',
          },
          {
            id: 5,
            nome: 'IV - Livro de Substabelecimentos de Procurações',
          },
        ],
      },
      {
        id: 8,
        nome: 'Protesto',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Protesto',
        showProtocolo: true,
        livros: [
          {
            id: 12,
            nome: 'Livro de Protestos',
          },
        ],
      },
      {
        id: 7,
        nome: 'Registro Especial',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Termo',
        showProtocolo: true,
        livros: [
          {
            id: 11,
            nome: 'Livro E',
          },
        ],
      },
      {
        id: 11,
        nome: 'Registro de Imóveis',
        codLivro: 0,
        labelDataAto: 'Praticado em',
        labelCodAto: 'Matrícula',
        showProtocolo: true,
        livros: [
          {
            id: 17,
            nome: 'Livro 2 - Registro Gera',
          },
          {
            id: 18,
            nome: 'Livro 3 - Registro Auxiliar',
          },
        ],
      },
      {
        id: 9,
        nome: 'Títulos e Documentos',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Registro',
        showProtocolo: true,
        livros: [
          {
            id: 13,
            nome: 'Livro B',
          },
          {
            id: 14,
            nome: 'Livro C',
          },
        ],
      },
      {
        id: 6,
        nome: 'Óbito',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Termo',
        showProtocolo: true,
        livros: [
          {
            id: 9,
            nome: 'Livro C',
          },
          {
            id: 10,
            nome: 'Livro CA',
          },
        ],
      },

      {
        id: 12,
        nome: 'Atos restritos',
        labelDataAto: 'Praticado em',
        labelCodAto: 'Identificador interno',
        livros: [],
        showProtocolo: false,
      },
    ],
    servico: {},
  };
}
