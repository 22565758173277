const routes = [
  {
    path: '/biblioteca',
    component: () => import('layouts/biblioteca.vue'),
    children: [
      {
        path: '',
        name: 'biblioteca-index',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/Index.vue'),
      },
      {
        path: 'documentos/:categoria?',
        name: 'biblioteca-documentos',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/documento/documentos.vue'),
      },
      {
        path: 'documento/:documento_id',
        name: 'biblioteca-documento',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/documento/documento.vue'),
      },
      {
        path: 'entidades',
        name: 'biblioteca-entidades',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/entidade/entidades.vue'),
      },
      {
        path: 'entidades/:entidade_id',
        name: 'biblioteca-entidade',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/entidade/entidade.vue'),
      },
      {
        path: 'autores',
        name: 'biblioteca-autores',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/autor/autores.vue'),
      },
      {
        path: 'autores/:autor_id',
        name: 'biblioteca-autor',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/autor/autor.vue'),
      },

      {
        path: 'oficio',
        name: 'biblioteca-oficio',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/oficio.vue'),
      },
      {
        path: 'edital',
        name: 'biblioteca-edital',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/edital.vue'),
      },
      {
        path: 'intimacao',
        name: 'biblioteca-intimacao',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/intimacao.vue'),
      },
      {
        path: 'malote',
        name: 'biblioteca-malote',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/malote.vue'),
      },
      {
        path: 'informativo',
        name: 'biblioteca-informativo',
        meta: {
          title: '',
          breadCrumb: [{ label: 'Início', path: 'default-home' }],
          requiresAuth: true,
          scopes: ['w-acervo', 'r-acervo'],
        },
        component: () => import('pages/biblioteca/informativo.vue'),
      },
    ],
  },
];

export default routes;
