/*
export function someMutation (state) {
}
*/


export function SET_COMPRAS(state, list) {
  state.compras = list;
}


export function RESET_COMPRA(state, valor) {
  state.compra = {
    cliente_id: null,
    formaPagamento: '',
    quantidade: valor,
  };
}
