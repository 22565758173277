import date from 'quasar/src/utils/date.js';;
import { alert } from '../../../../../services/alert-message';

export function SET_ATOS(state, list) {
  if (!list.data.length) {
    alert('Não encontramos atos com o filtro informado');
    return;
  }

  if (list.clear) {
    list = {
      data: [],
      links: {},
      meta: {},
    };
  }

  list.data.map((ato) => {
    ato.selected = false;
    state.atos.data.push(ato);
    return true;
  });

  state.atos = list;
}

export function PUSH_ATOS(state, list) {
  list.data.map((ato) => {
    ato.selected = false;
    state.atos.data.push(ato);
    return true;
  });

  state.atos.meta = list.meta;
}

export function RESET_ATOS(state) {
  state.atos = {
    data: [],
  };
}

export function SET_ATO(state, obj) {
  const ato = JSON.parse(JSON.stringify(obj));
  state.ato = ato;
}

export function UPDATE_ATO_GIF(state, obj) {
  state.ato.relationships.gif = obj;
}

export function RESET_FILTER(state) {
  const dateNow = Date.now();
  const dataInicio = `${date.formatDate(dateNow, 'DD/MM/YYYY')}`;
  const dataFim = `${date.formatDate(dateNow, 'DD/MM/YYYY')}`;

  state.filtro = {
    cns: '',
    atoSelecionados: [],
    periodo: { from: dataInicio, to: dataFim },
    numeroSelo: '',
    atoLivro: '',
    atoFolha: '',
    atoCodProtocolo: '',
    atoCodLivro: '',
    atoId: '',
    atoCodServico: '',
  };
}
