/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = '';

/**
 * Buscando estatisticasd
 * @param {*} param0
 * @param {*} obj
 */
export async function estatisticaLista({ commit }, obj) {
  return (await http.get(`${urlApi}?${obj.filters}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      error(`${err.error.message}`);
      return err;
    })
  ).data;
}

/**
 * Buscando estatisticasd
 * @param {*} param0
 * @param {*} obj
 */
export async function estatisticaQuantitativoGeral({ commit }, obj) {
  return (await http.get(`${urlApi}/quantitativo-geral?`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      error(`${err.error.message}`);
      return err;
    })
  ).data;
}


/**
 * Buscando Busca Visualização
 * @param {*} param0
 */
export async function estatisticaQuantitativoBuscaVisualizacao({ commit }) {
  return (await http.get(`${urlApi}/quantitativo-consulta-visualizacao?`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      error(`${err.error.message}`);
      return err;
    })
  ).data;
}
