/* eslint-disable  */
import axios from 'axios';
import Notify from 'quasar/src/plugins/Notify.js';;
import Vue from 'vue';
import { error, success } from '../../../../services/alert-message';
import { checkError } from '../../../../services/httpMessage';

// HTPP
let http = axios.create({
  baseURL: Vue.prototype.$anoreg.base.urlApi,
  timeout: 0,
  headers: {
    // 'X-Custom-Header': 'foobar',
  },
});

http.interceptors.request.use(request => {
  const token = localStorage.getItem('token');
  request.headers.authorization = `Bearer ${token}`;
  return request;
});

http.interceptors.response.use(
  function (response) {
    // Do something with response data
    if (response.data) {
      if (response.data.message) {
        if (response.data.success === false) {
          error(response.data.message);
        } else {
          success(response.data.message);
        }
      }
    }
    return response;
  },
  function (error) {
    checkError(error);
  },
);

export default http;
