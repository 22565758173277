/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'upload';

/**
 * Buscando arquivos
 * @param {*} param0
 * @param {*} obj
 */
export async function uploadArquivo({ commit }, obj) {
  Loading.show();
  const fd = new FormData();
  fd.append('media', obj.file);
  return (
    await http
      .post(urlApi)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        error(`${err.error.message}`);
        return err;
      })
  ).data;
}
