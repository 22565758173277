/* eslint-disable  */
import Vue from "vue";
import HoneybadgerVue from "@honeybadger-io/vue";

const config = {
  apiKey: "db0defb0",
  environment: "production",
  revision: "master",
  onerror: false,
  ignorePatterns: [/Cannot read property/i, /UnhandledPromiseRejectionWarning/i]
};

Vue.use(HoneybadgerVue, config);
