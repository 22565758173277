export default function () {
  return {
    calendario: {
      attributes: {
        cns: '063776',
        cartorio: '5° Serviço Notarial E Registral De Cuiaba',
      },
    },
    livrosPorServico: [],
  };
}
