/*
export function someMutation (state) {
}
*/

export function SET_PROPERTIES(state, list) {
  state.geoJson.features = list;
}

export function INSERT_FILTER(state, property) {
  if (property.visible) {
    state.filter.unshift(property);
  } else {
    const i = state.filter.findIndex(p => p.matricula === property.matricula);
    state.filter.splice(i, 1);
  }
}

export function CLEAR_FILTER(state) {
  state.filter = [];
}

export function SET_MAPREF(state, map) {
  state.mapRef = map;
}


export function UPDATE_MAP(state) {
  state.mapRef.$mapPromise.then((map) => {
    map.data.setStyle((feature) => {
      const tipo = feature.getProperty('tipo');
      let visible = feature.getProperty('visible');

      if (state.filter.length) {
        visible = !feature.h.visible;
      }

      let color = '';
      switch (tipo) {
        case 'posse':
          color = '#0000FF';
          break;
        case 'lei':
          color = 'red';
          break;
        case 'registrado':
          color = '#f8f801';
          break;
        case 'sigef':
          color = 'blue';
          break;
        default:
          break;
      }
      return {
        visible: !visible,
        fillColor: color,
        strokeWeight: 1,
      };
    });
    // Caso tenha filtro
    if (state.filter.length) {
      map.setCenter({ lat: Number(state.filter[0].location.split(',')[0]), lng: Number(state.filter[0].location.split(',')[1]) });
      map.setZoom(12);
    } else {
      map.setZoom(9);
    }
  });
}
