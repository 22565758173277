/*
export function someMutation (state) {
}
*/
export function SET_REGULAMENTO_LIST(state, list) {
  state.regulamentos = list;
}


export function SET_REGULAMENTO(state, obj) {
  const regulamento = JSON.parse(JSON.stringify(obj));
  regulamento.attributes.progressoPergunta = regulamento.attributes.progressoPergunta < 100
    ? parseFloat(regulamento.attributes.progressoPergunta)
      .toFixed(2) : 100;
  regulamento.attributes.progressoPlanoAcao = regulamento.attributes.progressoPlanoAcao < 100
    ? parseFloat(regulamento.attributes.progressoPlanoAcao)
      .toFixed(2) : 100;
  if (regulamento.attributes.dataInicio) {
    regulamento.attributes.dataInicio = regulamento.attributes.dataInicio.formatDate('DD/MM/YYYY', 'YYYY-MM-DD');
  }
  if (regulamento.attributes.dataPrevistaTermino) {
    regulamento.attributes.dataPrevistaTermino = regulamento.attributes.dataPrevistaTermino.formatDate('DD/MM/YYYY', 'YYYY-MM-DD');
  }
  if (regulamento.attributes.dataTermino) {
    regulamento.attributes.dataTermino = regulamento.attributes.dataTermino.formatDate('DD/MM/YYYY', 'YYYY-MM-DD');
  }
  state.regulamento = regulamento;
}
