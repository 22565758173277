/*
export function someMutation (state) {
}
*/
export function SET_CONTEXTO_LIST(state, list) {
  state.contextos = list;
}


export function SET_CONTEXTO(state, obj) {
  state.contexto = obj;
}
