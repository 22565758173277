//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'ComponentUtilInputDate',
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    stackLabel: {
      type: Boolean,
    },
    disable: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      moneyFormatForDirective: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
  computed: {
  },
  methods: {

    input(val) {
      this.$emit('input', val.toString().replace(/\./g, '').replace(/,/g, '.'));
    },

  },
};
