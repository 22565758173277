/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1/base-conhecimentos';

/**
*
* @param {*} param0
*/
export async function baseConhecimentoList({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}?${obj.page}&${obj.filter}`)
    .then((res) => {
      Loading.hide();
      commit('SET_BASE_CONHECIMENTO_LIST', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function baseConhecimentoListTree({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/tree?${obj.page}&${obj.filter}`)
    .then((res) => {
      Loading.hide();
      commit('SET_BASE_CONHECIMENTO_TREE', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function baseConhecimentoGet({ commit }, id) {
  Loading.show();
  return (await http.get(`${urlApi}/${id}`)
    .then((res) => {
      Loading.hide();
      commit('SET_BASE_CONHECIMENTO', res.data.data.baseConhecimento);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function baseConhecimentoSave({ state, commit }, obj) {
  Loading.show({ message: 'Salvando ...' });
  const baseConhecimento = JSON.parse(JSON.stringify(state.baseConhecimento));
  // processo.responsavel_telefone = processo.responsavel_telefone;
  if (state.baseConhecimento.id) {
    return (
      await http.put(`${urlApi}/${state.baseConhecimento.id}`, baseConhecimento)
        .then((res) => {
          Loading.hide();
          success(res.data.data.message);
          // commit('SET_BASE_CONHECIMENTO_LIST', res.data);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }

  return (
    await http.post(`${urlApi}/`, baseConhecimento)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}


/**
*
* @param {*} param0
*/
export async function baseConhecimentoRemove({ state, commit }, obj) {
  Loading.show({ message: 'Removendo...' });
  return (await http.delete(`${urlApi}/${state.baseConhecimento.id}`)
    .then((res) => {
      Loading.hide();
      success(res.data.data.message);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}
