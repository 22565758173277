/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';


/**
 * Avaliação
 */
export async function avaliacaoSaveAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/avaliacao-incluir`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}


export async function avaliacaoAvaliarAppV2({ commit }, obj) {
  Loading.show();
  return (
    await http
      .post(
        `/distribuicao/app/v1/pedidos/${obj.pedido.id}/avaliar`,
        obj,
      )
      .then((res) => {
        Loading.hide();

        return res;
      })
      .catch(() => {
        Loading.hide();
      })
  ).data;
}
