/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'lgpd/api/v1/planos-acao';

/**
*
* @param {*} param0
*/
export async function planoAcaoCartaoSave({ state, commit }, obj) {
  const planoAcaoCartao = JSON.parse(JSON.stringify(state.planoAcaoCartao));
  delete planoAcaoCartao.relationships;
  Loading.show({ message: 'Salvando cartão' });
  if (!obj.planoAcao.id) {
    obj.planoAcao = state.planoAcaoCartao.relationships.planoAcao;
  }

  if (state.planoAcaoCartao.id) {
    return (
      await http.put(`${urlApi}/${obj.planoAcao.id}/cartoes/${state.planoAcaoCartao.id}`, planoAcaoCartao)
        .then((res) => {
          Loading.hide();
          success(res.data.data.message);
          // commit('SET_ATOS', res.data);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }

  Loading.show({ message: 'Salvando cartão...' });
  return (
    await http.post(`${urlApi}/${obj.planoAcao.id}/cartoes`, planoAcaoCartao)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function planoAcaoCartaoShow({ state, commit }, obj) {
  Loading.show();
  return (
    await http.get(`${urlApi}/${obj.planoAcao.id}/cartoes/${obj.planoAcaoCartao.id}`)
      .then((res) => {
        Loading.hide();
        commit('SET_PLANO_ACAO_CARTAO', res.data.data.planoAcaoCartao);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

/**
*
* @param {*} param0
*/
export async function planoAcaoCartaoDelete({ state, commit }, obj) {
  Loading.show({ message: 'Removendo cartão' });

  if (!obj.planoAcao.id) {
    obj.planoAcao = state.planoAcaoCartao.relationships.planoAcao;
  }

  return (
    await http.delete(`${urlApi}/${obj.planoAcao.id}/cartoes/${state.planoAcaoCartao.id}`)
      .then((res) => {
        Loading.hide();
        success(res.data.data.message);
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}


/**
 * Incluindo BaseConhecimento
 * @param {*} param0
 * @param {*} obj
 * @returns
 */
export async function planoAcaoCartaoItemIncluirBaseConhecimento({ state, commit }, obj) {
  Loading.show({ message: 'Gerando Evidência' });
  return (
    await http.post(`${urlApi}/${obj.planoAcao.id}/cartoes/${obj.planoAcaoCartao.id}/incluir-base-conhecimento`, obj)
      .then((res) => {
        Loading.hide();
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}
