/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';

const urlApi = 'lgpd/api/v1/auditorias';

export async function auditoriaList({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}?${obj.page}&${obj.filter}`)
    .then((res) => {
      Loading.hide();
      commit('SET_AUDITORIAS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function auditoriaShow({ commit }, auditoriId) {
  Loading.show();
  const response = await http.get(`${urlApi}/${auditoriId}`);
  Loading.hide();
  return response.data;
}

export async function reabrirAuditoria({ commit }, auditoria) {
  Loading.show();
  return (await http.post(`${urlApi}/${auditoria.id}/reabrir`)
    .then((res) => {
      Loading.hide();
      commit('SET_AUDITORIAS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function auditoriaPerguntaList({ state, commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/${state.auditoria.id}/pergunta/${obj.pergunta.id}/auditoria-perguntas?${obj.filtros}`)
    .then((res) => {
      Loading.hide();

      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function auditoriaSave({ state, commit }, obj) {
  Loading.show({ message: 'Salvando ...' });

  if (state.auditoria.id) {
    return (
      await http.put(`${urlApi}/${state.auditoria.id}`, state.auditoria)
        .then((res) => {
          Loading.hide();
          // commit('SET_BASE_CONHECIMENTO_LIST', res.data);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }

  return (
    await http.post(`${urlApi}/`, state.auditoria)
      .then((res) => {
        Loading.hide();
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}

export async function auditoriaEncerrar({ state, commit }, obj) {
  Loading.show();
  await http.post(`${urlApi}/${state.auditoria.id}/encerrar`);
  Loading.hide();
}

export async function auditoriaBaseConhecimentoList({ commit }, obj) {
  Loading.show();
  return (await http.get(`${urlApi}/${obj.auditoria.id}/baseConhecimento?${obj.filters}`)
    .then((res) => {
      Loading.hide();
      commit('SET_AUDITORIAS', res.data);
      return res;
    })
    .catch((err) => {
      Loading.hide();
      return err;
    })
  ).data;
}

export async function perguntaAuditar({ state, commit }, obj) {
  Loading.show();

  if (obj.auditoriaPergunta.id) {
    return (
      await http.put(`${urlApi}/${obj.auditoria.id}/pergunta/${obj.pergunta.id}/auditoria-perguntas/${obj.auditoriaPergunta.id}`, state.auditoriaPergunta)
        .then((res) => {
          Loading.hide();
          // commit('SET_BASE_CONHECIMENTO_LIST', res.data);
          return res;
        })
        .catch((err) => {
          Loading.hide();
          return err;
        })
    ).data;
  }

  return (
    await http.post(`${urlApi}/${obj.auditoria.id}/pergunta/${obj.pergunta.id}/auditoria-perguntas`, obj)
      .then((res) => {
        Loading.hide();
        // commit('SET_ATOS', res.data);
        return res;
      })
      .catch((err) => {
        Loading.hide();
        return err;
      })
  ).data;
}


// V2
export async function incluirAuditagem({ state }, auditagem) {
  Loading.show();
  const response = await http.post(`${urlApi}/v2/auditagens`, auditagem);
  Loading.hide();
  return response;
}
export async function auditagemShow({ state }, auditagemId) {
  Loading.show();
  const response = await http.get(`${urlApi}/v2/auditagens/${auditagemId}`);
  Loading.hide();
  return response.data;
}
export async function auditagemSalvar({ state }, auditagem) {
  Loading.show();
  const response = await http.put(`${urlApi}/v2/auditagens/${auditagem.id}`, auditagem);
  Loading.hide();
}
export async function encaminhar({ status }, auditoria) {
  Loading.show();
  const response = await http.post(`${urlApi}/v2/${auditoria.id}/encaminhar`);
  Loading.hide();
}
