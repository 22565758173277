const routes = [
  {
    path: '',
    component: () => import('layouts/auth.vue'),
    children: [
      {
        // Página com opção de escolha
        // Quero me cadastrar / Já sou cadastrado
        path: 'opcoes',
        name: 'auth-begin-options',
        meta: {
          scopes: [],
          requiresAuth: false,
        },
        component: () => import('pages/auth/begin-options.vue'),
      },
      // Página de Login
      {
        path: 'login',
        name: 'auth-login',
        meta: {
          scopes: [],
          requiresAuth: false,
        },
        component: () => import('pages/auth/login.vue'),
      },
      // Página de cadastro
      {
        path: 'registro',
        name: 'auth-register',
        meta: {
          scopes: [],
          requiresAuth: false,
        },
        component: () => import('pages/auth/register.vue'),
      },
      // Página para recuperar senha
      {
        path: 'solicitar-recuperacao-senha',
        name: 'solicitar-recuperacao-senha',
        meta: {
          scopes: [],
          requiresAuth: false,
        },
        component: () => import('pages/auth/recuperar-senha.vue'),
      },
      // Redefinir Senha
      {
        path: 'redefinir-senha/:token?',
        name: 'redefinir-senha',
        meta: {
          scopes: ['can-login'],
          requiresAuth: false,
        },
        component: () => import('pages/auth/recuperar-senha.vue'),
      },
      // Sucesso Empresa ativada
      {
        path: 'entidade-ativada-sucesso',
        name: 'entidade-ativada-sucesso',
        meta: {
          scopes: ['can-login'],
          requiresAuth: false,
        },
        component: () => import('pages/auth/entidade-ativada-sucesso.vue'),
      },
    ],
  },
  {
    path: '',
    component: () => import('layouts/clean.vue'),

    // Página para escolher o perfil/Usuário
    children: [
      {
        path: 'select-perfil/:listAll?',
        name: 'select-perfil',
        meta: {
          title: 'Seleção de perfil',
          requiresAuth: true,
          scopes: ['can-login'],
        },
        component: () => import('pages/auth/select-perfil.vue'),
      },
      {
        path: 'verifica-cadastro-usuario',
        name: 'verifica-cadastro-usuario',
        meta: {
          title: 'Verifica cadastro',
          requiresAuth: true,
          scopes: ['can-login'],
        },
        component: () => import('pages/auth/verifica-cadastro-usuario.vue'),
      },
      {
        path: 'verifica-cadastro-entidade',
        name: 'verifica-cadastro-entidade',
        meta: {
          title: 'Verifica cadastro',
          requiresAuth: true,
          scopes: ['can-login'],
        },
        component: () => import('pages/auth/verifica-cadastro-entidade.vue'),
      },
      {
        path: 'email-validacao',
        name: 'email-validacao',
        meta: {
          title: 'Verifiar email',
          requiresAuth: true,
          scopes: ['can-login'],
        },
        component: () => import('pages/auth/validacao-email.vue'),
      },
      {
        path: 'email-confirmado',
        name: 'email-confirmado',
        meta: {
          title: 'Confirmar de perfil',
          requiresAuth: false,
          scopes: ['can-login'],
        },
        component: () => import('pages/auth/validacao-email.vue'),
      },
    ],
  },
  {
    path: '',
    component: () => import('layouts/default.vue'),
    children: [
      {
        path: 'perfil/empresa',
        name: 'user-profile-empresa',
        meta: {
          title: 'Empresa',
          requiresAuth: true,
          scopes: ['can-login'],
        },
        component: () => import('pages/auth/empresa.vue'),
      },
    ],
  },
];

export default routes;
