/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../http/http';
import { success, error } from '../../../services/alert-message';

const urlApi = 'v1/public/estados';

/**
 * Buscando todos os Tipo de Pedido
 * @param {*} param0
 */
export async function estadoList({ commit }, obj) {
  // Loading.show();
  return (
    await http.get(`${urlApi}?${obj.filter}&include=cidades`).then((res) => {
      commit('SET_ESTADOS', res.data.data);
      return res.data;
    })
  ).data;
}
