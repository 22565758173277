/*
export function someMutation (state) {
}
*/
export function SET_PROCESSO_MODELO_LIST(state, list) {
  state.processoModelos = list;
}

export function CALCULA_PROGRESSO(processo) {
  if (!processo.id) {
    return {
      progressoPergunta: 0,
      progressoPlanoAcao: 0,
    };
  }

  const quatRegulamento = processo.relationships.regulamentos.length;

  const somaPergunta = parseFloat(processo.relationships.regulamentos
    .reduce((somatorio, el) => somatorio + Number(el.attributes.progressoPergunta), 0)
    / quatRegulamento);

  const somaPlanoAcao = parseFloat(processo.relationships.regulamentos
    .reduce((somatorio, el) => somatorio + Number(el.attributes.progressoPlanoAcao), 0)
    / quatRegulamento);

  processo.progressoPergunta = somaPergunta < 100 ? Number(somaPergunta.toFixed(0)) : 100;
  processo.progressoPlanoAcao = somaPlanoAcao < 100 ? Number(somaPlanoAcao.toFixed(0)) : 100;

  if (processo.relationships.modeloProcesso.attributes.conhecimento) {
    processo.regulamentosConcluido = processo.relationships.regulamentos
      .filter(regulamento => (regulamento.attributes.progressoPergunta >= 100)).length;
  }
  if (!processo.relationships.modeloProcesso.attributes.conhecimento) {
    processo.regulamentosConcluido = processo.relationships.regulamentos
      .filter(regulamento => (regulamento.attributes.progressoPergunta >= 100
        && regulamento.attributes.progressoPlanoAcao >= 100)).length;
  }

  return processo;
}

export function SET_PROCESSO_LIST(state, list) {
  state.processos = list;
  state.processos.data.map(processo => CALCULA_PROGRESSO(processo));
}


export function SET_PROCESSO(state, obj) {
  if (obj) {
    state.processo = obj;
  } else {
    state.processo = {
      attributes: {
        titulo: '',
      },
      relationships: {
        regulamentos: [],
        perguntas: [],
      },
    };
  }
}
