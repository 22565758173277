/*
export function someMutation (state) {
}
*/


export function SET_CONTA_CORRENTE(state, list) {
  state.contaCorrente = list;
}

export function PUSH_CONTA_CORRENTE(state, list) {
  list.data.map((cc) => {
    state.contaCorrente.data.push(cc);
    return true;
  });

  state.contaCorrente.meta = list.meta;
}
