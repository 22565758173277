/* eslint-disable */

export default async ({ Vue }) => {
  Vue.prototype.$fingerPrint = text => {
    return new Promise((resolve, reject) => {
      // Caso aparelho n tenha leitor de biometria
      if (!window.Fingerprint) {
        Vue.prototype.$q
          .dialog({
            // title: 'Alert',
            message: 'Não foi encontrado leitor biométrico neste aparelho.',
          })
          .onOk(() => {

            reject();
          })
          .onCancel(() => {

            reject({ message: 'leitor não encontrado.' });
          })
          .onDismiss(() => {

            reject();
          });
      } else {
        window.Fingerprint.isAvailable(
          suc => {
            window.Fingerprint.show(
              {
                clientId: 'fingerprintId',
                clientSecret: 'o7aoOMYUbyxaD23oFAnJ',
                disableBackup: true,
              },
              suc => {
                resolve(suc);
              },
              err => {
                reject(err);
              },
            );
          },
          err => {
            if (err.code === -106)
              Vue.prototype.$q
                .dialog({
                  // title: 'Alert',
                  message:
                    'Biometria não encontrada, favor cadastrar no aparelho e tentar novamente.',
                })
                .onOk(() => {
                  reject();
                })
                .onCancel(() => {

                  reject();
                })
                .onDismiss(() => {

                  reject();
                });
          },
        );
      }
    });
  };
};
