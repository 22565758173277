/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import Loading from 'quasar/src/plugins/Loading.js';;
import http from '../../http/http';
import { success, error } from '../../../../../services/alert-message';

const urlApi = 'consultas';

/**
*
* @param {*} param0
*/
export async function dashboardList({ state, commit }, obj) {
  state.loadingDashboard = true;
  return (await http.get(`financeiro/api/v1/dashboard?${obj.filter}`)
    .then((res) => {
      state.loadingDashboard = false;
      commit('SET_DASHBOARD', res.data.data);
      return res;
    })
    .catch((err) => {
      state.loadingDashboard = false;
      return err;
    })
  ).data;
}
